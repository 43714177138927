<template>
  <a-radio-group v-bind="$attrs" v-on="$listeners">
    <a-radio-button style="min-width: 112px" class="text-center" :value="0"
      >全部</a-radio-button
    >
    <a-radio-button
      style="min-width: 112px"
      class="text-center"
      v-for="item in filters"
      :value="item.value"
      :key="item.value"
    >
      {{ item.name }}
    </a-radio-button>
  </a-radio-group>
</template>

<script>
export default {
  name: "StatusFilterSelect",
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
  },
};
</script>