<template>
  <div class="text-333 text-sm">
    <a-row class="pt-10">
      <a-col :span="24">
        <div class="mt-10" v-for="(item, index) in model.Patents" :key="index">
          <span> {{ index + 1 }}、{{ patentClass[item.Class - 1].Name }} </span>
          ---
          <span>{{ item.Name }}</span>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      patentClass: [],
    };
  },
  created() {
    this.patentClass = this.options.patentClass;
  },
};
</script>
