<template>
  <div>
    <a-form-model
      ref="container"
      :model="dataSource"
      :colon="false"
      :labelCol="{ span: 6 }"
      :wrapperCol="{ span: 15 }"
      v-bind="$attrs"
      labelAlign="left"
    >
      <a-form-model-item prop="ScaleId">
        <div slot="label" class="h-40 flex items-center">
          <span>单位规模</span>
        </div>
        <a-row>
          <a-col :span="15">
            <a-select size="large" v-model="dataSource.ScaleId">
              <a-select-option
                v-for="option in scales"
                :key="option.ID"
                :value="option.ID"
              >
                {{ option.Name }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="Listed">
        <div slot="label" class="h-40 flex items-center">
          <span>是否上市</span>
        </div>
        <a-row>
          <a-col :span="15">
            <a-select size="large" v-model="dataSource.Listed">
              <a-select-option
                v-for="option in whether"
                :key="option.ID"
                :value="option.ID"
              >
                {{ option.Name }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="Resercher">
        <div slot="label" class="h-40 flex items-center">
          <span>科研人员占比</span>
          <a-tooltip class="ml-10">
            <span slot="title">按照科研岗位占总人数规模的占比进行填写，精确到小数点后一位。</span>
            <a-icon type="question-circle" theme="filled" style="color: #ddd" />
          </a-tooltip>
        </div>
        <a-row>
          <a-col :span="15">
            <gf-input size="large" v-model="dataSource.Resercher"></gf-input>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="Revenue">
        <div slot="label" class="h-40 flex items-center">
          <span>上年度营收（万元）</span>
        </div>
        <a-row>
          <a-col :span="15">
            <a-input-number
              :min="0"
              size="large"
              v-model="dataSource.Revenue"
            ></a-input-number>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="FinancingId">
        <div slot="label" class="h-40 flex items-center">
          <span>企业融资轮次</span>
        </div>
        <a-row>
          <a-col :span="15">
            <a-select size="large" v-model="dataSource.FinancingId">
              <a-select-option
                v-for="option in turn"
                :key="option.ID"
                :value="option.ID"
              >
                {{ option.Name }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="Valuation">
        <div slot="label" class="h-40 flex items-center">
          <span>企业估值（万元）</span>
        </div>
        <a-row>
          <a-col :span="15">
            <a-input-number
              
              :min="0"
              size="large"
              v-model="dataSource.Valuation"
            ></a-input-number>
          </a-col>
        </a-row>
      </a-form-model-item>
    </a-form-model>
    <div class="text-right mt-40">
      <a-space>
        <a-button size="large" @click="cancel" type="primary" ghost
          >取消</a-button
        >
        <a-button size="large" @click="save" type="primary">保存</a-button>
      </a-space>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    scales() {
      return this.options.scale;
    },
    whether() {
      return this.options.whether;
    },
    turn() {
      return this.options.turn;
    },
    container() {
      return this.$refs.container;
    },
  },
  data() {
    return {
      dataSource: {},
    };
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    save() {
      this.$refs.container.validate((valid) => {
        if (valid) {
          this.$emit("save", this.dataSource);
        }
      });
    },
  },
  watch: {
    model: {
      handler(val) {
        const temp = { ...val };
        if (temp.Scale) {
          temp.ScaleId = temp.Scale.ID || undefined;
        }
        if (temp.Financing) {
          temp.FinancingId = temp.Financing.ID || undefined;
        }

        this.dataSource = temp;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>