export function localGet(key) {
    return JSON.parse(localStorage.getItem(key))
}

export function localSet(key, value) {
    if (value === undefined || value === null) return;
    return localStorage.setItem(key, JSON.stringify(value))
}

export function sessionGet(key) {
    return JSON.parse(sessionStorage.getItem(key))
}

export function sessionSet(key, value) {
    if (value === undefined || value === null) return;
    return sessionStorage.setItem(key, JSON.stringify(value))
}