<template>
  <div>
    <a-form-model
      ref="container"
      :rules="rules"
      :model="dataSource"
      :colon="false"
      :labelCol="{ span: 6 }"
      :wrapperCol="{ span: 15 }"
      v-bind="$attrs"
      labelAlign="left"
    >
      <a-form-model-item prop="Name" :wrapperCol="{ span: 9 }">
        <div slot="label" class="h-40 flex items-center">
          <span>产品名称</span>
        </div>
        <gf-input
          prop="Name"
          placeholder="15字以内"
          :max="15"
          size="large"
          v-model="dataSource.Name"
        ></gf-input>
      </a-form-model-item>
      <a-form-model-item
        v-if="isTech"
        prop="Tags"
        :required="!$store.getters.isManager"
      >
        <div slot="label" class="h-40 flex items-center">
          <span>产品标签</span>
          <a-tooltip class="ml-10">
            <span slot="title">最多只能添加5个标签</span>
            <a-icon type="question-circle" theme="filled" style="color: #ddd" />
          </a-tooltip>
        </div>
      </a-form-model-item>
      <div v-if="isTech">
        <a-form-model-item
          v-for="(item, index) in dataSource.Tags"
          :key="item.ID"
        >
          <div slot="label" class="h-40 flex items-center">
            <a-space class="text-999 text-xs" align="baseline">
              <span>产品标签{{ index + 1 }}</span>
              <a-icon
                v-if="dataSource.Tags.length > 1 || $store.getters.isManager"
                @click="deleteTag(index)"
                class="align-middle cursor-pointer"
                type="minus-circle"
              />
            </a-space>
          </div>
          <a-row :gutter="10">
            <a-col :span="10">
              <a-form-model-item
                :prop="`Tags.${index}.ID`"
                :rules="{ required: true, message: '请输入', trigger: 'blur' }"
              >
                <a-select placeholder="选择标签" v-model="item.ID">
                  <a-select-option
                    v-for="option in tag"
                    :key="option.ID"
                    :value="option.ID"
                  >
                    {{ option.Name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="14">
              <a-form-model-item
                :prop="`Tags.${index}.Desc`"
                :rules="{ required: true, message: '请输入', trigger: 'blur' }"
              >
                <gf-textarea
                  placeholder="可过技术原型以及实用场景，来阐述您的产品标签"
                  :required="true"
                  :prop="`Tags.${index}.Desc`"
                  :max="50"
                  :rows="4"
                  v-model="item.Desc"
                ></gf-textarea>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model-item>
        <a-form-model-item>
          <div slot="label" class="h-40 flex items-center">
            <span></span>
          </div>
          <div
            v-if="dataSource.Tags.length < 5"
            @click="add"
            class="
              bg-white
              h-40
              border border-eee
              rounded
              text-center
              leading-10
            "
          >
            添加
          </div>
        </a-form-model-item>
      </div>

      <a-form-model-item prop="Desc">
        <div slot="label" class="h-40 flex items-center">
          <span>产品描述</span>
          <a-tooltip class="ml-10">
            <span slot="title"
              >对产品描述完毕后可添加两个关键词，关键词填写示意：如：能耗降低30%，相当于一个软件工程师</span
            >
            <a-icon type="question-circle" theme="filled" style="color: #ddd" />
          </a-tooltip>
        </div>
        <gf-textarea
          placeholder="请在此输入产品描述"
          :max="300"
          :required="true"
          prop="Desc"
          :rows="6"
          v-model="dataSource.Desc"
        ></gf-textarea>
      </a-form-model-item>
      <a-form-model-item v-if="isTech">
        <div slot="label" class="h-40 flex items-center">
          <span></span>
        </div>
        <a-row :gutter="10">
          <a-col :span="8">
            <a-form-model-item
              prop="KeyWords1"
              :rules="{ required: true, message: '请输入', trigger: 'blur' }"
            >
              <gf-input
                placeholder="添加关键词1"
                v-model="dataSource.KeyWords1"
              ></gf-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
              prop="KeyWords2"
              :rules="{ required: true, message: '请输入', trigger: 'blur' }"
            >
              <gf-input
                placeholder="添加关键词2"
                v-model="dataSource.KeyWords2"
              ></gf-input>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="Photos">
        <div slot="label" class="h-40 flex items-center">
          <span>产品海报</span>
        </div>
        <a-row>
          <a-col :span="15">
            <picture-upload
              v-model="dataSource.Photos"
              :width="338"
              :height="160"
              accept=".png,.jpg,.jpeg"
              :size="5"
              field="4"
            ></picture-upload>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item v-if="isTech" prop="Detail">
        <div slot="label" class="h-40 flex items-center">
          <span>产品详情</span>
        </div>
        <a-row>
          <a-col :span="15">
            <file-upload
              field="14"
              :size="20"
              accept=".pdf,.docx,.doc,.png,.jpg,.jpeg"
              v-model="dataSource.Detail"
            ></file-upload>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item v-if="isTech" prop="IndustryIDs">
        <div slot="label" class="h-40 flex items-center">
          <span>产品应用行业</span>
        </div>
        <a-select
          placeholder="请选择，最多选择5个"
          size="large"
          mode="multiple"
          v-model="dataSource.IndustryIDs"
        >
          <a-select-option
            v-for="option in industry"
            :key="option.ID"
            :value="option.ID"
          >
            {{ option.Name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        prop="Industry"
        v-if="dataSource.IndustryIDs.includes(-1) && isTech"
      >
        <div slot="label" class="h-40 flex items-center">
          <span>自定义应用行业</span>
        </div>
        <a-select
          mode="tags"
          size="large"
          v-model="dataSource.Industry"
        ></a-select>
      </a-form-model-item>
      <a-form-model-item v-if="isTech" prop="Attachments">
        <div slot="label" class="h-40 flex items-center">
          <span>其他附件</span>
        </div>
        <a-row>
          <a-col :span="15">
            <file-upload
              field="15"
              :size="20"
              accept=".pdf,.docx,.doc,.png,.jpg,.jpeg"
              v-model="dataSource.Attachments"
            ></file-upload>
          </a-col>
        </a-row>
      </a-form-model-item>
    </a-form-model>
    <div class="text-right mt-40">
      <a-space>
        <a-button size="large" @click="cancel" type="primary" ghost
          >取消</a-button
        >
        <a-button size="large" @click="save" type="primary">保存</a-button>
      </a-space>
    </div>
  </div>
</template>

<script>
function validate2($this) {
  return (rule, value, callback) => {
    let total =
      $this.dataSource.IndustryIDs.length + $this.dataSource.Industry.length;
    if ($this.dataSource.IndustryIDs.includes(-1)) {
      total--;
    }
    if (total === 0) {
      return callback(new Error("请选择"));
    }

    if (total > 5) {
      return callback(new Error("最多只能选择5个"));
    }

    callback();
  };
}
export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dataSource: {},
    };
  },
  computed: {
    role() {
      return this.$store.state.role;
    },
    isManager() {
      return this.role === 6 || this.role === 7;
    },
    user() {
      return this.$store.state.user.USER_INFO;
    },
    isTech() {
      return this.isManager ? !this.dataSource.Industrial : this.user.is_tech;
    },
    tag() {
      return this.options.tag;
    },
    container() {
      return this.$refs.container;
    },
    industry() {
      return this.options.industry;
    },
    rules() {
      return {
        Name: [
          {
            required: !this.$store.getters.isManager,
            message: "请输入",
            trigger: "blur",
          },
        ],
        Desc: [
          {
            required: !this.$store.getters.isManager,
            message: "请输入",
            trigger: "blur",
          },
        ],
        Photos: [
          {
            required: !this.$store.getters.isManager,
            message: "请上传",
            trigger: "blur",
          },
        ],
        IndustryIDs: [
          {
            required: !this.$store.getters.isManager,
            message: "请选择",
            trigger: ["change", "blur"],
          },
          { validator: validate2(this), trigger: ["change", "blur"] },
        ],
        Industry: [
          {
            required: !this.$store.getters.isManager,
            message: "请选择",
            trigger: ["change", "blur"],
          },
          { validator: validate2(this), trigger: ["change", "blur"] },
        ],
      };
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel", this.dataSource.Name);
    },
    save() {
      this.$refs.container.validate((valid) => {
        if (valid) {
          this.$emit("save", this.dataSource);
          this.$emit("update");
        }
      });
    },
    deleteTag(index) {
      this.dataSource.Tags.splice(index, 1);
    },
    add() {
      this.dataSource.Tags.push({});
    },
  },
  watch: {
    model: {
      handler(val) {
        const temp = { ...val };

        temp.IndustryIDs = temp.Industries.filter((item) => item.ID !== 0).map(
          (item) => item.ID
        );
        temp.Industry = temp.Industries.filter((item) => item.ID === 0).map(
          (item) => item.Name
        );

        if (temp.Industry.length) {
          temp.IndustryIDs.push(-1);
        }

        this.dataSource = JSON.parse(JSON.stringify(temp));
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>