<template>
  <div class="container mx-auto flex items-center h-full">
    <a href="#/index" class="logo mr-40">
      <img
        class="w-full h-full object-contain"
        src="@/assets/icons/logo.svg"
        alt=""
    /></a>
    <a-menu mode="horizontal" :selectedKeys="key">
      <a-sub-menu key="a">
        <span slot="title" v-if="!isManager">供需矿场</span>
        <a href="#/requirement-pool" slot="title">供需矿场</a>
        <a-menu-item key="requirement" hidden>
          <span v-if="!isManager">供需矿场</span>
          <a href="#/requirement-list" v-else>供需矿场</a>
        </a-menu-item>
        <a-menu-item key="requirement-list">
          <span v-if="!isManager">需求池</span>
          <a href="#/requirement-list" v-else>需求池</a>
        </a-menu-item>
        <a-menu-item key="product">
          <a href="#/product-list" v-if="isManager">核心产品</a>
          <span v-else>核心产品</span>
        </a-menu-item>
        <a-menu-item key="case">
          <span v-if="!isManager">成功案例</span>
          <a href="#/case-list" v-else>成功案例</a>
        </a-menu-item>
        <a-menu-item key="solution">
          <span v-if="!isManager">解决方案</span>
          <a href="#/solution-list" v-else>解决方案</a>
        </a-menu-item>
      </a-sub-menu>
      <a-menu-item key="activity">
        <span v-if="!isManager">星球探索</span>
        <a href="#/activity-list" v-else>星球探索</a>
        <!-- <span>星球探索</span> -->
      </a-menu-item>
      <a-menu-item key="partner">
        <a href="#/partner-list" v-if="isManager">星球伙伴</a>
        <span v-else>星球伙伴</span>
      </a-menu-item>
      <a-menu-item key="expert">
        <span v-if="!isManager">专家直连</span>
        <a href="#/expert-list" v-else>专家直连</a>
      </a-menu-item>
      <a-menu-item key="knowledge">
        <!-- <span>知识矩阵</span> -->

        <a href="#/knowledge-list" v-if="isManager">知识矩阵</a>
        <span v-else>知识矩阵</span>
      </a-menu-item>
      <a-menu-item key="policy">
        <!-- <span>政策直通车</span> -->
        <span v-if="!isManager">政策直通车</span>
        <a href="#/policy-list" v-else>政策直通车</a>
      </a-menu-item>
    </a-menu>
    <div class="ml-auto inline-flex items-center">
      <a-space v-if="isLogin" align="center" :size="20">
        <MessageBadge />

        <AvatarMenu>
          <a :href="href">
            <Avatar />
          </a>
        </AvatarMenu>
      </a-space>
      <div v-else>
        <a href="#/auth/login" class="mr-40 text-333">登录</a>
        <a
          href="#/auth/register"
          class="
            bg-primary
            inline-block
            hover:text-white
            text-center
            rounded
            text-white
          "
          style="width: 96px; height: 40px; line-height: 40px"
          >开放注册</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { localSet } from "@/utils/storage.js";
import { getUrl } from "@/api";
import AvatarMenu from "@/router/components/AvatarMenu/index.vue";
import Avatar from "@/store/components/Avatar/index.vue";
import MessageBadge from "@/store/components/MessageBadge/index.vue";

export default {
  name: "global-header",
  components: { AvatarMenu, Avatar, MessageBadge },
  computed: {
    key() {
      const temp = this.$route.meta.group || "";
      return [temp];
    },
    href() {
      switch (this.role) {
        case 1:
          return "#/account/user-setting";
        case 2:
          return "#/dashboard/home-expert";
        case 3:
          return "#/company";
        case 4:
          return "#/company";
        case 5:
          return "#/company";
        case 6:
          return "#/evaluate";
        case 7:
          return "#/evaluate";
        case 8:
          return "#/dashboard/children-partner";
      }
    },
    role() {
      return this.$store.state.role || this.$store.state.userInfo.role;
    },
    isManager() {
      // return this.role === 6 || this.role === 7;
      return true;
    },
    isLogin() {
      return this.$store.state.userInfo && this.$store.state.userInfo.id;
    },
    name() {
      return this.$store.state.userInfo
        ? this.$store.state.userInfo.username ||
            this.$store.state.userInfo.email
        : "-";
    },
    logo() {
      return (
        this.$store.state.userInfo.Profile &&
        getUrl(this.$store.state.userInfo.Profile.ID)
      );
    },
  },
  methods: {
    logout() {
      localSet("token", "");
      this.$store.state.role = "";
      window.location.href = "#/auth/login";
    },
  },
};
</script>

<style scoped>
.logo {
  width: 185px;
  height: 45px;
}
.userLogo {
  width: 32px;
  height: 32px;
  border: 1px solid #eee;
  border-radius: 50%;
  overflow: hidden;
}
.ant-menu .ant-menu-submenu-selected a {
  color: #2e7ced !important;
}
.ant-menu-item {
  border-bottom: 0 !important;
}
.ant-menu-horizontal {
  border-bottom: 0 !important;
}
</style>