<template>
  <div>
    <div class="flex justify-between items-center px-20" v-if="state === 0">
      <div class="title text-xl text-111 relative pl-10 ellipsis">
        {{ title }}
      </div>
      <div
        v-if="
          (AuditStatus !== 1 && !isManager) || (isManager && AuditStatus === 1)
        "
      >
        <slot name="opt"></slot>
      </div>
    </div>
    <div v-else-if="state === 1" class="p-20 bg-f9">
      <div class="text-xl mb-20 flex items-center">
        <span text-primary>{{ title }}</span>
        <a-tooltip class="ml-10" v-if="tip">
          <span slot="title">{{tip}}</span>
          <a-icon type="question-circle" theme="filled" style="color: #ddd" />
        </a-tooltip>
      </div>
      <slot name="editing"></slot>
    </div>
    <div v-else-if="state === 2" class="display p-20 hover:bg-f9">
      <div class="relative">
        <div
          class="absolute right-0 top-0 z-10"
          v-if="
            (AuditStatus !== 1 && !isManager) ||
            (isManager && AuditStatus === 1)
          "
        >
          <slot name="display-opt"></slot>
        </div>
        <div
          v-if="showTitle"
          class="title text-xl text-111 relative pl-10 flex items-center"
        >
          <div class="ellipsis title1">{{ title }}</div>

          <span v-if="AuditStatus === 1 && showStatus" class="status ing"
            >审核中</span
          >
          <span
            v-else-if="AuditStatus === 2 && showStatus"
            class="status success"
            >审核成功</span
          >
          <span v-else-if="AuditStatus === 3 && showStatus" class="status fail"
            >审核失败</span
          >
          <span v-else-if="showStatus" class="status edit">未提交审核</span>

          <span v-if="!status && showTipStatus" class="status complete"
            >资料填写不完整</span
          >
        </div>

        <slot name="display"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "edit-block",
  props: {
    state: Number,
    title: String,
    showTitle: {
      type: Boolean,
      default: true,
    },
    showStatus: {
      type: Boolean,
      default: false,
    },
    showTipStatus: {
      type: Boolean,
      default: true,
    },
    status: {
      type: Boolean,
      default: true,
    },
    AuditStatus: Number,
    tip:String
  },
  computed: {
    isManager() {
      return this.$store.state.role === 7 || this.$store.state.role === 6;
    },
  },
};
</script>

<style scoped>
.title {
  width: 500px;
}
.title1 {
  max-width: 350px;
}
.title::before {
  content: "";
  width: 3px;
  height: 18px;
  top: 55%;
  @apply absolute left-0 transform -translate-y-1/2 bg-primary;
}

.display-opt {
  display: none;
}
.display:hover .display-opt {
  display: inline-block;
}
.error {
  border: 1px solid red;
}
.status {
  font-size: 12px;
  height: 24px;
  padding: 0 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 12px;
  color: #fff;
  margin-left: 10px;
}
.status.fail {
  background-color: #fc2c00;
}
.status.ing {
  background-color: #008dfc;
}
.status.success {
  background-color: #1ed989;
}
.status.edit {
  background-color: #000;
}
.status.complete {
  background-color: #fc2c00;
}
</style>