<template>
  <div :class="industrybg" class="w-full"></div>
</template>

<script>
export default {
  props: {
    tag: Object,
    default: () => ({}),
  },
  computed: {
    industrybg() {
      const tag = this.tag;
      if (tag && tag.ID) {
        return `industry${tag.ID}`;
      } else {
        const num = parseInt(Math.random() * 4 + 1);
        return `custom${num}`;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.custom1 {
  background-image: url("../../assets/images/custom1.jpeg");
  background-size: 100% auto;
  background-position-y: center;
}
.custom2 {
  background-image: url("../../assets/images/custom2.jpeg");
  background-size: 100% auto;
  background-position-y: center;
}
.custom3 {
  background-image: url("../../assets/images/custom3.jpeg");
  background-size: 100% auto;
  background-position-y: center;
}
.custom4 {
  background-image: url("../../assets/images/custom4.jpeg");
  background-size: 100% auto;
  background-position-y: center;
}
.custom5 {
  background-image: url("../../assets/images/custom5.jpeg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry1 {
  background-image: url("../../assets/images/dzxx.jpeg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry2 {
  background-image: url("../../assets/images/zbzz.jpeg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry3 {
  background-image: url("../../assets/images/qc.jpg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry4 {
  background-image: url("../../assets/images/cb.jpg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry5 {
  background-image: url("../../assets/images/swyy.jpg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry6 {
  background-image: url("../../assets/images/hthk.jpeg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry7 {
  background-image: url("../../assets/images/gt.jpg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry8 {
  background-image: url("../../assets/images/hg.jpg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry9 {
  background-image: url("../../assets/images/jjz.jpg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry10 {
  background-image: url("../../assets/images/gcjx.jpeg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry11 {
  background-image: url("../../assets/images/sw.jpg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry12 {
  background-image: url("../../assets/images/dj.jpg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry13 {
  background-image: url("../../assets/images/ny.jpg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry14 {
  background-image: url("../../assets/images/ny2.jpg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry15 {
  background-image: url("../../assets/images/jz.jpeg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry16 {
  background-image: url("../../assets/images/fz.jpg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry17 {
  background-image: url("../../assets/images/sp.jpg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry18 {
  background-image: url("../../assets/images/wl.jpeg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry19 {
  background-image: url("../../assets/images/qg.jpg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry20 {
  background-image: url("../../assets/images/rjfw.jpg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry21 {
  background-image: url("../../assets/images/yh.jpeg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry22 {
  background-image: url("../../assets/images/jy.jpg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry23 {
  background-image: url("../../assets/images/jd.jpeg");
  background-size: 100% auto;
  background-position-y: bottom;
}
</style>