<template>
  <a-empty></a-empty>
</template>

<script>
export default {
  name: "gf-empty",
  props: {
    type: String,
  },
};
</script>