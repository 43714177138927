<template>
  <div>
    <!-- 搜索 -->
    <div class="pb-40 flex justify-center" v-if="showSearch">
      <gf-re-input-search
        size="large"
        style="width: 580px"
        enter-button
        @search="$emit('change', { name: $event, current: 1 })"
      ></gf-re-input-search>
      <slot name="publish"></slot>
    </div>
    <!-- 筛选 -->
    <div class="mt-20">
      <FilterSelect v-bind="$attrs" v-on="$listeners"> </FilterSelect>
    </div>
    <!-- 列表 -->
    <div class="mt-40">
      <FilterRows :gutter="gutter" :span="span" :list="list">
        <slot
          name="card"
          slot-scope="{ dataSource }"
          v-bind:dataSource="dataSource"
        ></slot>
      </FilterRows>
    </div>
    <!-- 分页 -->
    <div class="text-center mt-40" v-if="list.length">
      <a-pagination
        :current="current"
        :pageSize="limit"
        :total="total"
        @change="$emit('change', { current: $event })"
      ></a-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterList",
  props: {
    span: {
      type: Number,
      default: 1,
    },
    current: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 0,
    },
    gutter: {
      type: Number,
      default: 20,
    },
    list: {
      type: Array,
      default: () => [],
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
  },
};
</script>