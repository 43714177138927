import { request, proRequest } from '@/utils/request.js'
import { localSet } from '@/utils/storage.js'

// 发送邮箱绑定验证码
export function sendBindingCode(data) {
    return request.post(`/email/sendEmailBindingCode`, data)
}

// 发送注册验证码
export function sendRegisterCode(data) {
    return request.post(`/email/sendRegisterCode`, data)
}

// 发送重置密码验证码
export function sendResetCode(data) {
    return request.post(`/email/sendResetCode`, data)
}

// 用户绑定邮箱
export function bindEmail(data) {
    return request.post(`/user/bind_email`, data)
}

// 用户详情
export function getUserInfo() {
    return request.get('/user/info').catch((err) => {
        if (err === 404) {
            localSet('token', '')
            window.location.href = '#/auth/login'
        }
    })
}

// 更新用户详情
export function updateUserInfo(data) {
    return request.put(`/user/info`, data)
}

// 登录
export function login(data) {
    return request.post('/user/login', data)
}

// 设置用户密码
export function setPassword(data) {
    return request.put(`/user/password`, data)
}

// 注册
export function register(data) {
    return request.post('/user/register', data)
}

// 重置密码
export function reset(data) {
    return request.post('/user/reset_password', data)
}

// 发送手机绑定验证码
export function sendBindPhoneCode(data) {
    return request.post('/sms/sendBindingCode', data)
}
// 发送手机重置密码验证码
export function sendRegisterPhoneCode(data) {
    return request.post('/sms/sendRegisterCode', data)
}
// 发送手机注册验证码
export function sendResetPhoneCode(data) {
    return request.post('/sms/sendResetCode', data)
}
// 绑定手机号
export function bindPhone(data) {
    return request.post('/user/bind_phone', data)
}