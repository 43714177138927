import request from '@/utils/request1'

let promission = {}

// 获取用户公司信息
export function getCompanyInfo() {
    return request.get('/user/company')
}
promission.getCompanyInfo = {}

// 获取用户公司信息填写进度
export function getCompanyProgress() {
    return request.get('/user/company/progress')
}
promission.getCompanyProgress = { type: 3 }

export default promission;