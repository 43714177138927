<template>
  <a-row :gutter="20">
    <a-col :span="8">
      <gf-form-item
        prop="region.Province.ID"
        :rules="[
          {
            required: required,
            message: '请选择省份',
            trigger: ['blur'],
          },
        ]"
      >
        <a-select
          :disabled="disabled"
          @change="selectProvince"
          v-model="model.province"
          placeholder="请选择省份"
        >
          <a-select-option v-for="item in provinces" :key="item.ID">{{
            item.Name
          }}</a-select-option>
        </a-select>
      </gf-form-item>
    </a-col>
    <a-col :span="8" v-if="![710000, 810000, 820000].includes(model.province)">
      <gf-form-item
        prop="region.City.ID"
        :rules="[
          {
            required: required,
            message: '请选择城市',
            trigger: ['blur'],
          },
        ]"
      >
        <a-select
          :disabled="disabled"
          @change="selectCity"
          v-model="model.city"
          placeholder="请选择城市"
        >
          <a-select-option v-for="item in citys" :key="item.ID">{{
            item.Name
          }}</a-select-option>
        </a-select>
      </gf-form-item>
    </a-col>
    <a-col :span="8" v-if="![710000, 810000, 820000].includes(model.province)">
      <gf-form-item
        prop="region.County.ID"
        :rules="[
          {
            required: required,
            message: '请选择区县',
            trigger: ['blur'],
          },
        ]"
      >
        <a-select
          :disabled="disabled"
          v-model="model.county"
          placeholder="请选择区县"
        >
          <a-select-option v-for="item in countys" :key="item.ID">{{
            item.Name
          }}</a-select-option>
        </a-select>
      </gf-form-item>
    </a-col>
  </a-row>
</template>

<script>
import { getRegion } from "@/api/api/common";
export default {
  name: "GfRegionSelect",
  props: {
    required: {
      type: Boolean,
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      model: {},
      provinces: [],
      citys: [],
      countys: [],
    };
  },
  created() {
    this.getProvince(100000).then(() => {
      const temp = {
        province: this.value.Province.ID,
        city: this.value.City.ID,
        county: this.value.County.ID,
      };
      this.model = temp;
    });
  },
  methods: {
    getProvince(id) {
      return getRegion({ id }).then(({ data }) => {
        this.provinces = data;
      });
    },
    getCitys(id) {
      return getRegion({ id }).then(({ data }) => {
        this.citys = data;
      });
    },
    getCountys(id) {
      return getRegion({ id }).then(({ data }) => {
        this.countys = data;
      });
    },
    selectProvince(e) {
      this.getCitys(e);
      this.$set(this.model, "city", undefined);
      this.$set(this.model, "county", undefined);
    },
    selectCity(e) {
      this.getCountys(e);
      this.$set(this.model, "county", undefined);
    },
  },
  watch: {
    model: {
      async handler(val, old) {
        if (val.province !== old.province) {
          await this.getCitys(val.province);
        }
        if (val.city !== old.city) {
          await this.getCountys(val.city);
        }
        const temp = {
          City: this.citys.find((item) => val.city === item.ID),
          Country: {
            ID: 100000,
            Name: "中国",
          },
          County: this.countys.find((item) => val.county === item.ID),
          Province: this.provinces.find((item) => val.province === item.ID),
        };

        this.$emit("input", temp);
      },
      deep: true,
    },
  },
};
</script>