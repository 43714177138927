<template>
  <a-select
    :placeholder="placeholder"
    ref="custom-select"
    v-bind="$attrs"
    v-on="$listeners"
    @blur="blur"
    @focus="focus"
    @select="select"
    :open="open"
  >
    <div slot="dropdownRender" slot-scope="menu">
      <v-nodes :vnodes="menu" />
      <a-divider style="margin: 4px 0" />
      <div style="padding: 4px 8px; cursor: pointer">
        <a-row>
          <a-col :span="20">
            <gf-re-input
              v-model="customValue"
              ref="custom-input"
              @blur="blur"
              :maxLength="10"
            ></gf-re-input>
          </a-col>
          <a-col :span="4" class="">
            <div class="flex justify-center items-center h-40" @click="addItem">
              <a-icon type="plus" />
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <a-select-option v-for="item in list" :key="item.ID" :value="item.ID">{{
      item.Name
    }}</a-select-option>
  </a-select>
</template>

<script>
export default {
  components: {
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
  name: "GfCustomSelect",
  model: {
    prop: "value",
    event: "change",
  },
  methods: {
    addItem() {
      this.$refs["custom-select"].$el.focus();
      if (!this.customValue) {
        return;
      }
      const temp = {
        ID: this.customValue,
        Name: this.customValue,
        custom: true,
      };
      if (!this.findItem(temp)) {
        this.list.push(temp);
      }
    },
    findItem(data) {
      return this.list.find(
        (item) => item.ID === data.ID && item.Name === data.Name
      );
    },
    focus() {
      this.open = true;
    },
    blur() {
      setTimeout(() => {
        const isInput =
            document.activeElement === this.$refs["custom-input"].$el,
          isSelect = document.activeElement === this.$refs["custom-select"].$el;

        if (!isInput && !isSelect) {
          this.open = false;
        }
      }, 100);
    },
    select(){
      this.$refs["custom-select"].focus()
    }
  },
  props: {
    flag: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "请选择",
    },
  },
  data() {
    return {
      list: [],
      open: false,
      customValue: "",
      index: 100,
    };
  },
  created() {
    if (this.flag) {
      const options = [...this.options[this.flag]];
      if (!options || !options.length) return;
      options.pop();
      this.list = options;
    }
  },
  computed: {
    options() {
      return this.$store.state.options;
    },
  },
};
</script>