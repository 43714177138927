<template>
  <a-space class="font-bold text-333 text-xl" :size="10" align="baseline">
    <span class="bg-primary inline-block" style="width: 3px; height: 14px"></span>
    <span><slot></slot></span>
  </a-space>
</template>

<script>
export default {
  name: "gf-title",
};
</script>