<template>
  <div class="p-20 pb-0 bg-background">
    <div
      v-for="item in filters"
      :key="item.flag"
      class="flex text-sm text-666 overflow-hidden transition"
      :style="{ height: item.collapse ? 'auto' : '40px' }"
    >
      <div style="width: 100px" class="mr-20 text-left">{{ item.name }}:</div>
      <div style="width: calc(100% - 240px)" class="flex flex-wrap" ref="flex">
        <div
          class="option mr-20 mb-20 cursor-pointer"
          v-for="(option, index) in options[item.flag]"
          :key="index"
          @click="select({ ...option, flag: item.flag })"
          :class="{
            'text-primary': isSelected({ ...option, flag: item.flag }),
          }"
        >
          {{ option.Name }}
        </div>
        <!-- <div class="" v-if="item.flag === 'Budget'">
          <a-input-number size="small"></a-input-number>
          <a-input-number size="small"></a-input-number>
          <a-button size="small"></a-button>
        </div> -->
      </div>
      <div
        style="width: 100px"
        class="ml-5 cursor-pointer"
        v-if="item.hasMore"
        @click="$set(item, 'collapse', !item.collapse)"
      >
        <span v-if="!item.collapse">更多</span><span v-else>收起</span
        ><a-icon
          class="ml-1 transform transition align-middle"
          :class="{ 'rotate-180': !item.collapse }"
          type="down"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { sessionGet } from "@/utils/storage";
export default {
  name: "FilterSelect",
  props: {
    filterOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filters: [],
      selected: [],
      options: [],
      budget: {
        min: null,
        max: null,
      },
    };
  },
  created() {
    const options = sessionGet("options1");
    for (let key in options) {
      const temp = options[key];
      temp.unshift({ ID: 0, Name: "全部" });
      this.$set(this.options, key, temp);
    }

    this.filters = this.filterOptions;
    this.init();
  },
  methods: {
    select(data) {
      const mode = this.findFilter(data).mode,
        isSelected = this.isSelected(data);
      if (isSelected) {
        if (data.ID === 0) return;
        const temp = this.selected.filter(
          (item) => !(item.flag === data.flag && item.ID === data.ID)
        );
        if (!this.isSingle(temp, data)) {
          temp.push({ ID: 0, flag: data.flag });
        }
        this.selected = temp;
      } else {
        if (mode === "multiple" && data.ID !== 0) {
          const temp = this.selected.filter(
            (item) => !(item.flag === data.flag && item.ID === 0)
          );
          temp.push(data);
          this.selected = temp;
        } else {
          const temp = this.selected.filter((item) => item.flag !== data.flag);
          temp.push(data);
          this.selected = temp;
        }
      }

      const params = {
        current: 1,
      };
      this.filters.forEach((item) => {
        const temp = this.selected
          .filter((select) => select.flag === item.flag)
          .map((item) => item.value || item.ID);

        params[item.restore] = temp.filter((item) => item);

        if (item.mode !== "multiple") {
          params[item.restore] = params[item.restore][0] || "";
        }
      });

      this.$emit("change", params);
    },
    findFilter(data) {
      return this.filters.find((item) => item.flag === data.flag);
    },
    isSelected(data) {
      return this.selected.find(
        (item) => item.flag === data.flag && item.ID === data.ID
      );
    },
    isSingle(selected, data) {
      return selected.filter((item) => item.flag === data.flag).length;
    },
    init() {
      const temp = [];
      this.filters.forEach((item) => {
        temp.push({ ID: 0, flag: item.flag });
      });
      this.selected = temp;
    },
  },
  mounted() {
    this.$refs.flex.forEach((el, index) => {
      if (el.scrollHeight > el.clientHeight) {
        this.$set(this.filters[index], "hasMore", true);
      }
    });
  },
};
</script>