<template>
  <div class="text-333 text-sm">
    <a-row class="pt-10">
      <a-col :span="24" class="mt-10">
        <a-space align="baseline">
          <div class="text-999" style="width: 80px">所属领域：</div>
          <div class="flex flex-wrap">
            <div class="tag" v-for="item in fieldText" :key="item">
              {{ item }}
            </div>
          </div>
        </a-space>
      </a-col>
      <a-col :span="24" class="mt-10">
        <a-space align="baseline">
          <div class="text-999" style="width: 80px">{{ industryTitle }}：</div>
          <div class="flex flex-wrap">
            <div class="tag" v-for="item in industryText" :key="item">
              {{ item }}
            </div>
          </div>
        </a-space>
      </a-col>
      <a-col :span="24" class="mt-10">
        <a-space align="baseline">
          <div class="text-999" style="width: 80px">企业标签：</div>
          <div class="flex flex-wrap">
            <div class="tag" v-for="item in tagText" :key="item">
              {{ item }}
            </div>
          </div>
        </a-space>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    tagText() {
      return this.model.Tags.map((item) => {
        if (item.Name) {
          return item.Name;
        } else {
          const option = this.options.tag[item.ID - 1];
          return option ? option.Name : "";
        }
      });
    },
    fieldText() {
      return this.model.Fields.map((item) => {
        if (item.Name) {
          return item.Name;
        } else {
          const option = this.options.field[item.ID - 1];
          return option ? option.Name : "";
        }
      });
    },
    industryText() {
      return this.model.Industries.map((item) => {
        if (item.Name) {
          return item.Name;
        } else {
          const option = this.options.industry[item.ID - 1];
          return option ? option.Name : "";
        }
      });
    },
    industryTitle() {
      return this.model.Fields.find((item) => item === 1)
        ? "所属行业"
        : "服务行业";
    },
  },
};
</script>

<style scoped>
.tag {
  padding: 5px 10px;border:1px solid #2E7CED;color:#2E7CED; 
  border: 1px solid #eee;
  background-color: #f9f9f9;
  margin-bottom: 10px;
  margin-right: 10px;
}
</style>