<template>
  <div>
    <a-spin :spinning="loading">
      <a-upload
        :action="upload()"
        :accept="accept"
        @change="handleChange"
        :before-upload="beforeUpload"
        :multiple="multiple"
        :defaultFileList="dataSource"
      >
        <a-button> <a-icon type="upload" /> Upload </a-button>
      </a-upload>
    </a-spin>
  </div>
</template>

<script>
import { upload } from "@/api";
export default {
  name: "file-upload",
  props: {
    field: String,
    size: {
      type: Number,
      default: 20,
    },
    accept: String,
    value: {
      type: [Array, Object],
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dataSource: [],
      loading: false,
    };
  },
  methods: {
    handleChange({ fileList }) {
      let list;
      list = fileList
        .filter((item) => {
          return item.status === "done";
        })
        .map((item) => {
          if (item.response) {
            return this.transform(item.response.data);
          } else {
            return item;
          }
        });
      this.$emit("input", list);
      this.$emit("change", list);
    },
    beforeUpload(file) {
      if (!this.multiple && this.dataSource.length >= 1) {
        this.$message.error("只能上传单个文件");
        return Promise.reject("只能上传单个文件");
      }
      const accepts = this.accept && this.accept.split(",");
      const exts = file.name.split(".");
      const ext = "." + exts[exts.length - 1];
      const isJpgOrPng = accepts ? accepts.includes(ext) : true;
      if (!isJpgOrPng) {
        this.$message.error("只能上传指定格式的文件!");
        return Promise.reject("只能上传指定格式的文件!");
      }
      const isLt2M = file.size / 1024 / 1024 < this.size;
      if (!isLt2M) {
        this.$message.error(`文件大小不能超过${this.size}MB!`);
        return Promise.reject(`文件大小不能超过${this.size}MB!`);
      }
    },
    upload() {
      return upload();
    },
    transform(obj) {
      if (obj) {
        return {
          ID: obj.ID || obj.id,
          uid: obj.ID || obj.id,
          Name: obj.Name || obj.name,
          name: obj.Name || obj.name,
          url: upload() + "/" + (obj.ID || obj.id),
          status: "done",
        };
      }
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.dataSource = val
            .map(this.transform)
            .filter((item) => item && item.ID);
        }
      },
      immediate: true,
    },
  },
};
</script>