<template>
  <div class="partner-card border border-eee py-20">
    <div class="partner-logo mx-auto border border-eee">
      <img
        v-if="dataSource.logo"
        :src="dataSource.logo"
        alt=""
        class="w-full h-full object-contain"
      />
    </div>
    <div
      class="
        partner-name
        text-base
        font-bold
        text-center
        overflow-hidden
        mt-20
        px-20
      "
    >
      <a class="ellipsis" :href="detail">{{ dataSource.name }}</a>
    </div>
    <div class="text-center">
      <a-space>
        <div>{{ dataSource.submitTime }}</div>
        <div>提交</div>
      </a-space>
    </div>
    <div class="text-center">{{ dataSource.status }}</div>
    <!-- <div class="flex justify-center items-center text-xs text-999 w-full mt-10">
      <div>
        需求：<span class="text-primary">{{
          dataSource.requirementCount
        }}</span>
      </div>
      <div class="mx-10">|</div>
      <div>
        产品：<span class="text-primary">{{ dataSource.productCount }}</span>
      </div>
      <div class="mx-10">|</div>
      <div>
        案例：<span class="text-primary">{{ dataSource.caseCount }}</span>
      </div>
      <div class="mx-10">|</div>
      <div>
        方案：<span class="text-primary">{{ dataSource.caseCount }}</span>
      </div>
    </div>
    <div
      class="
        partner-tag
        text-xs
        flex flex-wrap
        overflow-hidden
        mt-20
        bg-f9
        px-20
        py-10
      "
    >
      <div
        
        v-for="(tag, index) in dataSource.tags"
        :key="index"
      >
        <div class="ellipsis mr-10 mb-10 tag" v-if="tag">{{
          tag
        }}</div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "evaluate-partner-state-card",
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      detail: "",
    };
  },
  created() {
    this.detail = `#/evaluate/state-detail${
      this.dataSource.isTech ? "" : "2"
    }/:id`.replace(/:id/, this.dataSource.id);
  },
  watch: {
    "dataSource.id"(val) {
      this.detail = `#/evaluate/state-detail${
        this.dataSource.isTech ? "" : "2"
      }/:id`.replace(/:id/, val);
    },
  },
};
</script>

<style scoped>
.partner-card:hover {
  box-shadow: 2px 5px 32px -10px rgba(0, 0, 0, 0.2);
}
.partner-logo {
  width: 64px;
  height: 64px;
}
.partner-name {
  height: 24px;
}
.partner-tag {
  height: 82px;
}
.tag {
  max-width: 250px;
  padding: 5px 10px;
  border: 1px solid #2e7ced;
  color: #2e7ced;
  height: 26px;
}
</style>