<template>
  <premission-link :href="`#/knowledge-detail/${dataSource.id}`">
    <div class="border border-eee bg-f9 hover:shadow">
      <div class="logo mr-20 relative">
        <div
          class="
            type
            text-xs
            absolute
            left-0
            top-20
            px-10
            bg-primary
            text-white
          "
          
        >
          {{ dataSource.category.Name }}
        </div>
        <img
          class="w-full"
          v-if="dataSource.cover && dataSource.cover.url && dataSource.weight"
          :src="dataSource.cover.url"
          alt=""
        />
        <img
          v-if="[6, 8].includes(dataSource.categoryId) && !dataSource.weight"
          src="@/assets/images/knowledge2.png"
          alt=""
        />
        <img
          v-else-if="
            [1, 2, 3, 9].includes(dataSource.categoryId) && !dataSource.weight
          "
          src="@/assets/images/knowledge1.png"
          alt=""
        />
        <img
          v-else-if="
            [4, 5, 7].includes(dataSource.categoryId) && !dataSource.weight
          "
          src="@/assets/images/knowledge3.png"
          alt=""
        />
        <div class="absolute top-0 w-full px-20" style="padding-top: 76px">
          <div class="text-111 font-bold mb-20 ellipsis-2" style="height: 48px">
            {{ dataSource.name }}
          </div>
          <gf-tag-group>
            <gf-tag v-for="(tag, index) in dataSource.tags" :key="index">
              {{ tag.Name }}
            </gf-tag>
          </gf-tag-group>
        </div>
      </div>
      <!-- <div class="info pb-20">
          <div
            style="24px"
            class="ellipsis-1 mb-20 font-bold cursor-pointer hover:text-primary"
            @click="$router.push(`/detail/${dataSource.id}`)"
          >
            {{ dataSource.name }}
          </div>
          <div class="ellipsis-2 h-40 mb-20 text-sm">
            {{ dataSource.desc }}
          </div>
          <div class="text-999 text-xs mb-20">
            来源：{{ dataSource.source }}
          </div>
          <a-button
            type="primary"
            @click="$router.push(`/detail/${dataSource.id}`)"
            >查看</a-button
          >
        </div> -->
    </div>
  </premission-link>
</template>

<script>
export default {
  name: "knowledge-card",
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style scoped>
.logo {
  width: 100%;
  height: auto;
}
.type {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  height: 26px;
  line-height: 26px;
}
.info {
  width: calc(100% - 240px);
}
.tag {
  padding: 5px 10px;
  border: 1px solid #2e7ced;
  color: #2e7ced;
}
</style>