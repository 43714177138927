<template>
  <div>
    <div class="mb-10 relative" v-for="(item, index) in list" :key="index + 1">
      <div
        v-if="(list.length > 1 && required) || !required"
        class="
          absolute
          -right-5
          top-1/2
          transform
          -translate-y-1/2
          cursor-pointer
        "
        style="color: #f5222d"
        @click="del(index)"
      >
        <a-icon type="close-circle" />
      </div>
      <slot v-bind="{ index, record: item }"></slot>
    </div>
    <div
      class="
        h-40
        bg-background
        rounded
        border-eee
        leading-10
        text-center text-primary
        cursor-pointer
      "
      @click="!disabled&&add()"
    >
      新增
    </div>
  </div>
</template>

<script>
export default {
  name: "DynamicForm",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    add() {
      this.list.push({});
    },
    del(index) {
      this.list.splice(index, 1);
    },
  },
};
</script>