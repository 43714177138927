<template>
  <svg width="24px" height="24px" viewBox="0 0 24 24">
    <title>时间轴备份</title>
    <g
      id="页面1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="工业企业画像2" transform="translate(-1080.000000, -548.000000)">
        <g id="时间轴备份" transform="translate(1080.000000, 548.000000)">
          <rect
            id="矩形"
            fill="#000000"
            fill-rule="nonzero"
            opacity="0"
            x="0"
            y="0"
            width="24"
            height="24"
          ></rect>
          <path
            d="M17.8329375,15.955125 L11.4763125,15.955125 C11.079,15.955125 10.6816875,16.21875 10.6816875,16.614375 C10.6816875,17.01 10.9995,17.273625 11.4763125,17.273625 L17.833125,17.273625 C18.2304375,17.273625 18.62775,17.01 18.62775,16.614375 C18.62775,16.21875 18.3099375,15.955125 17.8329375,15.955125 Z M19.4225625,6.7265625 L11.4763125,6.7265625 C11.079,6.7265625 10.6816875,6.9901875 10.6816875,7.3858125 C10.6816875,7.7814375 10.9995,8.0450625 11.4763125,8.0450625 L19.4225625,8.0450625 C19.8196875,8.0450625 20.217,7.7814375 20.217,7.3858125 C20.217,6.9901875 19.8991875,6.7265625 19.4225625,6.7265625 Z M17.8329375,10.6816875 C18.23025,10.6816875 18.6275625,10.4180625 18.6275625,10.0224375 C18.6275625,9.6268125 18.3099375,9.3631875 17.8329375,9.3631875 L11.4763125,9.3631875 C11.079,9.3631875 10.6816875,9.6268125 10.6816875,10.0224375 C10.6816875,10.4180625 10.9995,10.6816875 11.4763125,10.6816875 L17.8329375,10.6816875 L17.8329375,10.6816875 Z M19.4225625,13.3183125 L11.4763125,13.3183125 C11.079,13.3183125 10.6816875,13.5819375 10.6816875,13.9775625 C10.6816875,14.3731875 10.9995,14.6368125 11.4763125,14.6368125 L19.4225625,14.6368125 C19.8196875,14.6368125 20.217,14.3731875 20.217,13.9775625 C20.217,13.5819375 19.8991875,13.3183125 19.4225625,13.3183125 Z"
            id="形状"
            fill="currentColor"
          ></path>
          <path
            d="M7.63460779,3.4 C7.96597864,3.4 8.23460779,3.66862915 8.23460779,4 C8.23460779,4.29823376 8.01701818,4.54564675 7.73193091,4.59214701 L7.63460779,4.6 L5,4.6 C4.80670034,4.6 4.64542508,4.7371128 4.60812658,4.91938605 L4.6,5 L4.60093384,6.09185401 C5.41187294,6.34699488 6,7.10481522 6,8 C6,8.89518478 5.41187294,9.65300512 4.60093384,9.90814599 L4.6,10.4441997 C4.6,10.6374994 4.7371128,10.7987747 4.91938605,10.8360732 L5,10.8441997 L7,10.8441997 C7.83456362,10.8441997 8.51988979,11.4831613 8.59346132,12.2985671 L8.6,12.4441997 L8.60093384,13.091854 C9.41187294,13.3469949 10,14.1048152 10,15 C10,15.8951848 9.41187294,16.6530051 8.60093384,16.908146 L8.6,18 C8.6,18.8345636 7.96103848,19.5198898 7.14563261,19.5934613 L7,19.6 L4,19.6 C3.66862915,19.6 3.4,19.3313708 3.4,19 C3.4,18.7017662 3.61758961,18.4543532 3.90267688,18.407853 L4,18.4 L7,18.4 C7.19329966,18.4 7.35457492,18.2628872 7.39187342,18.080614 L7.4,18 L7.400077,16.9084637 C6.58861585,16.6536348 6,15.8955567 6,15 C6,14.1044433 6.58861585,13.3463652 7.400077,13.0915363 L7.4,12.4441997 C7.4,12.2509001 7.2628872,12.0896248 7.08061395,12.0523263 L7,12.0441997 L5,12.0441997 C4.16543638,12.0441997 3.48011021,11.4052382 3.40653868,10.5898324 L3.4,10.4441997 L3.400077,9.90846373 C2.58861585,9.65363477 2,8.89555669 2,8 C2,7.10444331 2.58861585,6.34636523 3.400077,6.09153627 L3.4,5 C3.4,4.16543638 4.03896152,3.48011021 4.85436739,3.40653868 L5,3.4 L7.63460779,3.4 Z M8,14 C7.44771525,14 7,14.4477153 7,15 C7,15.5522847 7.44771525,16 8,16 C8.55228475,16 9,15.5522847 9,15 C9,14.4477153 8.55228475,14 8,14 Z M4,7 C3.44771525,7 3,7.44771525 3,8 C3,8.55228475 3.44771525,9 4,9 C4.55228475,9 5,8.55228475 5,8 C5,7.44771525 4.55228475,7 4,7 Z"
            id="形状结合"
            fill="currentColor"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "SnakeIcon",
  props: {
    color: String,
  },
};
</script>