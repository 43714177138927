// 通用接口
import request from '@/utils/request1'

// 获取列表选项
export function getCategory() {
    return request.get('/category')
}

// 获取文件url
export function getFileUrl(id) {
    return request.defaults.baseURL + `files/${id}`
}

// 获取区域列表
export function getRegion(params) {
    return request.get('/regions', { params })
}

// 获取经纬度
export function getLocation(params) {
    return request.get(`/lbs/location`, { params })
}

// 获取问卷信息
export function getQuestion(id) {
    return request.get(`/surveys/${id}`)
}

// 提交问卷信息
export function submitQuestion(id, data) {
    return request.post(`/surveys/${id}/users`, data)
}

// 获取当前用户问卷结果
export function getMyQuestion(id = '61385b59c8125a006c52a1d8') {
    return request.get(`/surveys/${id}/users/0`)
}

// 获取问卷列表
export function getQuestionList() {
    return request.get(`/op/surveys`)
}
