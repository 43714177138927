<template>
  <a :href="`#/product-detail/${dataSource.id}`" class="card  inline-block border border-eee">
    <div class="photo">
      <img
        class="w-full h-full"
        v-if="dataSource.logo"
        :src="dataSource.logo"
        alt=""
      />
    </div>
    <div class="desc bg-f9 p-20">
      <div class="name font-bold ellipsis-2">{{ dataSource.name }}</div>
      <div
        class="tag-wrapper flex flex-wrap overflow-hidden items-center mt-10"
      >
        <img
          class="w-20 h-20 mr-10"
          src="@/assets/icons/industry1.svg"
          alt=""
        />
        <div
          v-if="dataSource.Industries.length"
          style="height: 26px; max-width: 200px"
          class="flex flex-wrap items-center overflow-hidden"
        >
          <div
            class="tag mb-10 mr-10 overflow-hidden border border-eee text-xs"
            v-for="(item, index) in dataSource.Industries"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
        <span class="text-sm" v-else style="height: 26px; line-height: 26px"
          >无</span
        >
      </div>
      <div class="tag-wrapper flex flex-wrap overflow-hidden items-center">
        <img class="w-20 h-20 mr-10" src="@/assets/icons/tag2.svg" alt="" />
        <div
          v-if="dataSource.tags.length"
          style="height: 26px; max-width: 200px"
          class="flex flex-wrap items-center overflow-hidden"
        >
          <div
            class="tag mb-10 mr-10 overflow-hidden border border-eee text-xs"
            v-for="(item, index) in dataSource.tags"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
        <span class="text-sm" v-else style="height: 26px; line-height: 26px"
          >无</span
        >
      </div>
    </div>
  </a>
</template>

<script>
export default {
  name: "product-card",
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style scoped>
.card {
  width: 285px;
}
.photo {
  height: 210px;
  overflow: hidden;
}
.photo:hover img {
  transition: all 0.3s ease;
  transform: scale(1.2);
}
.name {
  height: 48px;
}
.tag-wrapper {
  /* height: 26px; */
}
.tag-wrapper + .tag-wrapper {
  margin-top: 10px;
}
.tag {
  padding: 0 10px;
  line-height: 26px;
  height: 26px;
  border: 1px solid #eee !important;
  color: #333 !important;
}
</style>