import { getConfig } from '@/api/api/common'

export default {
    namespaced: true,
    state: {
        config: 0,
    },
    mutations: {
        setConfig(state, config) {
            state.config = config
        },
    },
    actions: {
        getConfig({ commit }) {
            getConfig().then(({ data }) => {
                commit('setConfig', data)
            })
        }
    }
}