import Vue from 'vue'
import VueRouter from 'vue-router'

import layout from '../layout/AdminLayout.vue'
import EntryLayout from '../layout/EntryLayout.vue'
import HomeLayout from '../layout/HomeLayout.vue'
import MessageLayout from '../layout/MessageLayout.vue'
import AdminLayout from '../layout/AdminLayout.vue'

Vue.use(VueRouter)

export const constRoutes = [
  {
    path: '/dashboard',
    redirect: '/dashboard/home',
    name: 'dashboard',
    component: layout,
    meta: { label: '仪表盘', roles: [2, 4, 5, 8] },
    children: [
      {
        name: 'dashboard-home-expert', path: 'home-expert', meta: { roles: [2], label: '我的首页' },
        component: () => import(/* webpackChunkName: 'home-expert' */ '@/views/admin/home/expert.vue')
      },
      {
        name: 'dashboard-home-company', path: 'home-company', meta: { roles: [4, 5], label: '我的首页' },
        component: () => import(/* webpackChunkName: 'home-company' */ '@/views/admin/home/company.vue')
      },
      {
        name: 'dashboard-partner', path: 'partner', meta: { roles: [4, 5], label: '星球伙伴' },
        component: () => import(/* webpackChunkName: 'dashboard-partner' */ '@/views/admin/partner/index.vue')
      },
      {
        name: 'dashboard-children-partner', path: 'children-partner', meta: { roles: [8], label: '星球伙伴' },
        component: () => import(/* webpackChunkName: 'dashboard-children-partner' */ '@/views/admin/partner/children.vue')
      },
    ]
  },
  {
    path: '/company',
    name: 'company',
    redirect: '/company/recoginze',
    component: layout,
    meta: { label: '我的企业', roles: [1, 3, 4, 5] },
    children: [
      {
        name: 'company-recoginze', path: 'recoginze', meta: { roles: [3, 4, 5], label: '认证企业账号' },
        component: () => import(/* webpackChunkName: 'recoginze' */ '@/views/admin/recoginze/index.vue')
      },
      {
        name: 'company-recoginze', path: 'recoginze', meta: { roles: [1], label: '升级企业账号' },
        component: () => import(/* webpackChunkName: 'recoginze' */ '@/views/admin/recoginze/index.vue')
      },
      {
        name: 'company-state', path: 'state', meta: { roles: [4, 5], is_tech: true, label: '企业情况' },
        component: () => import(/* webpackChunkName: 'state' */ '@/views/admin/state/index.vue')
      },
      {
        name: 'company-state2', path: 'state2', meta: { roles: [4, 5], is_tech: false, label: '企业情况' },
        component: () => import(/* webpackChunkName: 'state' */ '@/views/admin/state/index2.vue')
      },
      {
        name: 'company-leader', path: 'leader', meta: { roles: [5], label: '会员负责人' },
        component: () => import(/* webpackChunkName: 'leader' */ '@/views/admin/leader/index.vue')
      },
      {
        name: 'company-product', path: 'product', meta: { is_tech: true, roles: [4, 5], label: '核心产品' },
        component: () => import(/* webpackChunkName: 'company-product' */ '@/views/admin/product/index.vue')
      },
      {
        name: 'company-product1', path: 'product1', meta: { is_tech: false, roles: [4, 5], label: '主营产品' },
        component: () => import(/* webpackChunkName: 'company-product1' */ '@/views/admin/product1/index.vue')
      },
      {
        name: 'company-base', path: 'base', meta: { is_tech: false, roles: [4, 5], label: '生产基地' },
        component: () => import(/* webpackChunkName: 'company-base' */ '@/views/admin/base/index.vue')
      },
      {
        name: 'company-solution', path: 'solution', meta: { is_tech: true, roles: [4, 5], label: '解决方案' },
        component: () => import(/* webpackChunkName:'company-solution' */ '@/views/admin/solution/index.vue')
      },
      {
        name: 'company-case', path: 'case', meta: { is_tech: true, roles: [4, 5], label: '成功案例' },
        component: () => import(/* webpackChunkName: 'company-case' */ '@/views/admin/case/index.vue')
      },
      {
        name: 'company-caseone', path: 'caseone', meta: { is_tech: false, roles: [4, 5], label: '数字化情况' },
        component: () => import(/* webpackChunkName: 'company-case-1' */ '@/views/admin/state/case1.vue')
      },
      {
        name: 'company-casetwo', path: 'casetwo', meta: { is_tech: false, roles: [4, 5], label: '数字化转型大事记' },
        component: () => import(/* webpackChunkName: 'company-case-2' */ '@/views/admin/state/case2.vue')
      },
      {
        name: 'company-children-product', path: 'children-product', meta: { roles: [8], label: '核心产品' },
        component: () => import(/* webpackChunkName: 'company-children-product' */ '@/views/admin/product/children.vue')
      },
      {
        name: 'company-children-solution', path: 'children-solution', meta: { roles: [8], label: '解决方案' },
        component: () => import(/* webpackChunkName:'company-children-solution' */ '@/views/admin/solution/children.vue')
      },
      {
        name: 'company-children-case', path: 'children-case', meta: { roles: [8], label: '成功案例' },
        component: () => import(/* webpackChunkName: 'company-children-case' */ '@/views/admin/case/children.vue')
      },
      {
        name: 'company-expert', path: 'expert', meta: { roles: [4, 5], label: '专家' },
        component: () => import(/* webpackChunkName: 'company-expert' */ '@/views/admin/expert/index.vue')
      },
    ]
  },
  {
    path: '/requirement',
    name: 'requirement',
    redirect: '/requirement/recoginze',
    component: layout,
    meta: { label: '需求管理', roles: [2, 4, 5, 7] },
    children: [
      {
        name: 'requirement-apply', path: 'apply', meta: { roles: [4, 5], label: '我发布的需求' },
        component: () => import(/* webpackChunkName: 'apply' */ '@/views/requirement/companyApply.vue')
      },
      {
        name: 'requirement-apply', hidden: true, path: 'apply/:id', meta: { roles: [4, 5], label: '我发布的需求' },
        component: () => import(/* webpackChunkName: 'apply-detail' */ '@/views/requirement/companyApplyDetail.vue')
      },
      {
        name: 'manager-apply-evaluate', path: 'manager-apply', meta: { roles: [7], label: '发布需求审核' },
        component: () => import(/* webpackChunkName: 'manager-apply' */ '@/views/requirement/proApply.vue')
      },
      {
        name: 'manager-apply-evaluate', hidden: true, path: 'manager-apply/:id', meta: { roles: [7], label: '发布需求审核' },
        component: () => import(/* webpackChunkName: 'manager-apply-detail' */ '@/views/requirement/proApplyDetail.vue')
      },
      {
        name: 'expert-apply-evaluate', path: 'expert-apply', meta: { roles: [2], label: '发布需求审核' },
        component: () => import(/* webpackChunkName: 'expert-apply' */ '@/views/requirement/expertApply.vue')
      },
      {
        name: 'expert-apply-evaluate', hidden: true, path: 'expert-apply/:id', meta: { roles: [2], label: '发布需求审核' },
        component: () => import(/* webpackChunkName: 'expert-apply-detail' */ '@/views/requirement/expertApplyDetail.vue')
      },
      {
        name: 'requirement-supply', path: 'supply', meta: { roles: [4, 5], label: '我响应的需求' },
        component: () => import(/* webpackChunkName: 'supply' */ '@/views/requirement/companyResponse.vue')
      },
      {
        name: 'requirement-supply', hidden: true, path: 'supply/:id', meta: { roles: [4, 5], label: '我响应的需求' },
        component: () => import(/* webpackChunkName: 'supply-detail' */ '@/views/requirement/companyResponseDetail.vue')
      },
      {
        name: 'manager-supply-evaluate', path: 'manager-supply', meta: { roles: [7], label: '响应需求审核' },
        component: () => import(/* webpackChunkName: 'pro-response' */ '@/views/requirement/proResponse.vue')
      },
      {
        name: 'manager-supply-evaluate', hidden: true, path: 'manager-supply/:id', meta: { roles: [7], label: '响应需求审核' },
        component: () => import(/* webpackChunkName: 'pro-response-detail' */ '@/views/requirement/proResponseDetail.vue')
      },
      {
        name: 'expert-supply-evaluate', path: 'expert-supply', meta: { roles: [2], label: '响应需求审核' },
        component: () => import(/* webpackChunkName: 'expert-response' */ '@/views/requirement/expertResponse.vue')
      },
      {
        name: 'expert-supply-evaluate', hidden: true, path: 'expert-supply/:id', meta: { roles: [2], label: '响应需求审核' },
        component: () => import(/* webpackChunkName: 'expert-response-detail' */ '@/views/requirement/expertResponseDetail.vue')
      },
      {
        name: 'requirement-abutment', path: 'abutment', meta: { roles: [7], label: '需求对接管理' },
        component: () => import(/* webpackChunkName:'requirement-abutment' */ '@/views/requirement/abutment.vue')
      },
      {
        name: 'requirement-abutment', hidden: true, path: 'abutment-detail/:id', meta: { roles: [7], label: '需求对接管理' },
        component: () => import(/* webpackChunkName:'requirement-abutment-detail' */ '@/views/requirement/abutmentDetail.vue')
      },
      {
        name: 'requirement-children-apply', path: 'children-apply', meta: { roles: [8], label: '我发布的需求' },
        component: () => import(/* webpackChunkName: 'children-apply' */ '@/views/requirement/companyChildrenApply.vue')
      },
      {
        name: 'requirement-children-supply', path: 'children-supply', meta: { roles: [8], label: '我响应的需求' },
        component: () => import(/* webpackChunkName: 'children-supply' */ '@/views/requirement/companyChildrenResponse.vue')
      },
    ]
  },
  {
    path: '/knowledge',
    redirect: '/knowledge/expert',
    name: 'knowledge',
    component: layout,
    meta: { label: '知识管理', roles: [2] },
    children: [
      {
        name: 'knowledge-expert', path: 'expert', meta: { roles: [2], label: '我的知识' },
        component: () => import(/* webpackChunkName: 'knowledge-expert' */ '@/views/admin/knowledge/expert.vue')
      },
    ]
  },
  {
    path: '/evaluate',
    name: 'evaluate',
    redirect: '/evaluate/recoginze',
    component: layout,
    meta: { label: '后台审核', roles: [6, 7], evaluate: true },
    children: [
      {
        name: 'evaluate-company', disabled: true, path: 'company', meta: { roles: [6, 7], label: '企业库' },
        component: () => import(/* webpackChunkName: 'evaluate-company' */ '@/views/admin/company/list.vue')
      },
      {
        name: 'evaluate-company', hidden: true, disabled: true, path: 'company/:id', meta: { roles: [6, 7], label: '企业库' },
        component: () => import(/* webpackChunkName: 'evaluate-company' */ '@/views/admin/company/detail.vue')
      },
      {
        name: 'evaluate-recoginze', path: 'recoginze', meta: { roles: [6, 7], label: '认证信息' },
        component: () => import(/* webpackChunkName: 'evaluate-recoginze' */ '@/views/admin/recoginze/evaluate.vue')
      },
      {
        name: 'evaluate-recoginze', hidden: true, path: 'recoginze-detail/:id', meta: { roles: [6, 7], label: '认证信息' },
        component: () => import(/* webpackChunkName: 'evaluate-recoginze-detail' */ '@/views/admin/recoginze/index.vue')
      },
      {
        name: 'evaluate-state', path: 'state', meta: { roles: [6, 7], label: '企业情况' },
        component: () => import(/* webpackChunkName: 'evaluate-state' */ '@/views/admin/state/evaluate.vue')
      },
      {
        name: 'evaluate-state', hidden: true, path: 'state-detail/:id', meta: { roles: [6, 7], label: '企业情况' },
        component: () => import(/* webpackChunkName: 'evaluate-state-detail' */ '@/views/admin/state/index.vue')
      },
      {
        name: 'evaluate-state2', hidden: true, path: 'state-detail2/:id', meta: { roles: [6, 7], label: '企业情况' },
        component: () => import(/* webpackChunkName: 'evaluate-state-detail2' */ '@/views/admin/state/index2.vue')
      },
      {
        name: 'evaluate-leader', path: 'leader', meta: { roles: [6, 7], label: '会员负责人' },
        component: () => import(/* webpackChunkName: 'evaluate-leader' */ '@/views/admin/leader/evaluate.vue')
      },
      {
        name: 'evaluate-leader', hidden: true, path: 'leader-detail/:id', meta: { roles: [6, 7], label: '会员负责人' },
        component: () => import(/* webpackChunkName: 'evaluate-leader-detail' */ '@/views/admin/leader/index.vue')
      },
      {
        name: 'evaluate-event', path: 'event', meta: { roles: [6, 7], is_tech: false, label: '大事记管理' },
        component: () => import(/* webpackChunkName: 'evaluate-event' */ '@/views/admin/state/evaluate2.vue')
      },
      {
        name: 'evaluate-event', hidden: true, path: 'event-detail/:id', meta: { roles: [6, 7], is_tech: false, label: '大事记管理' },
        component: () => import(/* webpackChunkName: 'evaluate-event-detail' */ '@/views/admin/state/case2.vue')
      },
      {
        name: 'evaluate-case', path: 'case', meta: { roles: [6, 7], label: '案例管理' },
        component: () => import(/* webpackChunkName:'evaluate-case' */ '@/views/admin/case/evaluate.vue')
      },
      {
        name: 'evaluate-case', hidden: true, path: 'case-detail/:id', meta: { roles: [6, 7], label: '案例管理' },
        component: () => import(/* webpackChunkName:'evaluate-case-detail' */ '@/views/admin/case/index.vue')
      },
      {
        name: 'evaluate-product', path: 'product', meta: { roles: [6, 7], label: '产品管理' },
        component: () => import(/* webpackChunkName:'evaluate-product' */ '@/views/admin/product/evaluate.vue')
      },
      {
        name: 'evaluate-product', hidden: true, path: 'product-detail/:id', meta: { roles: [6, 7], label: '产品管理' },
        component: () => import(/* webpackChunkName:'evaluate-product-detail' */ '@/views/admin/product/index.vue')
      },
      {
        name: 'evaluate-base', path: 'base', meta: { roles: [6, 7], label: '生产基地管理' },
        component: () => import(/* webpackChunkName:'evaluate-base' */ '@/views/admin/base/evaluate.vue')
      },
      {
        name: 'evaluate-base', hidden: true, path: 'base-detail/:id', meta: { roles: [6, 7], label: '生产基地管理' },
        component: () => import(/* webpackChunkName:'evaluate-base-detail' */ '@/views/admin/base/index.vue')
      },
      {
        name: 'evaluate-solution', path: 'solution', meta: { roles: [6, 7], label: '方案管理' },
        component: () => import(/* webpackChunkName:'evaluate-solution' */ '@/views/admin/solution/evaluate.vue')
      },
      {
        name: 'evaluate-solution', hidden: true, path: 'solution-detail/:id', meta: { roles: [6, 7], label: '方案管理' },
        component: () => import(/* webpackChunkName:'evaluate-solution-detail' */ '@/views/admin/solution/index.vue')
      },
      {
        name: 'evaluate-expert', path: 'expert', meta: { roles: [6, 7], label: '专家管理' },
        component: () => import(/* webpackChunkName:'evaluate-expert' */ '@/views/admin/expert/evaluate.vue')
      },
      {
        name: 'evaluate-expert', hidden: true, path: 'expert-detail/:id', meta: { roles: [6, 7], label: '专家管理' },
        component: () => import(/* webpackChunkName:'expert-detail' */ '@/views/admin/expert/index.vue')
      },
      {
        name: 'evaluate-knowledge', path: 'knowledge', meta: { roles: [6, 7], label: '知识库管理' },
        component: () => import(/* webpackChunkName:'knowledge1' */ '@/views/admin/knowledge/index.vue')
      },
      {
        name: 'evaluate-activity', path: 'activity', meta: { roles: [6, 7], label: '活动管理' },
        component: () => import(/* webpackChunkName:'activity1' */ '@/views/admin/activity/manager.vue')
      },
      {
        name: 'evaluate-comment', path: 'comment', meta: { roles: [6, 7], label: '提问管理' },
        component: () => import(/* webpackChunkName:'evaluate-comment' */ '@/views/admin/comment/evaluate.vue')
      },
      {
        name: 'evaluate-comment', hidden: true, path: 'comment-detail/:id', meta: { roles: [6, 7], label: '提问管理' },
        component: () => import(/* webpackChunkName:'evaluate-comment-detail' */ '@/views/admin/comment/detail.vue')
      },

      // { name: 'evaluate-solution7', disabled: true, path: 'solution', meta: { roles: [6, 7], label: '用户管理' }, component: () => import(/* webpackChunkName:'solution' */ '@/views/admin/solution/index.vue') },
    ]
  },
  {
    name: 'evaluate-policy', path: 'evaluate-policy', component: layout, meta: { roles: [3, 4, 5, 6, 7], label: '政策直通车' },
    children: [
      {
        name: 'evaluate-policy-manager', path: '/evaluate/evaluate-policy-manager', meta: { roles: [6, 7], label: '政策审核' },
        component: () => import('@/views/admin/policy/manager.vue')
      },
      {
        name: 'evaluate-policy-manager', hidden: true, path: '/evaluate/evaluate-policy-manager/:id', meta: { roles: [6, 7], label: '政策审核' },
        component: () => import('@/views/admin/policy/detail.vue')
      },
      {
        name: 'evaluate-policy-apply', path: '/evaluate/evaluate-policy-apply', meta: { roles: [6, 7], label: '政策发布' },
        component: () => import('@/views/admin/policy/apply.vue')
      },
      {
        name: 'evaluate-policy-apply', hidden: true, path: '/evaluate/evaluate-policy-apply/:id', meta: { roles: [6, 7], label: '政策审核' },
        component: () => import('@/views/admin/policy/detail.vue')
      },
      {
        name: 'evaluate-policy-apply', hidden: true, path: '/evaluate/evaluate-policy-create', meta: { roles: [6, 7], label: '政策审核' },
        component: () => import('@/views/admin/policy/create.vue')
      },
      {
        name: 'evaluate-policy-comment', path: '/evaluate/evaluate-policy-comment', meta: { roles: [6, 7], label: '留言审核' },
        component: () => import('@/views/admin/policy/comment.vue')
      },
      {
        name: 'evaluate-policy-comment', hidden: true, path: '/evaluate/evaluate-policy-comment-detail/:id', meta: { roles: [6, 7], label: '留言审核' },
        component: () => import('@/views/admin/policy/commentDetail.vue')
      },
      {
        name: 'evaluate-policy-survey', path: '/evaluate/evaluate-policy-survey', meta: { roles: [3, 4, 5], label: '我的调研' },
        component: () => import('@/views/admin/policy/survey.vue')
      },
      {
        name: 'evaluate-policy-survey', hidden: true, path: '/evaluate/evaluate-policy-survey/:id', meta: { roles: [3, 4, 5], label: '我的调研' },
        component: () => import('@/views/admin/policy/surveyDetail.vue')
      },
      {
        name: 'evaluate-policy-collect', path: '/evaluate/evaluate-policy-collect', meta: { roles: [3, 4, 5], label: '我的征集' },
        component: () => import('@/views/admin/policy/collect.vue')
      },
      {
        name: 'evaluate-policy-collect', hidden: true, path: '/evaluate/evaluate-policy-collect/:id', meta: { roles: [3, 4, 5], label: '我的征集' },
        component: () => import('@/views/admin/policy/collectDetail.vue')
      },
    ]
  },
  {
    path: '/account',
    name: 'account',
    redirect: '/account',
    component: layout,
    meta: { label: '我的账号', roles: [1, 2, 3, 4, 5, 8] },
    children: [
      {   // 个人用户账号设置
        name: 'account-user', path: 'user-setting', meta: { roles: [1, 8], label: '账号设置' },
        component: () => import(/* webpackChunkName: 'account-user' */ '@/views/admin/account/user.vue')
      },
      {   // 专家用户账号设置
        name: 'account-expert', path: 'expert-setting', meta: { roles: [2], label: '账号设置' },
        component: () => import(/* webpackChunkName: 'account-expert' */ '@/views/admin/account/expert.vue')
      },
      {   // 企业用户账号设置
        name: 'account-company', path: 'company-setting', meta: { roles: [4, 5], label: '账号设置' },
        component: () => import(/* webpackChunkName: 'account-company' */ '@/views/admin/account/company.vue')
      },
      {   // 企业子账号管理
        name: 'account-children', path: 'account-children', meta: { roles: [4, 5], label: '子账号管理' },
        component: () => import(/* webpackChunkName: 'account-children' */ '@/views/admin/children/index.vue')
      },
      {  // 我的星球贝
        name: 'account-integral', disabled: true, path: 'integral', meta: { roles: [3, 4, 5, 8], label: '我的星球贝' },
        component: () => import(/* webpackChunkName: 'integral' */ '@/views/admin/integral/index.vue')
      },
      {
        name: 'account-activity', path: 'activity', meta: { roles: [4, 5], label: '我的活动' },
        component: () => import(/* webpackChunkName: 'account-activity' */ '@/views/admin/activity/company.vue')
      },
      { name: 'account-solution', disabled: true, path: 'solution', meta: { roles: [1, 2, 3, 4, 5, 8], label: '我的收藏' }, component: () => import(/* webpackChunkName:'account-solution' */ '@/views/admin/solution/index.vue') },
      { name: 'account-question', path: 'question', meta: { roles: [2, 4, 5], label: '我的锦囊' }, component: () => import(/* webpackChunkName:'question' */ '@/views/admin/question/index.vue') },
      { name: 'account-comment', path: 'comment', meta: { roles: [2, 4, 5], label: '我的提问' }, component: () => import(/* webpackChunkName:'account-comment' */ '@/views/admin/comment/index.vue') },
    ]
  },
  {
    path: '/user',
    name: 'user',
    component: layout,
    meta: { label: '用户管理', roles: [6, 7] },
    children: [
      {
        path: 'survey', name: 'evaluate-user-survey', meta: { roles: [6, 7], label: '用户调研' },
        component: () => import(/* webpackChunkName:'evaluate-user-survey' */ '@/views/admin/survey/index.vue')
      },
      {
        path: 'survey-detail/:id', hidden: true, name: 'evaluate-user-survey', meta: { roles: [6, 7], label: '用户调研' },
        component: () => import(/* webpackChunkName:'evaluate-survey-detail' */ '@/views/admin/survey/detail.vue')
      }
    ]
  }

]

const routes = [
  {
    path: '/auth',
    component: EntryLayout,
    children: [
      {
        path: 'login',
        name: 'login',
        alias: '/login',
        component: () => import(/* webpackChunkName: 'login' */ '../views/auth/login.vue')
      },
      {
        path: 'register',
        name: 'register',
        alias: '/register',
        component: () => import(/* webpackChunkName: 'register' */ '../views/auth/register.vue')
      },
      {
        path: 'forget',
        name: 'forget',
        alias: '/forget',
        component: () => import(/* webpackChunkName: 'forget' */ '../views/auth/forget.vue')
      },
      {
        path: 'survey',
        name: 'survey',
        alias: '/survey',
        component: () => import(/* webpackChunkName: 'survey' */ '../views/auth/survey.vue')
      },
    ]
  },
  {
    path: '/',
    component: HomeLayout,
    name: 'home',
    children: [
      {
        path: '/index', name: 'home-index', alias: '/',
        component: () => import(/* webpackChunkName: 'home-index' */ '../views/index/index.vue')
      },
      // {
      //   path: '/requirement-pool', name: 'home-requirement-pool', meta: { group: 'requirement' },
      //   component: () => import(/* webpackChunkName: 'home-requirement-pool' */ '../views/requirement/pool.vue')
      // },
      // {
      //   path: '/requirement-detail/:id', name: 'home-requirement-detail', meta: { group: 'requirement-list' },
      //   component: () => import(/* webpackChunkName: 'home-requirement-detail' */ '../views/requirement/detail.vue')
      // },
      // {
      //   path: '/requirement-response/:id', name: 'home-requirement-response', meta: { group: 'requirement' },
      //   component: () => import(/* webpackChunkName: 'home-requirement-response' */ '../views/requirement/response.vue')
      // },
      {
        path: '/requirement-apply', name: 'home-requirement-apply', meta: { group: 'requirement' },
        component: () => import(/* webpackChunkName: 'home-requirement-apply' */ '../views/requirement/apply.vue')
      },
      // {
      //   path: '/requirement-list', name: 'home-requirement-list', meta: { group: 'requirement-list' },
      //   component: () => import(/* webpackChunkName: 'home-requirement-list' */ '../views/requirement/list.vue')
      // },
      // {
      //   path: '/product-list', name: 'home-product-list', meta: { group: 'product' },
      //   component: () => import(/* webpackChunkName: 'home-product-list' */ '../views/product/list.vue')
      // },
      // {
      //   path: '/product-detail/:id', name: 'home-product-detail', meta: { group: 'product' },
      //   component: () => import(/* webpackChunkName: 'home-product-detail' */ '../views/product/detail.vue')
      // },
      // {
      //   path: '/case-list', name: 'home-case-list', meta: { group: 'case' },
      //   component: () => import(/* webpackChunkName: 'home-case-list' */ '../views/case/list.vue')
      // },
      // {
      //   path: '/case-detail/:id', name: 'home-case-detail', meta: { group: 'case' },
      //   component: () => import(/* webpackChunkName: 'home-case-detail' */ '../views/case/detail.vue')
      // },
      // {
      //   path: '/solution-list', name: 'home-solution-list', meta: { group: 'solution' },
      //   component: () => import(/* webpackChunkName: 'home-solution-list' */ '../views/solution/list.vue')
      // },
      // {
      //   path: '/solution-detail/:id', name: 'home-solution-detail', meta: { group: 'solution' },
      //   component: () => import(/* webpackChunkName: 'home-solution-detail' */ '../views/solution/detail.vue')
      // },
      // {
      //   path: '/activity-list', name: 'home-activity-list', meta: { group: 'activity' },
      //   component: () => import(/* webpackChunkName: 'home-activity-list' */ '../views/activity/list.vue')
      // },
      // {
      //   path: '/activity-detail/:id', name: 'home-activity-detail', meta: { group: 'activity' },
      //   component: () => import(/* webpackChunkName: 'home-activity-detail' */ '../views/activity/detail.vue')
      // },
      // {
      //   path: '/expert-list', name: 'home-expert-list', meta: { group: 'expert' },
      //   component: () => import(/* webpackChunkName: 'home-expert-list' */ '../views/expert/home.vue')
      // },
      // {
      //   path: '/expert-detail/:id', name: 'home-expert-detail', meta: { group: 'expert' },
      //   component: () => import(/* webpackChunkName: 'home-expert-detail' */ '../views/expert/detail.vue')
      // },
      // {
      //   path: '/knowledge-list', name: 'home-knowledge-list', meta: { group: 'knowledge' },
      //   component: () => import(/* webpackChunkName: 'home-knowledge-list' */ '../views/knowledge/home.vue')
      // },
      // {
      //   path: '/knowledge-detail/:id', name: 'home-knowledge-detail', meta: { group: 'knowledge' },
      //   component: () => import(/* webpackChunkName: 'home-knowledge-detail' */ '../views/knowledge/detail.vue')
      // },
      // {
      //   path: '/partner-list', name: 'home-partner-list', meta: { group: 'partner' },
      //   component: () => import(/* webpackChunkName: 'home-partner-list' */ '../views/partner/home.vue')
      // },
      // {
      //   path: '/partner-detail-science/:id', name: 'home-partner-detail-science', meta: { group: 'partner' },
      //   component: () => import(/* webpackChunkName: 'home-partner-detail-science' */ '../views/partner/detail.vue')
      // },
      // {
      //   path: '/partner-detail-industry/:id', name: 'home-partner-detail-industry', meta: { group: 'partner' },
      //   component: () => import(/* webpackChunkName: 'home-partner-detail-industry' */ '../views/partner/detail2.vue')
      // },
      // {
      //   path: '/policy-list', name: 'home-policy-list', meta: { group: 'policy' },
      //   component: () => import(/* webpackChunkName: 'home-policy-list' */ '../views/policy/list.vue')
      // },
      // {
      //   path: '/policy-detail/:id', name: 'home-policy-detail', meta: { group: 'policy' },
      //   component: () => import(/* webpackChunkName: 'home-policy-detail' */ '../views/policy/detail.vue')
      // },
      // {
      //   path: '/policy-apply', name: 'home-policy-apply', meta: { group: 'policy' },
      //   component: () => import(/* webpackChunkName: 'home-policy-apply' */ '../views/policy/apply.vue')
      // },
      {
        path: '/message', name: 'message', component: MessageLayout, redirect: '/message/system',
        children: [
          {
            path: 'system', name: 'message-system', meta: { activeMenu: 'message-system', label: '系统通知' },
            component: () => import(/* webpackChunkName: 'message-system' */ '../views/message/index.vue')
          },
          {
            path: 'activity', name: 'message-activity', meta: { activeMenu: 'message-activity', label: '活动通知' },
            component: () => import(/* webpackChunkName: 'message-activity' */ '../views/message/activity.vue')
          }
        ]
      },
      // {
      //   path: '/result', name: 'result',
      //   component: () => import(/* webpackChunkName: 'result' */ '../views/result/index.vue')
      // },
      // ...constRoutes
    ]
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
