<template>
  <div>
    <a-row :gutter="gutter" v-if="list.length">
      <a-col
        :span="span"
        v-for="(item, index) in list"
        :key="item.id"
        :style="
          index < 24 / span
            ? { 'margin-top': 0 }
            : { 'margin-top': `${gutter}px` }
        "
      >
        <div class="inline-block hover:shadow w-full">
          <slot v-bind:dataSource="item"></slot>
        </div>
      </a-col>
    </a-row>
    <a-empty v-else></a-empty>
  </div>
</template>

<script>
export default {
  name: "FilterRows",
  props: {
    gutter: {
      type: Number,
      default: 20,
    },
    span: {
      type: Number,
      default: 1,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>