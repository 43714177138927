<template>
  <div
    :style="{ width: width + 'px', height: height + 'px' }"
    :class="[{ 'border border-eee': border }, { 'rounded-full': rounded }]"
    class="overflow-hidden"
  >
    <img
      class="w-full h-full object-contain"
      v-if="profile"
      :src="profile"
      alt=""
    />
    <img
      v-else
      class="w-full h-full object-contain"
      src="@/assets/images/no-logo.png"
      alt=""
    />
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 32,
    },
    height: {
      type: Number,
      default: 32,
    },
    border: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    profile() {
      const profile = this.$store.state.user.USER_INFO.Profile;
      if (profile) {
        return profile.url;
      }

      return "";
    },
  },
};
</script>