<template>
  <div class="text-333 text-sm">
    <a-row class="pt-10">
      <a-col :span="24">
        <div class="mt-10" v-for="(item, index) in model.Competitives" :key="index">
          <span> {{ index + 1 }}、{{ item.Keyword }} </span>
          ---
          <span class="text-999">{{ item.Desc }}</span>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
