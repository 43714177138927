<template>
  <div class="app">
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
  </div>
</template>
<script>
import zhCN from "ant-design-vue/es/locale/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";

moment.locale("zh-cn");
export default {
  data() {
    return {
      locale: zhCN,
    };
  },
  created() {
    this.$store.dispatch("config/getConfig");
    if (this.isLogin) {
      this.$store.dispatch("getReg");
    }
  },
  computed: {
    isLogin() {
      return this.$store.state.userInfo && this.$store.state.userInfo.id;
    },
  },
};
</script>

<style lang="less">
.app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333;
  font-size: 16px;
}
</style>
