<template>
  <div class="relative gf-form-item-wrapper">
    <a-form-model-item
      :wrapperCol="{ span }"
      :prop="prop"
      :label="label"
      :rules="[
        {
          max: max ? max : 65535,
          message: '字数超出限制',
          trigger: ['change', 'blur'],
        },
      ]"
    >
      <a-textarea
        :maxLength="maxLength"
        :value="value"
        v-bind="$attrs"
        v-on="$listeners"
      ></a-textarea>
    </a-form-model-item>
    <div class="absolute bottom-10 right-10 text-xs text-999" v-if="max">
      <span>{{ value ? value.length : 0 }}</span>
      /
      <span>{{ max }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "gf-textarea",
  model: {
    prop: "value",
    event: "change.value",
  },
  props: {
    value: {
      type: String,
    },
    max: {
      type: Number,
      default: 0,
    },
    prop: String,
    label: String,
    span: {
      type: Number,
      default: 24,
    },
    maxLength: {
      type: Number,
      default: 500,
    },
  },
};
</script>