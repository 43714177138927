import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

Vue.config.productionTip = false

import Antd from 'ant-design-vue'
import '@/components'

import 'animate.css/animate.min.css'

import '@/style/index.less'

import './utils/filter'

import './premission.js'

Vue.use(Antd)

Vue.directive('focus', (el) => {
  Vue.nextTick(() => {
    const input = el.querySelector('textarea')
    input.focus()
  })

})

// import { getCategory } from '@/api'
// import { sessionGet, sessionSet } from '@/utils/storage.js'
// import { message } from 'ant-design-vue'

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

// async function setupCategory(router, store, App) {
//   let options = {}
//   const temp = sessionGet('options')
//   Object.assign(options, temp)

//   try {
//     if (!options.turn) {
//       const { data } = await getCategory()
//       Object.assign(options, {
//         whether: [
//           { ID: 1, Name: '是' },
//           { ID: 2, Name: '否' },
//         ],
//         status: [
//           { ID: 1, Name: '待审核' },
//           { ID: 2, Name: '审核通过' },
//           { ID: 3, Name: '审核拒绝' },
//         ]
//       }, {
//         field: data.CompanyField,
//         turn: data.CompanyFinance,
//         industry: data.CompanyIndustry,
//         nature: data.CompanyProperty,
//         tag: data.CompanyTag,
//         scale: data.CompanyScale,
//         gender: data.Gender,
//         honorClass: data.HonorClass,
//         honorLevel: data.HonorLevel,
//         membership: data.Membership,
//         patentClass: data.PatentClass,
//         political: data.Political,
//         education: data.Education,
//         scene: data.Scene,
//         service: data.ServiceClass,
//         expertField: data.ExpertField,
//         KnowledgeClass: data.KnowledgeClass
//       })
//       sessionSet('options', options)
//     }
//   } catch {
//     message.error('获取数据失败1')
//   } finally {

//   }

// }

// setupCategory(router, store, App)
store.dispatch('setupCategory')
