import { request } from '@/utils/request.js'
import {
    sendBindingCode,
    sendRegisterCode,
    sendResetCode,
    bindEmail,
    getUserInfo,
    updateUserInfo,
    login,
    setPassword,
    register,
    reset
} from './user.js'

export {
    sendBindingCode,
    sendRegisterCode,
    sendResetCode,
    bindEmail,
    getUserInfo,
    updateUserInfo,
    login,
    setPassword,
    register,
    reset
}

export function validateCode(id, params) {
    return request.get(`/company/${id}/check_credit_code`, { params })
}

export function getInitiator() {
    return request.get(`/user/company/initiator`)
}

export function saveInitiator(data) {
    return request.post(`/user/company/initiator`, data)
}

// 获取文件连接
export function getUrl(id) {
    return `${request.defaults.baseURL}/files/${id}?thumbnail=medium`
}

export function download(id) {
    return request.get(`/files/${id}`, { responseType: 'blob' })
}

export function evaluateInitiator() {
    return request.post(`/user/company/initiator/evaluate`)
}

export function upload() {
    return `${request.defaults.baseURL}/files`
}

export function getCompanyRecoginze() {
    return request.get(`user/company`)
}

export function saveCompanyRecoginze(data) {
    return request.put(`/user/company`, data)
}

export function evaluateCompanyRecoginze() {
    return request.post(`/user/company/evaluate`)
}

export function evaluateCompanyState() {
    return request.post(`user/company/state/evaluate`)
}

export function getCategory() {
    return request.get(`/category`)
}

export function getProducts() {
    return request.get('user/company/products')
}

export function getProduct(id) {
    return request.get(`/products/${id}`)
}

export function createProduct(data) {
    return request.post(`/products`, data)
}

export function saveProduct(data) {
    return request.put(`/products/${data.ID}`, data)
}

export function deleteProduct(id) {
    return request.delete(`/products/${id}`)
}

export function evaluateProduct(id) {
    return request.post(`/products/${id}/submit`)
}

export function getSolutions() {
    return request.get('user/company/solutions')
}

export function getSolution(id) {
    return request.get(`/solutions/${id}`)
}

export function createSolution(data) {
    return request.post(`/solutions`, data)
}

export function saveSolution(data) {
    return request.put(`/solutions/${data.ID}`, data)
}

export function deleteSolution(id) {
    return request.delete(`/solutions/${id}`)
}

export function evaluateSolution(id) {
    return request.post(`/solutions/${id}/submit`)
}

export function getCases() {
    return request.get('user/company/cases')
}

export function getCase(id) {
    return request.get(`/cases/${id}`)
}

export function createCase(data) {
    return request.post(`/cases`, data)
}

export function saveCase(data) {
    return request.put(`/cases/${data.ID}`, data)
}

export function deleteCase(id) {
    return request.delete(`/cases/${id}`)
}

export function evaluateCase(id) {
    return request.post(`/cases/${id}/submit`)
}

export function getExperts() {
    return request.get('user/company/experts')
}

export function getExpert(id) {
    return request.get(`/experts/${id}`)
}

export function createExpert(data) {
    return request.post(`/experts`, data)
}

export function saveExpert(data) {
    return request.put(`/experts/${data.ID}`, data)
}

export function deleteExpert(id) {
    return request.delete(`/experts/${id}`)
}

export function evaluateExpert(id) {
    return request.post(`/experts/${id}/submit`)
}

export function updateDigital(id, data) {
    return request.put(`/company/${id}/digital`, data)
}

export function updateSurvey(id, data) {
    return request.put(`/op/company/${id}/survey`, data)
}