<template>
  <Editor v-model="data" :init="init"></Editor>
</template>

<script>
import tinymce from "tinymce";
import 'tinymce/themes/modern/theme';
import Editor from "@tinymce/tinymce-vue";
export default {
  name: "GfEditor",
  components: { Editor },
  props: {
    value: String,
  },
  model: {
    prop: "value",
    event: "change",
  },
  created() {
    this.data = this.value;
  },
  data() {
    return {
      data: "",
      init: {
        branding: false,
        menubar: false,
        statusbar: false,
        language: "zh_CN",
        language_url: "/languages/zh_CN.js",
        skin_url: '/skins/lightgray',
        min_height: 600,
      },
    };
  },
  watch: {
    data(val) {
      this.$emit("change", val);
    },
    value(val) {
      this.data = val;
    },
  },
};
</script>