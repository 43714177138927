<template>
  <div>
    <a-form-model
      ref="container"
      :rules="rules"
      :model="dataSource"
      :colon="false"
      :labelCol="{ span: 6 }"
      :wrapperCol="{ span: 15 }"
      v-bind="$attrs"
      labelAlign="left"
    >
      <a-form-model-item prop="FieldIDs">
        <div slot="label" class="h-40 flex items-center">
          <span>所属领域</span>
        </div>
        <a-select
          placeholder="请选择，最多选择4个"
          size="large"
          mode="multiple"
          v-model="dataSource.FieldIDs"
          @change="change1"
        >
          <a-select-option
            v-for="option in field"
            :key="option.ID"
            :value="option.ID"
          >
            {{ option.Name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop="Field" v-if="dataSource.FieldIDs.includes(-1)">
        <div slot="label" class="h-40 flex items-center">
          <span>自定义领域</span>
        </div>

        <a-select
          mode="tags"
          size="large"
          v-model="dataSource.Field"
        ></a-select>
      </a-form-model-item>
      <a-form-model-item prop="IndustryIDs">
        <div slot="label" class="h-40 flex items-center">
          <span>{{ industryTitle }}</span>
          <a-tooltip class="ml-10">
            <span slot="title"
              >您选择的服务行业将取前三个呈现在贵司的企业画像中，请酌情选择</span
            >
            <a-icon type="question-circle" theme="filled" style="color: #ddd" />
          </a-tooltip>
        </div>
        <a-select
          placeholder="请选择，最多选择5个"
          size="large"
          mode="multiple"
          v-model="dataSource.IndustryIDs"
          @change="change2"
        >
          <a-select-option
            v-for="option in industry"
            :key="option.ID"
            :value="option.ID"
          >
            {{ option.Name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        prop="Industry"
        v-if="dataSource.IndustryIDs.includes(-1)"
      >
        <div slot="label" class="h-40 flex items-center">
          <span>自定义行业</span>
        </div>

        <a-select
          mode="tags"
          size="large"
          v-model="dataSource.Industry"
        ></a-select>
      </a-form-model-item>
      <a-form-model-item prop="TagIDs">
        <div slot="label" class="h-40 flex items-center">
          <span>企业标签</span>
        </div>
        <a-select
          placeholder="请选择，最多选择10个"
          size="large"
          mode="multiple"
          v-model="dataSource.TagIDs"
          @change="change3"
        >
          <a-select-option
            v-for="option in tag"
            :key="option.ID"
            :value="option.ID"
          >
            {{ option.Name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop="Tag" v-if="dataSource.TagIDs.includes(-1)">
        <div slot="label" class="h-40 flex items-center">
          <span>自定义标签</span>
        </div>

        <a-select mode="tags" size="large" v-model="dataSource.Tag"></a-select>
      </a-form-model-item>
    </a-form-model>
    <div class="text-right mt-40">
      <a-space>
        <a-button size="large" @click="cancel" type="primary" ghost
          >取消</a-button
        >
        <a-button size="large" @click="save" type="primary">保存</a-button>
      </a-space>
    </div>
  </div>
</template>

<script>
function validate1($this) {
  return (rule, value, callback) => {
    let total =
      $this.dataSource.FieldIDs.length + $this.dataSource.Field.length;
    if ($this.dataSource.FieldIDs.includes(-1)) {
      total--;
    }
    if (total === 0) {
      return callback(new Error("请选择"));
    }

    if (total > 4) {
      return callback(new Error("最多只能选择4个"));
    }

    callback();
  };
}
function validate2($this) {
  return (rule, value, callback) => {
    let total =
      $this.dataSource.IndustryIDs.length + $this.dataSource.Industry.length;
    if ($this.dataSource.IndustryIDs.includes(-1)) {
      total--;
    }
    if (total === 0) {
      return callback(new Error("请选择"));
    }

    if (total > 5) {
      return callback(new Error("最多只能选择5个"));
    }

    callback();
  };
}
function validate3($this) {
  return (rule, value, callback) => {
    let total = $this.dataSource.TagIDs.length + $this.dataSource.Tag.length;
    if ($this.dataSource.TagIDs.includes($this.tag.length)) {
      total--;
    }
    if (total === 0) {
      return callback(new Error("请选择"));
    }

    if (total > 10) {
      return callback(new Error("最多只能选择10个"));
    }

    callback();
  };
}
export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dataSource: {},
    };
  },
  computed: {
    tag() {
      return this.options.tag;
    },
    field() {
      return this.options.field;
    },
    industry() {
      return this.options.industry;
    },
    container() {
      return this.$refs.container;
    },
    industryTitle() {
      return this.dataSource.FieldIDs.includes(1) ? "所属行业" : "服务行业";
    },
    rules() {
      return {
        IndustryIDs: [
          {
            required: !this.$store.getters.isManager,
            message: "请选择",
            trigger: "blur",
          },
          { validator: validate2(this), trigger: ["change", "blur"] },
        ],
        Industry: [
          {
            required: !this.$store.getters.isManager,
            message: "请填写",
            trigger: "blur",
          },
          { validator: validate2(this), trigger: ["change", "blur"] },
        ],
        FieldIDs: [
          {
            required: !this.$store.getters.isManager,
            message: "请选择",
            trigger: "blur",
          },
          { validator: validate1(this), trigger: ["change", "blur"] },
        ],
        Field: [
          {
            required: !this.$store.getters.isManager,
            message: "请填写",
            trigger: "blur",
          },
          { validator: validate1(this), trigger: ["change", "blur"] },
        ],
        TagIDs: [
          {
            required: !this.$store.getters.isManager,
            message: "请选择",
            trigger: "blur",
          },
          { validator: validate3(this), trigger: ["change", "blur"] },
        ],
        Tag: [
          { required: "true", message: "请填写", trigger: "blur" },
          { validator: validate3(this), trigger: ["change", "blur"] },
        ],
      };
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    save() {
      this.$refs.container.validate((valid) => {
        if (valid) {
          this.$emit("save", this.dataSource);
        }
      });
    },
    change1(e) {
      if (!e.includes(-1)) {
        this.dataSource.Field = [];
      }
    },
    change2(e) {
      if (!e.includes(-1)) {
        this.dataSource.Industry = [];
      }
    },
    change3(e) {
      if (!e.includes(-1)) {
        this.dataSource.Tag = [];
      }
    },
  },
  watch: {
    model: {
      handler(val) {
        const temp = { ...val };

        temp.IndustryIDs = temp.Industries.filter((item) => item.ID !== 0).map(
          (item) => item.ID
        );
        temp.Industry = temp.Industries.filter((item) => item.ID === 0).map(
          (item) => item.Name
        );

        temp.FieldIDs = temp.Fields.filter((item) => item.ID !== 0).map(
          (item) => item.ID
        );
        temp.Field = temp.Fields.filter((item) => item.ID === 0).map(
          (item) => item.Name
        );

        temp.TagIDs = temp.Tags.filter((item) => item.ID !== 0).map(
          (item) => item.ID
        );
        temp.Tag = temp.Tags.filter((item) => item.ID === 0).map(
          (item) => item.Name
        );
        if (temp.Industry.length) {
          temp.IndustryIDs.push(-1);
        }
        if (temp.Field.length) {
          temp.FieldIDs.push(-1);
        }
        if (temp.Tag.length) {
          temp.TagIDs.push(-1);
        }

        this.dataSource = temp;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>