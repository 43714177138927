<template>
  <div class="p-20 bg-white border border-eee">
    <div class="flex">
      <div
        class="
          mr-20
          border border-eee
          relative
          rounded-full rounded-br-none
          overflow-hidden
        "
        style="width: 48px; height: 48px"
      >
        <img
          class="w-full h-full object-contain"
          v-if="dataSource.userPhoto"
          :src="dataSource.userPhoto.url"
          alt=""
        />
      </div>
      <div style="width: calc(100% - 68px); height: 134px" class="relative">
        <div class=" ellipsis-2 mb-10 text-xs">
          <span class="font-bold text-primary mr-20">{{ dataSource.userName }}</span>
          <a-space class="text-999 text-xs">
            <span>
              {{ dataSource.updateTime }}
            </span>
            <span>|</span>
            <span>{{ dataSource.statusName }}</span>
          </a-space>
        </div>
        <div class="ellipsis-2 mb-20">
          {{ dataSource.title }}
        </div>
        <div class="absolute bottom-0 w-full">
          <slot name="opt" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "detail-comment-card",
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>