import router from "./router";
import store from './store'

import { transformCompany } from '@/utils/transform/company.js'

import { localGet } from '@/utils/storage.js'

router.beforeEach((to, from, next) => {
    const token = localGet('token')
    if (token) {
        store.dispatch('user/GET_USER_INFO').then((res) => {
            store.commit('setUserInfo', res)
            store.dispatch("message/getMessage", res.id)
            store.dispatch('user/GET_COMPANY_INFO').then(res => {
                store.commit('setCompanyInfo', transformCompany(res))
            })
            next()
        }).catch(() => {
            store.dispatch('user/LOGOUT')
            store.dispatch('logout')
            next()
        })
    } else {
        next()
    }
})

router.afterEach((to, from) => {
    const info = store.state.userInfo
    const temp = {
        uri: to.fullPath,
        uid: info.UID || info.id,
        type: 'view'
    }
    store.dispatch('track/setTrack', temp)
})