import {
    transformOptions,
    transformAttachment,
    transformStatus,
    getTime
} from './_util'

export function transformCompany(data) {
    const company = data || {},
        detail = company.Detail || {},
        info = detail.Info || {},
        leader = detail.Initiator || {},
        operator = detail.Operator || {},
        market = detail.MarketStaff || {},
        contact1 = detail.Contact || {},
        contact = detail.ContactStaff || {},
        finance = detail.Finance || {},
        state = detail.State || {},
        marketOption = company.MarketStaffOption,
        contactOption = company.ContactStaffOption,
        Scale = transformOptions(finance.Scale, 'CompanyScale') || {},
        Property = transformOptions(info.Property, 'CompanyProperty') || {};
    if (contactOption === 1) {
        contact = operator
    }

    if (marketOption === 1) {
        market = operator
    } else if (marketOption === 2) {
        market = contact
    }
    const temp = {
        Website: contact1.Website, // 公司网站
        Employees: finance.Employees, // 员工人数
        Revenues: finance.Revenues, //年度营收
        id: data.ID, // 公司id
        feedback: detail.Feedback, // 审核意见
        status: company.AuditStatus, // 公司审核状态
        statusName: transformStatus(company.AuditStatus), // 审核状态字符串
        createdTime: new Date(company.CreatedAt).getTime(), // 公司创建时间戳
        submitedTime: new Date(company.SubmittedAt).getTime(), // 公司创建时间戳
        public: data.Public, // 是否公开
        companyName: info.Name, // 公司名称
        logo: transformAttachment(info.Logo) || {},
        marketName: leader.Name, // 负责人姓名
        marketEmail: leader.Email, // 负责人邮箱
        marketPosition: leader.Position, // 负责人职务
        marketPhone: leader.Phone, // 负责人电话
        marketGender: market.Gender, // 负责人性别
        marketMedium: market.Medium, // 负责人媒体
        marketWechat: market.Wechat, // 负责人微信
        marketWechatPub: market.WechatPub, // 负责人公众号
        marketWebsite: market.Website, // 负责人网站
        operatorName: operator.Name, // 运营人姓名
        operatorDepertment: operator.Depertment, // 运营人部门
        operatorGender: operator.Gender, // 运营人性别
        operatorIdentity: operator.Identity, // 运营人身份证
        operatorPhone: operator.Phone, // 运营人电话
        operatorPosition: operator.Position, // 运营人职位
        operatorTelephone: operator.Telephone, // 运营人座机
        operatorWechat: operator.Wechat, // 运营人微信号
        operatorEmail: operator.Email, // 运营人邮箱
        operatorFront: transformAttachment(operator.IDFront) || {}, // 运营人身份证正面
        operatorBack: transformAttachment(operator.IDBack) || {}, // 运营人身份证反面
        operatorMissive: transformAttachment(operator.IDMissive) || {}, // 运营人公函
        contactName: contact.Name, // 对接人姓名
        contactDepertment: contact.Depertment, // 对接人部门
        contactGender: contact.Gender, // 对接人性别
        contactIdentity: contact.Identity, // 对接人身份证
        contactPhone: contact.Phone, // 对接人电话
        contactPosition: contact.Position, // 对接人职位
        contactTelephone: contact.Telephone, // 对接人座机
        contactWechat: contact.Wechat, // 对接人微信号
        contactEmail: contact.Email, // 对接人邮箱
        contactFront: transformAttachment(contact.IDFront) || {}, // 对接人身份证正面
        contactBack: transformAttachment(contact.IDBack) || {}, // 对接人身份证反面
        marketOption,
        registerAddress: info.RegisterAddress,
        businessAddress: info.BusinessAddress,
        Representative: info.Representative,
        RegisterCapital: info.RegisterCapital,
        RegisterAt: getTime(info.RegisterAt),
        RegisterAt: info.RegisterAt,
        Listed: finance.Listed,
        Resercher: finance.Resercher,
        Scale,
        Property,
        Team: state.Team,
    }
    return temp;
}