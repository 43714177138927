import request from '@/utils/request1'

// 获取消息详情
export function getMessage(id) {
    return request.get(`/messages/${id}`)
}

// 设置消息已读
export function updateMessageState(id) {
    return request.post(`/messages/${id}/read`)
}

// 获取用户消息列表
export function getMessageList(id, params) {
    return request.get(`/users/${id}/messages`, { params })
}