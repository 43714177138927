<template>
  <div class="p-20 bg-white rounded h-full text-left">
    <div class="mb-40" v-if="$slots.opt">
      <slot name="opt"></slot>
    </div>
    <div class="mb-20" v-if="filters">
      <StatusFilterSelect
        :filters="filters"
        v-bind="$attrs"
        @change="$emit('change', { status: $event.target.value, current: 1 })"
      ></StatusFilterSelect>
    </div>
    <div class="mb-40" v-if="list.length">
      <FilterRows :gutter="gutter" :span="span" :list="list">
        <slot
          name="card"
          slot-scope="{ dataSource }"
          v-bind:dataSource="dataSource"
        ></slot>
      </FilterRows>
    </div>

    <div class="text-center" v-if="list.length">
      <a-pagination
        :current="current"
        :pageSize="limit"
        :total="total"
        @change="$emit('change', { current: $event })"
      ></a-pagination>
    </div>

    <gf-empty v-else></gf-empty>
  </div>
</template>

<script>
export default {
  name: "StatusFilterList",
  props: {
    span: {
      type: Number,
      default: 24,
    },
    current: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 0,
    },
    gutter: {
      type: Number,
      default: 20,
    },
    list: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: [Array, Boolean],
      default: () => [],
    },
  },
};
</script>