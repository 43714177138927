import { validateCode } from '@/api'


export function validateZip(rule, val, callback) {
    if (!val) {
        return callback()
    }
    if (/^[0-9]{6}$/.test(val)) {
        callback()
    } else {
        callback(new Error(rule.message))
    }
}

export function validatePhone(rule, val, callback) {
    if (!val) {
        return callback()
    }
    if (/^[0][1-9]{2,3}-[0-9]{5,10}$/.test(val)) {
        callback()
    } else {
        callback(new Error(rule.message))
    }
}

export function validateFax(rule, val, callback) {

    if (!val) {
        return callback()
    }
    if (/^(?:\d{3,4}-)?\d{7,8}(?:-\d{1,6})?$/.test(val)) {
        callback()
    } else {
        callback(new Error(rule.message))
    }
}

// 验证邮箱格式
export function validateEmail(rule, val, callback) {
    if (!val) {
        return callback()
    }
    if (/^[a-z0-9A-Z](?![a-z0-9A-Z]?[@\+\-\_\.]{2,})[\w\+\.\-]*[a-z0-9A-Z]@[a-z0-9A-Z][a-z0-9A-Z\-]*[a-z0-9A-Z]\.[a-z0-9A-Z]*([a-z0-9A-Z]+|\.?[a-z0-9A-Z]+)$/.test(val)) {
        callback()
    } else {
        callback(new Error(rule.message))
    }
}

// 验证身份证号码格式
export function validateId(rule, val, callback) {
    // if (!val) {
    //     return callback()
    // }
    // if (/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(val)) {
    //     callback()
    // } else {
    //     callback(new Error(rule.message))
    // }

    return callback()
}

// 验证选中个数
export function validateArrayCount(min, max) {
    return (rule, value, callback) => {
        if (value.length >= min && value.length <= max) {
            callback()
        } else if (value.length < min) {
            callback(new Error(rule.message.min))
        } else if (value.length > max) {
            callback(new Error(rule.message.max))
        }
    }
}

// 验证信用码是否存在
export function validateCodeExist(id) {
    return (rule, code, callback) => {
        if ({ code }) {
            validateCode(id, { code }).then(res => {
                if (res.data.exist) {
                    callback(new Error(rule.message))
                } else {
                    callback()
                }
            }).catch(() => {
                callback(new Error('验证信用码是否存在失败'))
            })
        }
    }

}

// 验证手机号码格式
export function validateTel(rule, val, callback) {
    if (!val) {
        return callback()
    }
    if (/^[1]([3-9])[0-9]{9}$/.test(val)) {
        callback()
    } else {
        callback(new Error(rule.message))
    }
}


// 验证密码格式
export function validatePassword(rule, val, callback) {
    if (!val) {
        return callback()
    }
    if (/^(([a-zA-Z]+[0-9]+)|([0-9]+[a-zA-Z]+)|([a-z]+[!_@#%])|([0-9]+[!_@#%]))([a-zA-Z0-9!_@#%]*)$/.test(val)) {
        callback()
    } else {
        callback(new Error(rule.message))
    }
}