import { getFileUrl } from '@/api1/common'

export function transformAttachment(attachments) {
    if (!attachments) return;

    let temp;
    if (Array.isArray(attachments)) {
        temp = attachments.map(attach => {
            return {
                ...attach,
                url: getFileUrl(attach.ID)
            }
        })
    } else {
        temp = {
            ...attachments,
            url: getFileUrl(attachments.ID)
        }
    }
    return temp;
}