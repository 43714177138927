<template>
  <div class="relative">
    <div class="flex items-baseline">
      <a-icon
        type="star"
        theme="filled"
        class="text-base mr-10 text-primary"
        v-if="checked"
      />
      <a-icon type="star" class="text-base mr-10 text-primary" v-else />
      <slot></slot>
    </div>

    <input
      ref="checkbox"
      :checked="checked"
      @change="change"
      class="absolute w-full h-full top-0 left-0 opacity-0"
      type="checkbox"
      name=""
      id=""
    />
  </div>
</template>

<script>
export default {
  name: "GCollection",
  props: {
    value: Boolean,
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      checked: false,
    };
  },
  methods: {
    change(e) {
      this.$emit("change", e.target.checked);
      this.checked = this.value;
      this.$refs.checkbox.checked = this.value;
    },
  },
  watch: {
    value: {
      handler(val) {
        this.checked = val;
      },
      immediate: true,
    },
  },
};
</script>