<template>
  <premission-link :href="`#/policy-detail/${dataSource.ID}`">
    <div class="border border-eee">
      <div style="height: 80px">
        <img class="w-full h-full object-contain" :src="header" alt="" />
      </div>
      <div class="p-20 text-left">
        <div class="ellipsis-2 font-bold mb-20" style="height: 48px">
          {{ dataSource.Title }}
        </div>
        <div class="flex justify-between text-999 text-xs items-center">
          <div>{{ dataSource.Region.Name }}</div>
          <div>{{ dataSource.PublicTime | formatterTime("YYYY-MM-DD") }}</div>
        </div>
      </div>
    </div>
  </premission-link>
</template>

<script>
import zxdt from "@/assets/images/zxdt.png";
import kszj from "@/assets/images/kszj.png";
import sbzl from "@/assets/images/sbzl.png";
import zcjx from "@/assets/images/zcjx.png";
import zlxc from "@/assets/images/zlxc.png";
export default {
  name: "PolicyCard",
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    header() {
      if (this.dataSource.Type.ID === 1) {
        return sbzl;
      } else if (this.dataSource.Type.ID === 2) {
        return zlxc;
      } else if (this.dataSource.Type.ID === 3) {
        return zcjx;
      } else if (this.dataSource.Type.ID === 4) {
        return zxdt;
      } else if (this.dataSource.Type.ID === 5) {
        return kszj;
      } else {
        return "";
      }
    },
  },
};
</script>