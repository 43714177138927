<template>
  <div class="text-333 text-sm">
    <a-row class="pt-10">
      <a-col :span="24">
        <div class="">
          <a-row>
            <a-col :span="4">
              <div class="logo mr-10">
                <img
                  v-if="Photo"
                  :src="Photo"
                  class="w-full h-full object-contain"
                  alt=""
                />
              </div>
            </a-col>
            <a-col :span="20">
              <a-row class="" :gutter="20">
                <a-col :span="12" class="mb-10">
                  <a-space>
                    <div class="text-999">姓名：</div>
                    <div>{{ Name }}</div>
                  </a-space>
                </a-col>
                <a-col :span="12" class="mb-10">
                  <a-space>
                    <div class="text-999">性别：</div>
                    <div>{{ Gender }}</div>
                  </a-space>
                </a-col>
                <a-col :span="12" class="mb-10">
                  <a-space>
                    <div class="text-999">身份证号码：</div>
                    <div>{{ Identity }}</div>
                  </a-space>
                </a-col>
                <a-col :span="12" class="mb-10">
                  <a-space>
                    <div class="text-999">出生年月：</div>
                    <div>{{ BirthdayTime }}</div>
                  </a-space>
                </a-col>
                <a-col :span="12" class="mb-10">
                  <a-space>
                    <div class="text-999">政治面貌：</div>
                    <div>{{ Political }}</div>
                  </a-space>
                </a-col>
                <a-col :span="12" class="mb-10">
                  <a-space>
                    <div class="text-999">手机号码：</div>
                    <div>{{ Phone }}</div>
                  </a-space>
                </a-col>
                <a-col :span="12" class="mb-10">
                  <a-space>
                    <div class="text-999">微信号码：</div>
                    <div>{{ Wechat }}</div>
                  </a-space>
                </a-col>
                <a-col :span="12" class="mb-10">
                  <a-space>
                    <div class="text-999">Email：</div>
                    <div>{{ Email }}</div>
                  </a-space>
                </a-col>
                <a-col :span="12" class="mb-10">
                  <a-space align="start">
                    <div class="text-999" style="white-space: nowrap">
                      工作单位：
                    </div>
                    <div>{{ Work }}</div>
                  </a-space>
                </a-col>
                <a-col :span="12" class="mb-10">
                  <a-space>
                    <div class="text-999">单位职务：</div>
                    <div>{{ Position }}</div>
                  </a-space>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </div>
      </a-col>
      <a-col :span="24" class="mt-20 w-full">
        <a-space direction="vertical" class="w-full">
          <div class="text-999">个人简介：</div>
          <div>
            <div v-for="(item, index) in Desc" :key="index">{{ item }}</div>
          </div>
        </a-space>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import day from "dayjs";
import { upload } from "@/api";
export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    gender() {
      return this.options.gender;
    },
    political() {
      return this.options.political;
    },
    Name() {
      return this.model.Name || "-";
    },
    Desc() {
      return this.model.Desc.split("\n");
    },
    Identity() {
      return this.model.Identity || "-";
    },
    Phone() {
      return this.model.Phone || "-";
    },
    Wechat() {
      return this.model.Wechat || "-";
    },
    Email() {
      return this.model.Email || "-";
    },
    Work() {
      return this.model.Work || "-";
    },
    Position() {
      return this.model.Position || "-";
    },
    Photo() {
      return this.model.Photo && this.model.Photo.ID
        ? upload() + "/" + this.model.Photo.ID
        : "";
    },
    BirthdayTime() {
      if (this.model.BirthdayTime) {
        return day.unix(this.model.BirthdayTime).format("YYYY-MM-DD");
      } else {
        return "-";
      }
    },
    Gender() {
      if (this.model.Gender === 0) {
        return "男";
      } else if (this.model.Gender === 1) {
        return "女";
      } else {
        return "-";
      }
    },
    Political() {
      if (this.model.Political) {
        return this.model.Political.Name;
      } else {
        return "-";
      }
    },
  },
};
</script>

<style scoped>
.Photo {
  flex-basis: 120px;
  width: 120px;
  height: 160px;
  border: 1px solid #eee;
}
</style>
