<template>
  <div class="text-333 text-sm">
    <a-row class="pt-10">
      <a-col :span="24">
        <div class="flex">
          <div class="Photos mr-20">
            <img
              v-if="Photos"
              class="w-full h-full object-contain"
              :src="Photos"
              alt=""
            />
          </div>
          <div style="width: calc(100% - 234px)">
            <div class="text-999 mb-10">
              <a-space>
                <span>基地主要产品：</span>
                <span v-if="isTech" class="text-primary"
                  >#{{ KeyWords[0] }}</span
                >
                <span v-if="isTech" class="text-primary"
                  >#{{ KeyWords[1] }}</span
                >
              </a-space>
            </div>
            <div>{{ Desc }}</div>
          </div>
        </div>
      </a-col>
      <a-col :span="24" class="mt-20" v-if="tagText.length && isTech">
        <div class="text-999 mb-10">基地标签：</div>
        <div class="mt-10" v-for="(item, index) in tagText" :key="index">
          <span> {{ index + 1 }}、{{ item.Name }} </span>
          ---
          <span>{{ item.Desc }}</span>
        </div>
      </a-col>
      <a-col :span="24" class="mt-20" v-if="isTech">
        <div class="text-999 mb-10">基地应用行业：</div>
        <div class="flex flex-wrap">
          <div class="tag" v-for="item in industryText" :key="item">
            {{ item }}
          </div>
        </div>
      </a-col>
      <a-col :span="24" class="mt-20" v-if="isTech">
        <div class="text-999 mb-10">
          基地详情：<span v-if="!Detail.length">无</span>
        </div>
        <div v-if="Detail.length">
          <div
            style="margin-bottom: 4px"
            v-for="(item, index) in Detail"
            :key="index"
          >
            <a-space>
              <img src="@/assets/icons/attachment.svg" alt="" />
              <a :href="item.link">{{ item.Name }}</a>
            </a-space>
          </div>
        </div>
      </a-col>
      <a-col :span="24" class="mt-20" v-if="isTech">
        <div class="text-999 mb-10">
          其他附件：<span v-if="!Attachments.length">无</span>
        </div>
        <div v-if="Attachments.length">
          <div
            style="margin-bottom: 4px"
            v-for="(item, index) in Attachments"
            :key="index"
          >
            <a-space>
              <img src="@/assets/icons/attachment.svg" alt="" />
              <a :href="item.link">{{ item.Name }}</a>
            </a-space>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { upload } from "@/api";
export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    user() {
      return this.$store.state.user.USER_INFO;
    },
    isTech() {
      return this.user.is_tech;
    },
    tag() {
      return this.options.tag;
    },
    industry() {
      return this.options.industry;
    },
    industryText() {
      return this.model.Industries.map((item) => {
        if (item.Name) {
          return item.Name;
        } else {
          const option = this.industry[item.ID - 1];
          return option ? option.Name : "";
        }
      });
    },
    tagText() {
      return this.model.Tags.map((item) => {
        if (item.ID) {
          const option = this.tag.find((tag) => tag.ID === item.ID);
          if (option) {
            return {
              ...option,
              Desc: item.Desc,
            };
          }
          return "";
        }
      });
    },
    Photos() {
      return this.model.Photos && this.model.Photos.ID
        ? upload() + "/" + this.model.Photos.ID
        : "";
    },
    Attachments() {
      return this.model.Attachments.map((item) => {
        return {
          link: upload() + "/" + item.ID,
          Name: item.Name,
        };
      });
    },
    Detail() {
      return this.model.Detail.map((item) => {
        return {
          link: upload() + "/" + item.ID,
          Name: item.Name,
        };
      });
    },
    KeyWords() {
      return this.model.KeyWords;
    },
    Desc() {
      return this.model.Product;
    },
  },
};
</script>

<style scoped>
.Photos {
  width: 214px;
  height: 160px;
  border: 1px solid #eee;
}
.tag {
  padding: 5px 10px;
  border: 1px solid #2e7ced;
  color: #2e7ced;
  border: 1px solid #eee;
  background-color: #f9f9f9;
  margin-bottom: 10px;
  margin-right: 10px;
}
</style>
