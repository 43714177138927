<template>
  <div class="text-333 text-sm">
    <a-row class="pt-10">
      <a-col :span="12" class="mt-10">
        <a-space>
          <div class="text-999">姓名：</div>
          <div>{{ Name }}</div>
        </a-space>
      </a-col>
      <a-col :span="12" class="mt-10">
        <a-space>
          <div class="text-999">性别：</div>
          <div>{{ Gender }}</div>
        </a-space>
      </a-col>
      <a-col :span="12" class="mt-10">
        <a-space>
          <div class="text-999">部门：</div>
          <div>{{ Department }}</div>
        </a-space>
      </a-col>
      <a-col :span="12" class="mt-10">
        <a-space>
          <div class="text-999">单位职务：</div>
          <div>{{ Position }}</div>
        </a-space>
      </a-col>
      <a-col :span="12" class="mt-10">
        <a-space>
          <div class="text-999">手机号码：</div>
          <div>{{ Phone }}</div>
        </a-space>
      </a-col>
      <a-col :span="12" class="mt-10">
        <a-space>
          <div class="text-999">微信号：</div>
          <div>{{ Wechat }}</div>
        </a-space>
      </a-col>
      <a-col :span="12" class="mt-10">
        <a-space>
          <div class="text-999">座机：</div>
          <div>{{ Telephone }}</div>
        </a-space>
      </a-col>
      <a-col :span="12" class="mt-10">
        <a-space>
          <div class="text-999">Email：</div>
          <div>{{ Email }}</div>
        </a-space>
      </a-col>
      <a-col :span="24" class="mt-10">
        <a-space>
          <div class="text-999">身份证号码：</div>
          <div>{{ Identity }}</div>
        </a-space>
      </a-col>
      <a-col :span="24" class="mt-10">
        <a-space align="start">
          <div class="text-999">二代身份证：</div>
          <a-space :size="10">
            <div class="img">
              <img
                v-if="IDFront"
                class="w-full h-full object-contain"
                :src="IDFront"
                alt=""
              />
              <img
                v-else
                class="w-full h-full object-contain"
                src="@/assets/images/id_front.svg"
                alt=""
              />
            </div>
            <div class="img">
              <img
                v-if="IDBack"
                class="w-full h-full object-contain"
                :src="IDBack"
                alt=""
              />
              <img
                v-else
                class="w-full h-full object-contain"
                src="@/assets/images/id_back.svg"
                alt=""
              />
            </div>
          </a-space>
        </a-space>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { upload } from "@/api";
export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    Name() {
      return this.model.Name || "-";
    },
    Department() {
      return this.model.Department || "-";
    },
    Email() {
      return this.model.Email || "-";
    },
    Identity() {
      return this.model.Identity || "-";
    },
    Phone() {
      return this.model.Phone || "-";
    },
    Wechat() {
      return this.model.Wechat || "-";
    },
    Telephone() {
      return this.model.Telephone || "-";
    },
    Position() {
      return this.model.Position || "-";
    },
    Gender() {
      const gender = this.model.Gender;
      if (gender === 0) {
        return "男";
      } else if (gender === 1) {
        return "女";
      } else {
        return "-";
      }
    },
    IDFront() {
      return this.model.IDFront && this.model.IDFront.ID
        ? upload() + "/" + this.model.IDFront.ID
        : "";
    },
    IDBack() {
      return this.model.IDBack && this.model.IDBack.ID
        ? upload() + "/" + this.model.IDBack.ID
        : "";
    },
  },
};
</script>

<style scoped>
.img {
  width: 200px;
  height: 108px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #eee;
  padding: 10px;
}
</style>
