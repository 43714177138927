<template>
  <a-form-model-item v-bind="$attrs" class="gf">
    <div
      slot="label"
      class="min-h-10"
      v-if="label"
      :class="{ 'leading-10': !subLabel }"
    >
      <div :class="{ 'mb-10': subLabel }">
        <span class="mr-2.5 label pl-2.5" :class="{ required }">{{
          label
        }}</span>
        <a-tooltip :title="tooltip" v-if="tooltip">
          <a-icon type="question-circle" theme="filled" class="text-999" />
        </a-tooltip>
      </div>
      <div class="text-999 leading-5">{{ subLabel }}</div>
    </div>
    <slot></slot>
  </a-form-model-item>
</template>

<script>
export default {
  name: "gf-form-item",
  props: {
    label: {
      type: String,
    },
    subLabel: {
      type: String,
    },
    tooltip: {
      type: String,
    },
    required: {
      type: Boolean,
    },
  },
};
</script>