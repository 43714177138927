import request from '@/utils/request1'

let promission = {};

// 获取消息详情
export function getMessage(id) {
    return request.get(`/messages/${id}`)
}
promission.getMessage = {}

// 设置消息已读
export function updateMessageState(id) {
    return request.post(`/messages/${id}/read`)
}
promission.updateMessageState = {}

// 获取用户消息列表
export function getMessageList(id, params) {
    return request.get(`/users/${id}/messages`, { params })
}
promission.getMessageList = {}

export default promission;