<template>
  <div class="recommend">
    <div class="recommend1 bg-f9 rounded">
      <slot name="a" />
    </div>
    <div class="recommend2 bg-f9 rounded">
      <slot name="b" />
    </div>
    <div class="recommend3 bg-f9 rounded">
      <slot name="c" />
    </div>
    <div class="recommend4 bg-f9 rounded">
      <slot name="d" />
    </div>
    <div class="recommend5 bg-f9 rounded">
      <slot name="e" />
    </div>
  </div>
</template>

<script>
export default {
  name: "recommend-layout",
};
</script>

<style scoped>
.recommend {
  display: grid;
  grid-template-columns: repeat(1fr, 4);
  grid-template-rows: repeat(1fr, 2);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
.recommend1 {
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 3;
}
.recommend2 {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
}
.recommend3 {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 2;
}
.recommend4 {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 3;
}
.recommend5 {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 3;
}
</style>