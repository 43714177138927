<template>
  <div>
    <a-form-model
      ref="container"
      :rules="rules"
      :model="dataSource"
      :colon="false"
      :labelCol="{ span: 6 }"
      :wrapperCol="{ span: 15 }"
      v-bind="$attrs"
      labelAlign="left"
    >
      <a-form-model-item prop="Name">
        <div slot="label" class="h-40 flex items-center">
          <span>姓名</span>
        </div>
        <a-row>
          <a-col :span="15">
            <gf-input size="large" v-model="dataSource.Name"></gf-input>
          </a-col>
        </a-row>
      </a-form-model-item>
      <!-- <a-form-model-item prop="Gender">
        <div slot="label" class="h-40 flex items-center">
          <span>性别</span>
        </div>
        <a-row>
          <a-col :span="15">
            <a-select size="large" v-model="dataSource.Gender">
              <a-select-option
                v-for="option in gender"
                :key="option.ID"
                :value="option.ID"
              >
                {{ option.Name }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-form-model-item> -->
      <a-form-model-item prop="Department">
        <div slot="label" class="h-40 flex items-center">
          <span>部门</span>
        </div>
        <a-row>
          <a-col :span="15">
            <gf-input size="large" v-model="dataSource.Department"></gf-input>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="Position">
        <div slot="label" class="h-40 flex items-center">
          <span>职位</span>
        </div>
        <a-row>
          <a-col :span="15">
            <gf-input size="large" v-model="dataSource.Position"></gf-input>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="Phone">
        <div slot="label" class="h-40 flex items-center">
          <span>手机号码</span>
        </div>
        <a-row>
          <a-col :span="15">
            <gf-input size="large" v-model="dataSource.Phone"></gf-input>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="Wechat">
        <div slot="label" class="h-40 flex items-center">
          <span>微信号</span>
        </div>
        <a-row>
          <a-col :span="15">
            <gf-input size="large" v-model="dataSource.Wechat"></gf-input>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="Telephone">
        <div slot="label" class="h-40 flex items-center">
          <span>座机</span>
        </div>
        <a-row>
          <a-col :span="15">
            <gf-input size="large" v-model="dataSource.Telephone"></gf-input>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="Email">
        <div slot="label" class="h-40 flex items-center">
          <span>Email</span>
        </div>
        <a-row>
          <a-col :span="24">
            <gf-input size="large" v-model="dataSource.Email"></gf-input>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="Identity">
        <div slot="label" class="h-40 flex items-center">
          <span>身份证号码</span>
        </div>
        <a-row>
          <a-col :span="24">
            <gf-input size="large" v-model="dataSource.Identity"></gf-input>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item>
        <div slot="label" class="h-40 flex items-center">
          <span>身份证正反面</span>
        </div>
        <a-row :gutter="40">
          <a-col :span="12">
            <a-form-model-item prop="IDFront">
              <picture-upload
                :scale="false"
                v-model="dataSource.IDFront"
                :width="240"
                :height="160"
                accept=".png,.jpg,.jpeg"
                :size="5"
                field="1"
              ></picture-upload>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item prop="IDBack">
              <picture-upload
                :scale="false"
                v-model="dataSource.IDBack"
                :width="240"
                :height="160"
                accept=".png,.jpg,.jpeg"
                :size="5"
                field="2"
              ></picture-upload>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model-item>
    </a-form-model>
    <div class="text-right mt-40">
      <a-space>
        <a-button size="large" @click="cancel" type="primary" ghost
          >取消</a-button
        >
        <a-button size="large" @click="save" type="primary">保存</a-button>
      </a-space>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {
    this.gender = this.options.gender;
  },
  data() {
    return {
      dataSource: {},
      gender: [],
      rules: {
        // Name: [{ required: true, message: "请填写", trigger: "blur" }],
        // Gender: [{ required: true, message: "请填写", trigger: "blur" }],
        // Department: [{ required: true, message: "请填写", trigger: "blur" }],
        // Email: [{ required: true, message: "请填写", trigger: "blur" }],
        // Wechat: [{ required: true, message: "请填写", trigger: "blur" }],
        // Phone: [{ required: true, message: "请填写", trigger: "blur" }],
        // Position: [{ required: true, message: "请填写", trigger: "blur" }],
      },
    };
  },
  computed: {
    container() {
      return this.$refs.container;
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    save() {
      this.$refs.container.validate((valid) => {
        if (valid) {
          this.$emit("save", this.dataSource);
        }
      });
    },
  },
  watch: {
    model: {
      handler(val) {
        const temp = { ...val };
        temp.IDBack = temp.IDBack || {};
        temp.IDFront = temp.IDFront || {};
        this.dataSource = temp;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>