<template>
  <a-popover placement="bottomRight">
    <div slot="content">
      <a-space
        style="width: 320px"
        class="text-sm"
        :size="10"
        direction="vertical"
      >
        <div class="username rounded p-20 font-bold ellipsis-1">
          {{ info.username }}
        </div>
        <a-space direction="vertical" class="w-full" :size="10">
          <div
            v-for="item in tracks"
            :key="item.name"
            class="item flex justify-between h-40 items-center"
          >
            <div>{{ item.name }}</div>
            <div class="text-primary">
              <!-- 资料未完善 -->
              <span v-if="item.status === 0">
                <router-link
                  class="text-primary"
                  v-if="!item.fault"
                  :to="`/requirement-apply?track=${item.id}`"
                >
                  <span class="cursor-pointer" type="link" ghost>
                    完善资料
                  </span>
                </router-link>
                <span v-else >来年再战</span>
              </span>

              <!-- 取消报名,已提交,初审时间未过且未通过初审 -->
              <a-space v-if="item.status === 1">
                <router-link
                  v-if="!item.fault"
                  class="text-primary"
                  :to="`/requirement-apply?track=${item.id}`"
                >
                  <span class="cursor-pointer" type="link"> 修改资料 </span>
                </router-link>
                <span
                  v-if="!item.fault"
                  class="cursor-pointer"
                  type="link"
                  @click="$emit('cancel', item)"
                >
                  取消报名
                </span>
                <span v-else-if="item.fault===1">来年再战</span>
                <span v-else-if="item.fault===2">已报名</span>
              </a-space>

              <!-- 初审通过 -->
              <span v-else-if="item.status === 10">
                <span v-if="item.fault">来年再战</span>
                <span v-else>通过材料初审</span>
              </span>
              <!-- 复审通过 -->
              <span v-else-if="item.status === 20">
                <span v-if="item.fault">来年再战</span>
                <span v-else>通过材料复审</span>
              </span>
              <!-- 已提名 -->
              <span v-else-if="item.status === 30">
                <span v-if="item.fault">来年再战</span>
                <span v-else>已提名</span>
              </span>
              <!-- 已获奖 -->
              <span v-else-if="item.status === 40">已获奖</span>
            </div>
          </div>
        </a-space>

        <div
          @click="exit"
          style="padding: 14px 10px"
          class="hover:bg-f9 rounded cursor-pointer"
        >
          退出
        </div>
      </a-space>
    </div>
    <slot></slot>
  </a-popover>
</template>

<script>
export default {
  props: {
    tracks: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    info() {
      return this.$store.state.user.USER_INFO;
    },
  },
  methods: {
    exit() {
      this.$store.dispatch("user/LOGOUT");
      this.$store.dispatch("logout");
    },
  },
};
</script>

<style lang="less" scoped>
.username {
  background-image: linear-gradient(to right, #d4e6ff, #edfaff, #e7fff6);
}
</style>