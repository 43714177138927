<template>
  <div class="bg-f9 h-full pb-20">
    <div class="text-left container mx-auto">
      <div class="py-20">
        <a-breadcrumb separator=">">
          <a-breadcrumb-item>
            <router-link to="/">首页</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item href=""> {{ label }} </a-breadcrumb-item>
        </a-breadcrumb>
      </div>
      <div class="flex">
        <div
          class="rounded bg-white mr-20"
          style="min-height: 810px; width: 200px"
        >
          <div
            style="
              width: 60px;
              line-height: 60px;
              height: 60px;
              background: #f4faff;
            "
            class="mx-auto rounded-full my-40 text-center"
          >
            <a-icon type="bell" theme="filled" class="text-3xl text-primary" />
          </div>
          <a-menu :selectedKeys="selectedKeys">
            <a-menu-item key="message-system">
              <router-link to="/message/system">通知</router-link>
            </a-menu-item>
            <!-- <a-menu-item key="message-activity">
              <router-link to="/message/activity">活动通知</router-link>
            </a-menu-item> -->
          </a-menu>
        </div>
        <div class="rounded bg-white p-20" style="width: calc(100% - 220px)">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    selectedKeys() {
      if (this.$route.meta && this.$route.meta.activeMenu) {
        return [this.$route.meta.activeMenu];
      } else {
        return [];
      }
    },
    label() {
      if (this.$route.meta && this.$route.meta.label) {
        return this.$route.meta.label;
      } else {
        return "";
      }
    },
  },
};
</script>