<template>
  <div>
    <a-form-model
      ref="container"
      :rules="rules"
      :model="dataSource"
      :colon="false"
      :labelCol="{ span: 6 }"
      :wrapperCol="{ span: 15 }"
      v-bind="$attrs"
      labelAlign="left"
    >
      <a-form-model-item prop="MeetingVenue">
        <div slot="label" class="h-40 flex items-center">
          <span>会议，活动场地</span>
        </div>
        <a-row>
          <a-col :span="24">
            <gf-textarea
              placeholder="请填写会议或场地地址、最大容纳数"
              prop="MeetingVenue"
              :max="150"
              :rows="6"
              size="large"
              v-model="dataSource.MeetingVenue"
            ></gf-textarea>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="ExhibitionHall">
        <div slot="label" class="h-40 flex items-center">
          <span>企业展厅</span>
        </div>
        <a-row>
          <a-col :span="24">
            <gf-textarea
            placeholder="请填写展厅具体内容、最大容纳人数、是否可以分批参观等"
              prop="ExhibitionHall"
              :max="150"
              :rows="6"
              size="large"
              v-model="dataSource.ExhibitionHall"
            ></gf-textarea>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="FactoryAddress">
        <div slot="label" class="h-40 flex items-center">
          <span>工厂参观</span>
        </div>
        <a-row>
          <a-col :span="24">
            <gf-textarea
            placeholder="请填写工厂具体内容、地址、最大容纳人数（上海或外地均可）"
              prop="FactoryAddress"
              :max="150"
              :rows="6"
              size="large"
              v-model="dataSource.FactoryAddress"
            ></gf-textarea>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="OtherResource">
        <div slot="label" class="h-40 flex items-center">
          <span>其他资源</span>
        </div>
        <a-row>
          <a-col :span="24">
            <gf-textarea
            placeholder="如有可提供的其他资源可在此填写"
              prop="OtherResource"
              :max="150"
              :rows="6"
              size="large"
              v-model="dataSource.OtherResource"
            ></gf-textarea>
          </a-col>
        </a-row>
      </a-form-model-item>
    </a-form-model>
    <div class="text-right mt-40">
      <a-space>
        <a-button size="large" @click="cancel" type="primary" ghost
          >取消</a-button
        >
        <a-button size="large" @click="save" type="primary">保存</a-button>
      </a-space>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dataSource: {},
    };
  },
  computed: {
    container() {
      return this.$refs.container;
    },
    rules() {
      return {};
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    save() {
      this.$refs.container.validate((valid) => {
        if (valid) {
          this.$emit("save", this.dataSource);
        }
      });
    },
  },
  mounted() {
    // console.log(this.$refs);
  },
  watch: {
    model: {
      handler(val) {
        this.dataSource = { ...val };
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>