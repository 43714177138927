// 通用接口
import request from '@/utils/request1'

// 获取列表选项
export function getCategory() {
    return request.get('/category')
}

// 获取文件url
export function getFileUrl(id) {
    return request.defaults.baseURL + `files/${id}?thumbnail=medium`
}

// 获取区域列表
export function getRegion(params) {
    return request.get('/regions', { params })
}

// 获取经纬度
export function getLocation(params) {
    return request.get(`/lbs/location`, { params })
}

// 获取系统设置
export function getConfig(){
    return request.get('/sys/config')
}