import request from '@/utils/request1'
import store from '@/store'

let id;
// 获取比赛详情
export function getEvent() {
    return request.get('/x/events/fyb2021').then(({ data }) => {
        id = data.ID;
    })
}

// 获取报名信息
export function getReg() {
    return request.get(`/x/events/${id}/registrations`)
}

// 提交报名信息
export function submitReg(data) {
    return request.post(`/x/events/${id}/registrations`, data).then((res) => {
        const info = store.state.userInfo,
            company = store.state.companyInfo;
        const temp = {
            uid: info.UID || info.id,
            type: "click",
            name: 'draft',
            metadata: {
                company: company.companyName,
                track: data.Track.Name
            }
        };
        store.dispatch("track/setTrack", temp);

        return Promise.resolve(res)
    })
}

// 修改报名信息
export function updateReg(data) {
    return request.put(`/x/events/${id}/registrations/${data.track_id}`, data).then((res) => {
        const info = store.state.userInfo,
            company = store.state.companyInfo;
        const temp = {
            uid: info.UID || info.id,
            type: "click",
            name: 'draft',
            metadata: {
                company: company.companyName,
                track: data.Track.Name
            }
        };
        store.dispatch("track/setTrack", temp);

        return Promise.resolve(res)
    })
}

// 取消报名信息
export function deleteReg(data) {
    return request.delete(`/x/events/${id}/registrations/${data.ID}`).then((res) => {
        const info = store.state.userInfo,
            company = store.state.companyInfo;
        const temp = {
            uid: info.UID || info.id,
            type: "click",
            name: 'cancel',
            metadata: {
                company: company.companyName,
                track: data.name
            }
        };
        store.dispatch("track/setTrack", temp);

        return Promise.resolve(res)
    })
}

// 提交申请表
export function submitForm(data) {
    return request.post(`/x/events/${id}/registrations/${data.track_id}/form`, data)
}

export function submit(data) {
    return request.post(`/x/events/${id}/registrations/${data.track_id}/submit`).then((res) => {
        const info = store.state.userInfo,
            company = store.state.companyInfo;
        const temp = {
            uid: info.UID || info.id,
            type: "click",
            name: 'submit',
            metadata: {
                company: company.companyName,
                track: data.Track.Name
            }
        };
        store.dispatch("track/setTrack", temp);

        return Promise.resolve(res)
    })
}