<template>
  <div>
    <a-form-model
      ref="container"
      :rules="rules"
      :model="dataSource"
      :colon="false"
      :labelCol="{ span: 6 }"
      :wrapperCol="{ span: 15 }"
      v-bind="$attrs"
      labelAlign="left"
    >
      <a-form-model-item prop="Name">
        <div slot="label" class="h-40 flex items-center">
          <span>姓名</span>
        </div>
        <a-row>
          <a-col :span="15">
            <gf-input size="large" v-model="dataSource.Name"></gf-input>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="Gender">
        <div slot="label" class="h-40 flex items-center">
          <span>性别</span>
        </div>
        <a-row>
          <a-col :span="15">
            <a-select size="large" v-model="dataSource.Gender">
              <a-select-option
                v-for="option in gender"
                :key="option.ID"
                :value="option.ID"
              >
                {{ option.Name }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="Photo">
        <div slot="label" class="h-40 flex items-center">
          <span>登记照（2寸免冠证件照）</span>
        </div>
        <a-row>
          <a-col :span="15">
            <picture-upload
            :scale="{ w: 413, h: 626 }"
              :width="280"
              :height="160"
              accept=".png,.jpg,.jpeg"
              :size="5"
              v-model="dataSource.Photo"
            ></picture-upload>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="Identity">
        <div slot="label" class="h-40 flex items-center">
          <span>身份证号码</span>
        </div>
        <a-row>
          <a-col :span="15">
            <gf-input size="large" v-model="dataSource.Identity"></gf-input>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="BirthdayTime">
        <div slot="label" class="h-40 flex items-center">
          <span>出生年月</span>
        </div>
        <a-row>
          <a-col :span="15">
            <a-date-picker
              valueFormat="X"
              size="large"
              v-model="dataSource.BirthdayTime"
            ></a-date-picker>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="Work">
        <div slot="label" class="h-40 flex items-center">
          <span>工作单位</span>
        </div>
        <a-row>
          <a-col :span="24">
            <gf-input size="large" v-model="dataSource.Work"></gf-input>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="Position">
        <div slot="label" class="h-40 flex items-center">
          <span>单位职务</span>
        </div>
        <a-row>
          <a-col :span="24">
            <gf-input size="large" v-model="dataSource.Position"></gf-input>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="Political">
        <div slot="label" class="h-40 flex items-center">
          <span>政治面貌</span>
        </div>
        <a-row>
          <a-col :span="15">
            <a-select size="large" v-model="dataSource.Political">
              <a-select-option
                v-for="option in political"
                :key="option.ID"
                :value="option.ID"
              >
                {{ option.Name }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="Phone">
        <div slot="label" class="h-40 flex items-center">
          <span>手机</span>
        </div>
        <a-row>
          <a-col :span="15">
            <gf-input size="large" v-model="dataSource.Phone"></gf-input>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="Wechat">
        <div slot="label" class="h-40 flex items-center">
          <span>微信号</span>
        </div>
        <a-row>
          <a-col :span="15">
            <gf-input size="large" v-model="dataSource.Wechat"></gf-input>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="Email">
        <div slot="label" class="h-40 flex items-center">
          <span>Email</span>
        </div>
        <a-row>
          <a-col :span="24">
            <gf-input size="large" v-model="dataSource.Email"></gf-input>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="Desc">
        <div slot="label" class="h-40 flex items-center">
          <span>个人简介</span>
        </div>
        <a-row>
          <a-col :span="24">
            <gf-textarea
              prop="Desc"
              :max="300"
              :required="true"
              :rows="6"
              v-model="dataSource.Desc"
            ></gf-textarea>
          </a-col>
        </a-row>
      </a-form-model-item>
    </a-form-model>
    <div class="text-right mt-40">
      <a-space>
        <a-button size="large" @click="cancel" type="primary" ghost
          >取消</a-button
        >
        <a-button size="large" @click="save" type="primary">保存</a-button>
      </a-space>
    </div>
  </div>
</template>

<script>
import { validateEmail } from "@/utils/validator.js";
export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dataSource: {},
    };
  },
  computed: {
    gender() {
      return this.options.gender;
    },
    political() {
      return this.options.political;
    },
    container() {
      return this.$refs.container;
    },
    rules() {
      return {
        Name: [{ required: !this.$store.getters.isManager, message: "请输入", trigger: "blur" }],
        Gender: [{ required: !this.$store.getters.isManager, message: "请选择", trigger: "blur" }],
        BirthdayTime: [{ required: !this.$store.getters.isManager, message: "请选择", trigger: "blur" }],
        Position: [{ required: !this.$store.getters.isManager, message: "请输入", trigger: "blur" }],
        Phone: [{ required: !this.$store.getters.isManager, message: "请输入", trigger: "blur" }],
        Email: [
          { required: !this.$store.getters.isManager, message: "请输入", trigger: "blur" },
          {
            validator: validateEmail,
            message: "请填写正确的邮箱格式",
            trigger: "blur",
          },
        ],
        Desc: [{ required: !this.$store.getters.isManager, message: "请输入", trigger: "blur" }],
      };
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    save() {
      this.container.validate((valid) => {
        if (valid) {
          this.$emit("save", this.dataSource);
        }
      });
    },
  },

  watch: {
    model: {
      handler(val) {
        const temp = { ...val };

        if (temp.Political) {
          temp.Political = temp.Political.ID || undefined;
        }
        this.dataSource = temp;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>