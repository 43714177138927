<template>
  <div class="bg-f9 pb-100 text-left">
    <div class="container mx-auto">
      <div class="py-20">
        <a-breadcrumb separator=">">
          <a-breadcrumb-item>首页</a-breadcrumb-item>
          <a-breadcrumb-item>我的工赋</a-breadcrumb-item>
          <a-breadcrumb-item>{{ title }}</a-breadcrumb-item>
        </a-breadcrumb>
      </div>
      <a-row :gutter="20">
        <a-col :span="5">
          <div
            class="bg-white relative"
            style="min-height: 810px; padding-bottom: 76px"
          >
            <a-menu
              class="py-40 user"
              :selectedKeys="selectedKeys"
              mode="inline"
              :defaultOpenKeys="defaultOpenKeys"
            >
              <a-sub-menu
                @click.native="push(subMenu)"
                v-for="subMenu in routes"
                :key="subMenu.name"
                :class="{ noChildren: !subMenu.children }"
              >
                <span slot="title" class="">{{ subMenu.meta.label }}</span>
                <template v-if="subMenu.children">
                  <a-menu-item
                    @click.native="push(item)"
                    v-for="item in subMenu.children"
                    :key="item.name"
                    :disabled="item.disabled"
                  >
                    <span class="">{{ item.meta.label }}</span>
                  </a-menu-item>
                </template>
              </a-sub-menu>
            </a-menu>
            <div
              class="
                progress
                flex flex-col
                justify-center
                items-center
                absolute
                bottom-0
                w-full
              "
              v-if="!isManager"
            >
              <div>信息完整度：{{ progress }}%</div>
              <a-progress :showInfo="false" :percent="progress" />
            </div>
          </div>
        </a-col>
        <a-col :span="19">
          <router-view></router-view>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  computed: {
    isManager() {
      return [6, 7, 2, 1, 8].includes(this.$store.state.role);
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    progress() {
      return this.$store.state.user.COMPANY_PROGRESS;
    },
    title() {
      return this.$route.meta.label;
    },
    selectedKeys() {
      return [this.$route.name];
    },
    routes() {
      const routes = JSON.parse(JSON.stringify(this.$store.state.routes));
      if (this.role === 1 && this.userInfo.link_status === 1) {
        routes.splice(0, 1);
      }
      return routes;
    },
    role() {
      return this.$store.state.role;
    },
    defaultOpenKeys() {
      switch (this.role) {
        case 1:
          return ["account", "company"];
        case 2:
          return ["dashboard", "requirement", "account", "knowledge"];
        case 3:
          return ["company", "account"];
        case 4:
          return ["dashboard", "company", "requirement", "account"];
        case 5:
          return ["dashboard", "company", "requirement", "account"];
        case 6:
          return ["requirement", "evaluate"];
        case 7:
          return ["requirement", "evaluate"];
        case 8:
          return ["account", "dashboard"];
      }
    },
  },
  methods: {
    push(e) {
      if (!e.children) {
        this.$router.push({ name: e.name });
      }
    },
  },
};
</script>

<style scoped>
.user-logo {
  width: 60px;
  height: 60px;
}
.progress {
  border-top: 1px solid #eee;
  height: 76px;
  padding: 0 20px;
}
</style>