import { getUserInfo } from "../api1/user"
import { getCompanyInfo, getCompanyProgress } from "../api1/company"

import { localSet } from '@/utils/storage'
import { transformAttachment } from '@/utils/transform1/_util'

export default {
    namespaced: true,
    state: {
        USER_INFO: {},
        COMPANY_INFO: {},
        COMPANY_PROGRESS: 0,
    },
    mutations: {
        SET_USER_INFO(state, info) {
            state.USER_INFO = info
        },
        SET_COMPANY_INFO(state, info) {
            state.COMPANY_INFO = info
        },
        SET_COMPANY_PROGRESS(state, progress) {
            state.COMPANY_PROGRESS = progress
        },
    },
    actions: {
        GET_USER_INFO({ commit }) {
            return getUserInfo().then(({ data }) => {
                const temp = { ...data }
                temp.Profile = transformAttachment(temp.Profile)
                commit('SET_USER_INFO', temp)
                return Promise.resolve(temp)
            })
        },
        GET_COMPANY_INFO({ commit }) {
            return getCompanyInfo().then(({ data }) => {
                commit('SET_COMPANY_INFO', data)
                return Promise.resolve(data)
            })
        },
        GET_COMPANY_PROGRESS({ commit }) {
            return getCompanyProgress().then(({ data }) => {
                commit('SET_COMPANY_PROGRESS', data.Total)
                return Promise.resolve(data.Total)
            })
        },
        LOGOUT({ commit }) {
            commit('SET_USER_INFO', {})
            commit('SET_COMPANY_INFO', {})
            localSet('token', '')
        }
    }
}