<template>
  <div class="gf-re-textarea relative bg-white">
    <div
      class="
        w-full
        transform
        -translate-x-1/2
        absolute
        bottom-0
        left-1/2
        items-center
        flex
        justify-between
        z-10
      "
      :style="{ width: 'calc(100% - 32px)', height: '30px', backgroundColor }"
    >
      <slot />
      <span v-if="max" class="text-xs text-999 ml-auto">
        {{ value.length ? `${value.length} / ${max}` : `${max}字以内` }}
      </span>
    </div>
    <a-textarea
      style="padding: 10px 16px 30px"
      class="relative bg-transparent"
      :rows="rows"
      :placeholder="placeholder"
      :value="value"
      v-bind="$attrs"
      v-on="$listeners"
      :maxLength="60000"
    ></a-textarea>
  </div>
</template>

<script>
export default {
  name: "GfReTextarea",
  model: {
    prop: "value",
    event: "change.value",
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    backgroundColor: {
      type: String,
      default: "transparent",
    },
    max: {
      type: Number,
    },
    rows: {
      type: Number,
      default: 4,
    },
    placeholder: {
      type: String,
      default: "请输入",
    },
  },
};
</script>

<style lang="less" scoped>
.gf-re-textarea {
  //   padding: 10px 16px 30px;
}
</style>