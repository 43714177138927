<template>
  <span class="text-primary cursor-pointer" @click="down">{{ title }}</span>
</template>

<script>
export default {
  name: "count-down",
  props: {
    count: Number,
    text: String,
    send: Function,
  },
  data() {
    return {
      title: "",
      num: 0,
      disabled: false,
    };
  },
  created() {
    this.title = this.text;
    this.num = this.count;
  },
  methods: {
    down() {
      if (!this.timer && !this.disabled) {
        this.disabled = true;
        const promise = this.send();
        promise &&
          promise
            .then((data) => {
              if (!data) {
                this.disabled = false;
                return;
              }
              this.title = `${this.num}秒后重新发送`;
              this.timer = setInterval(() => {
                this.num--;
                if (this.num <= 0) {
                  this.title = this.text;
                  clearInterval(this.timer);
                  this.disabled = false;
                  this.timer = null;
                  this.num = this.count;
                } else {
                  this.title = `${this.num}秒后重新发送`;
                }
              }, 1000);
              this.$message.success("验证码发送成功");
            })
            .catch(({ msg }) => {
              console.log(msg);
              this.disabled = false;
              this.$message.error(msg);
            });
      }
    },
  },
};
</script>