import { setTrack } from '@/api1/tracks.js'

const hostname = window.location.hostname

export default {
    namespaced: true,
    actions: {
        setTrack({ }, { title, uri, uid, type, name, metadata }) {
            const temp = {
                Event: {
                    Type: type,
                    Category: 'fyb',
                    ObjectID: 'fyb2021',
                    Name: name,
                    Metadata: metadata
                },
                Hostname: hostname,
                Title: title,
                URI: uri,
                UID: uid
            }
            setTrack(temp)
        }
    }
}