import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import { getUserInfo } from '@/api'
import { getCategory } from "@/api/api/common";
import { sessionSet, sessionGet } from '@/utils/storage';
import { getUserCompany } from '@/api/api/company'
import { getCompanyProgress } from "@/api/company.js";
import { getReg, deleteReg } from "@/api1/track.js";

import message from './message'
import user from './user'
import promission from './promission'
import config from './config'
import track from './track'


const custom = {
  EndTime: [
    { Name: '7日内到期', ID: 1, value: 7 * 86400 },
    { Name: '15日内到期', ID: 2, value: 15 * 86400 },
    { Name: '30日内到期', ID: 3, value: 30 * 86400 },
  ],
  Budget: [
    { Name: '20万以下', ID: 1, value: [0, 200000] },
    { Name: '20万-50万', ID: 2, value: [200000, 500000] },
    { Name: '50万-100万', ID: 3, value: [500000, 1000000] },
    { Name: '100万-200万', ID: 4, value: [1000000, 2000000] },
    { Name: '200万-300万', ID: 5, value: [2000000, 3000000] },
    { Name: '300万-500万', ID: 6, value: [3000000, 5000000] },
    { Name: '500万-700万', ID: 7, value: [5000000, 7000000] },
    { Name: '700万-900万', ID: 8, value: [7000000, 9000000] },
    { Name: '1000万-2000万', ID: 9, value: [10000000, 20000000] },
    { Name: '2000万-3000万', ID: 10, value: [20000000, 30000000] },
    { Name: '3000万以上', ID: 11, value: [30000000, 0] },
  ]
}
import { } from '@/premission.js'

import { localSet } from '@/utils/storage'
export default new Vuex.Store({
  state: {
    options: [],
    userInfo: {},
    role: 1,
    routes: [],
    companyInfo: {},
    progress: 0,
    reg: [],
    notTip: false
  },
  mutations: {
    SET_ROUTERS: (state, routes) => {
      state.routes = routes
    },
    setUserInfo(state, info) {
      state.userInfo = info
    },
    setRole(state, role) {
      state.role = role
    },
    setProgress(state, progress) {
      state.progress = progress
    },
    setCompanyInfo(state, info) {
      state.companyInfo = info
    },
    setReg(state, reg) {
      state.reg = reg;
    },
    setNotTip(state, status) {
      state.notTip = status;
    }
  },
  getters: {
    isManager(state) {
      return state.role ? (state.role === 6 || state.role === 7) : false
    }
  },
  actions: {
    setupCategory({ state }) {
      const options = sessionGet('options1');
      if (!options || !options.length) {
        getCategory().then(({ data }) => {
          const assignOptions = Object.assign(data, custom);
          sessionSet('options1', assignOptions);
          state.options = assignOptions;
        })
      }
    },
    logout({ state }) {
      state.userInfo = {};
      localSet('token', "")
    },
    getUserInfo({ state }) {
      return getUserInfo().then(({ data }) => {
        if (![6, 7, 1, 2, 8].includes(state.role)) {
          getCompanyProgress().then(({ data }) => {
            let num = 0
            for (let key in data) {
              num += data[key]
            }
            state.progress = num
          })
        }
        state.userInfo = data;
        return Promise.resolve(data)
      })
    },
    getCompanyInfo({ state }) {
      getUserCompany().then(res => {
        state.companyInfo = res;
        return Promise.resolve(res)
      })
    },
    getReg({ commit }) {
      getReg().then(({ data }) => {
        const rows = data.rows || [];
        commit('setReg', rows)
      });
    },
  },
  modules: { message, user, promission, config, track },
})
