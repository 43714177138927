<template>
  <div class="p-20 border border-eee flex">
    <div
      style="width: 214px; height: 160px"
      class="relative border border-eee rounded mr-20"
    >
      <img
        v-if="dataSource.Cover"
        :src="dataSource.Cover.url"
        class="w-full h-full object-contain"
        alt=""
      />
      <div
        class="
          absolute
          top-20
          rounded-tr-full rounded-br-full
          text-xs text-white text-center
          px-10
        "
        :class="{
          'status-1': dataSource.status === 0,
          'status-2': dataSource.status === 1,
          'status-0': [2, 10].includes(dataSource.status),
        }"
        style="height: 26px; line-height: 26px"
      >
        <span v-if="dataSource.status === 0">报名中</span>
        <span v-else-if="dataSource.status === 1">进行中</span>
        <span v-else-if="dataSource.status === 2">已结束</span>
        <span v-else-if="dataSource.status === 10">已关闭</span>
      </div>
      <div
        class="px-10 absolute right-0 bottom-0 text-white text-center text-xs"
        style="
          height: 26px;
          line-height: 26px;
          background-color: rgba(0, 0, 0, 0.5);
        "
      >
        {{ dataSource.type.Name }}
      </div>
    </div>
    <div style="width: calc(100% - 234px)" class="pb-4 relative">
      <div class="font-bold mb-10">{{ dataSource.Name }}</div>
      <div class="mb-20 text-sm text-666 ellipsis-1">{{ dataSource.Desc }}</div>
      <div class="mb-10 text-xs text-999 flex items-center">
        <a-icon type="clock-circle" class="mr-10" />
        {{ dataSource.time[0] | formatterTime("YYYY-MM-DD HH:mm") }} 至
        {{ dataSource.time[1] | formatterTime("YYYY-MM-DD HH:mm") }}
      </div>
      <div class="text-xs text-999 flex items-center">
        <a-icon type="environment" class="mr-10" />
        <span>{{ dataSource.address }}</span>
      </div>
      <div
        class="
          flex
          justify-between
          items-center
          absolute
          bottom-0
          w-full
          text-xs
        "
      >
        <div class="flex items-center">
          <a-progress
            style="width: 120px"
            class="mr-10"
            :show-info="false"
            :percent="(dataSource.participants / dataSource.Quota) * 100"
          />
          <div>
            名额已占{{
              dataSource.participants / dataSource.Quota < 1
                ? Math.ceil((dataSource.participants / dataSource.Quota) * 100)
                : Math.floor(
                    (dataSource.participants / dataSource.Quota) * 100
                  )
            }}%
          </div>
        </div>
        <div>
          <span>已报名：</span>
          <span class="text-primary"
            >{{ dataSource.participants }}/{{ dataSource.Quota }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActivityCard",
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>