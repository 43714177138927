<template>
  <div class="text-333 text-sm">
    <a-row class="pt-10">
      <a-col :span="24" class="mt-10">
        <a-space>
          <div class="text-999">贵司是否有工业数字化转型方向的讲师推荐：</div>
          <div>{{ Lecturer }}</div>
        </a-space>
      </a-col>
      <a-col :span="24" class="mt-10">
        <a-space>
          <div class="text-999">
            贵司是否有意愿参与工业数字化转型相关实训课程开发：
          </div>
          <div>{{ Training }}</div>
        </a-space>
      </a-col>
      <a-col :span="24" class="mt-10">
        <a-space>
          <div class="text-999">是否有组织能力提升/企业内训的需求：</div>
          <div>{{ Course }}</div>
        </a-space>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    Lecturer() {
      return this.model.Lecturer ? "是" : "否";
    },
    Training() {
      return this.model.Training ? "是" : "否";
    },
    Course() {
      return this.model.Course ? "是" : "否";
    },
  },
};
</script>
