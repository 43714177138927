<template>
  <div class="text-333 text-sm">
    <a-row class="pt-10">
      <a-col :span="12" class="mt-10">
        <a-space>
          <div class="text-999">固定电话：</div>
          <div>{{ Telephone }}</div>
        </a-space>
      </a-col>
      <a-col :span="12" class="mt-10">
        <a-space>
          <div class="text-999">传真：</div>
          <div>{{ Fax }}</div>
        </a-space>
      </a-col>
      <a-col :span="24" class="mt-10">
        <a-space class="w-full" align="start">
          <div class="text-999" style="width:70px">单位网址：</div>
          <div class="" style="width:700px;word-wrap:break-word">{{ Website }}</div>
        </a-space>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    Website() {
      return this.model.Website;
    },
    Telephone() {
      return this.model.Telephone;
    },
    Fax() {
      return this.model.Fax;
    },
  },
};
</script>
