<template>
  <a-row>
    <a-col :span="11">
      <gf-form-item
        prop="budget.0"
        :rules="[
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
        ]"
      >
        <ten-thousand-number
          :disabled="disabled"
          v-model="value[0]"
        ></ten-thousand-number>
      </gf-form-item>
    </a-col>
    <a-col :span="2">
      <div class="text-center">-</div>
    </a-col>
    <a-col :span="11">
      <gf-form-item
        prop="budget.1"
        :rules="[
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
        ]"
      >
        <ten-thousand-number
          :disabled="disabled"
          v-model="value[1]"
          :min="value[0]"
        ></ten-thousand-number>
      </gf-form-item>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: "GfReInputNumberRange",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    disabled: Boolean,
  },
};
</script>