<template>
  <div class="text-333 text-sm">
    <a-row class="pt-10">
      <a-col :span="24" class="mt-20">
        <a-space direction="vertical" class="w-full">
          <div class="text-999">会议、活动场地：</div>
          <div>
            <div v-for="(item, index) in MeetingVenue" :key="index">
              {{ item }}
            </div>
          </div>
        </a-space>
      </a-col>
      <a-col :span="24" class="mt-20">
        <a-space direction="vertical" class="w-full">
          <div class="text-999">企业展厅：</div>
          <div>
            <div v-for="(item, index) in ExhibitionHall" :key="index">
              {{ item }}
            </div>
          </div>
        </a-space>
      </a-col>
      <a-col :span="24" class="mt-20">
        <a-space direction="vertical" class="w-full">
          <div class="text-999">工厂参观：</div>
          <div>
            <div v-for="(item, index) in FactoryAddress" :key="index">
              {{ item }}
            </div>
          </div>
        </a-space>
      </a-col>
      <a-col :span="24" class="mt-20">
        <a-space direction="vertical" class="w-full">
          <div class="text-999">其他资源：</div>
          <div>
            <div v-for="(item, index) in OtherResource" :key="index">
              {{ item }}
            </div>
          </div>
        </a-space>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    FactoryAddress() {
      return this.model.FactoryAddress.split("\n");
    },
    ExhibitionHall() {
      return this.model.ExhibitionHall.split("\n");
    },
    OtherResource() {
      return this.model.OtherResource.split("\n");
    },
    MeetingVenue() {
      return this.model.MeetingVenue.split("\n");
    },
  },
};
</script>
