<template>
  <a-popover placement="bottomRight">
    <div slot="content">
      <div class="overflow-y-scroll scroll-hidden">
        <div class="p-20 border-b border-eee font-bold">消息通知</div>
        <div
          @click="read(item)"
          v-for="item in messages"
          :key="item.ID"
          style="width: 400px"
          class="p-20 border-b border-eee hover:bg-eee text-xs"
        >
          <div class="flex justify-between mb-10">
            <a-space>
              <div
                v-if="!item.Read"
                class="inline-block w-10 h-10 bg-red-600 rounded-full"
              ></div>
              <div>「{{ item.Metadata.track }}赛道」</div>
            </a-space>

            <div class="text-999">
              {{ item.CreatedAt | formatterTime1("YYYY-MM-DD HH:mm") }}
            </div>
          </div>
          <div class="text-999">{{ item.Content }}</div>
        </div>
        <router-link to="/message">
          <div
            class="p-20 border-b border-eee text-center bg-eee cursor-pointer"
          >
            查看全部
          </div>
        </router-link>
      </div>
    </div>
    <a-badge :count="counts">
      <router-link to="/message">
        <a-icon type="bell" class="text-2xl text-white" />
      </router-link>
    </a-badge>
  </a-popover>
</template>

<script>
import { updateMessageState } from "@/api/api/message";
export default {
  computed: {
    count() {
      return this.$store.state.message.unReadCount;
    },
    message() {
      return this.$store.state.message.unReadMessage;
    },
  },
  data() {
    return {
      messages: [],
      counts: 0,
    };
  },
  methods: {
    read(data) {
      if (!data.Read) {
        updateMessageState(data.ID)
          .then(() => {
            this.$set(data, "Read", true);
            this.counts--;
          })
          .catch(() => {
            this.$message.error("读取信息失败");
          });
      }
    },
  },
  watch: {
    message: {
      handler(val) {
        this.messages = val.slice(0, 5);
        this.counts = this.count;
      },
      immediate: true,
    },
  },
};
</script>