import RouterPromission from '@/router/router.promission'
import UserPromission from '@/api1/user'
import CompanyPromission from '@/api1/company'
import MessagePromission from '@/api1/message'

export default {
    namespaced: true,
    state: {
        API_PROMISSION: {
            ...UserPromission,
            ...CompanyPromission,
            ...MessagePromission
        },
        PAGE_PROMISSION: RouterPromission,
    },
    mutations: {
        // SET_API_PROMISSION(state, promission) {
        //     state.API_PROMISSION = promission
        // },
        // SET_PAGE_PROMISSION(state, promission) {
        //     state.PAGE_PROMISSION = promission
        // },
    },
    actions: {
        // GET_API_PROMISSION({ commit }) {

        // },
        // GET_PAGE_PROMISSION({ commit }) {

        // }
    }
}