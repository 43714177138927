<template>
  <div class="text-333 text-sm">
    <a-row class="pt-10">
      <a-col :span="24" class="mt-10">
        <div>
          <div v-for="(item, index) in Supplement" :key="index">
            {{ item }}
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    Supplement() {
      return this.model.Supplement.split("\n");
    },
  },
};
</script>
