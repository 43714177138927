<template>
  <div>
    <a-form-model
      ref="container"
      :rules="rules"
      :model="dataSource"
      :colon="false"
      :labelCol="{ span: 15 }"
      :wrapperCol="{ span: 9 }"
      v-bind="$attrs"
      labelAlign="left"
    >
      <a-form-model-item prop="Lecturer">
        <div slot="label" class="h-40 flex items-center">
          <span>贵司是否有工业数字化转型方向的讲师推荐</span>
        </div>
        <a-row>
          <a-col :span="24">
            <a-radio-group :defaultValue="true" v-model="dataSource.Lecturer">
              <a-radio :value="true">是</a-radio>
              <a-radio :value="false">否</a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="Training">
        <div slot="label" class="h-40 flex items-center">
          <span>贵司是否有意愿参与工业数字化转型相关实训课程开发</span>
        </div>
        <a-row>
          <a-col :span="24">
            <a-radio-group :defaultValue="true" v-model="dataSource.Training">
              <a-radio :value="true">是</a-radio>
              <a-radio :value="false">否</a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="Course">
        <div slot="label" class="h-40 flex items-center">
          <span>是否有组织能力提升/企业内训的需求</span>
        </div>
        <a-row>
          <a-col :span="24">
            <a-radio-group :defaultValue="true" v-model="dataSource.Course">
              <a-radio :value="true">是</a-radio>
              <a-radio :value="false">否</a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
      </a-form-model-item>
    </a-form-model>
    <div class="text-right mt-40">
      <a-space>
        <a-button size="large" @click="cancel" type="primary" ghost
          >取消</a-button
        >
        <a-button size="large" @click="save" type="primary">保存</a-button>
      </a-space>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dataSource: {},
      rules: {
        // Lecturer: [{ required: true, message: "请选择", trigger: "blur" }],
        // Training: [{ required: true, message: "请选择", trigger: "blur" }],
        // Course: [{ required: true, message: "请选择", trigger: "blur" }],
      },
    };
  },
  computed: {
    container() {
      return this.$refs.container;
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    save() {
      this.$refs.container.validate((valid) => {
        if (valid) {
          this.$emit("save", this.dataSource);
        }
      });
    },
  },
  mounted() {
    // console.log(this.$refs);
  },
  watch: {
    model: {
      handler(val) {
        this.dataSource = { ...val };
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>