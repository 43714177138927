<template>
  <div class="border border-eee text-333" @click="$emit('open',dataSource)">
    <div class="flex justify-between items-center title px-20">
      <div class="font-bold ellipsis-1 pr-20">{{ dataSource.title }}</div>
      <slot name="opt"></slot>
    </div>

    <div class="p-20 bg-white">
      <div class="flex text-999 text-sm">
        <div style="width: 24px; max-height: 80px" class="mr-10">
          <img
            src="@/assets/icons/question.svg"
            class="w-full h-full object-contain"
            alt=""
          />
        </div>

        <div style="width: calc(100% - 34px)">
          <div v-if="role === 2">
            <a-input
              @click.stop="inputing = true"
              placeholder="请在此输入您的回答"
              v-if="!form.answer && !inputing"
            ></a-input>
            <div @click.stop="inputing = true" v-else-if="form.answer && !inputing">
              {{ form.answer }}
            </div>
            <div class="flex" v-if="inputing">
              <div class="flex-grow relative">
                <a-form-model
                  ref="container"
                  :rules="rules"
                  v-if="role === 2"
                  :model="form"
                >
                  <gf-textarea
                    v-focus
                    :rows="3"
                    :max="300"
                    placeholder="请在此输入您的回答"
                    prop="answer"
                    v-model="form.answer"
                    @click.stop=""
                  ></gf-textarea>
                </a-form-model>
              </div>
              <div
                @click.stop="submit"
                style="flex-basic: 120px; width: 120px"
                class="
                  bg-primary
                  flex
                  justify-center
                  items-center
                  text-white
                  cursor-pointer
                "
              >
                确定
              </div>
            </div>
          </div>
          <div class="ellipsis-2" v-else>{{ form.answer || '专家暂未给出锦囊' }}</div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: "e-question-card",
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      form: {},
      rules: {
        answer: [
          { required: true, message: "请输入回复内容", trigger: "blur" },
        ],
      },
      inputing: false,
    };
  },
  computed: {
    role() {
      return this.$store.state.role;
    },
  },
  methods: {
    submit() {
      if (this.form.answer) {
        this.$refs.container.validate((valid) => {
          if (valid) {
            this.$emit("submit", { ...this.form });
            this.inputing = false;
          }
        });
      } else {
        this.$message.error("请输入回复内容");
      }
    },
  },
  watch: {
    dataSource: {
      handler(val) {
        this.form = val;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.title {
  height: 64px;
  line-height: 64px;
  background-color: #f0f8ff;
}
.tag1 {
  padding: 5px 10px;
  border: 1px solid #eee;
  color: #333;
  line-height: 1;
  display: inline-block;
  height: 26px;
  font-size: 12px;
}
</style>