<template>
  <div
    class="text-xs leading-4 rounded inline-block gf-tag"
    :class="type"
    style="padding: 5px 10px"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "gf-tag",
  props: {
    type: {
      type: String,
    },
  },
};
</script>

<style lang="less" scoped>
.gf-tag {
  border: 1px solid #eee;
  box-sizing: border-box;
  &.primary {
    border: 1px solid #3477D9;
    color: #2e7ced;
  }
}
</style>