<template>
  <div>
    <a-form-model
      ref="container"
      :rules="rules"
      :model="dataSource"
      :colon="false"
      :labelCol="{ span: 6 }"
      :wrapperCol="{ span: 15 }"
      v-bind="$attrs"
      labelAlign="left"
    >
      <a-form-model-item :required="!$store.getters.isManager">
        <div slot="label" class="h-40 flex items-center">
          <span>核心竞争力</span>
          <a-tooltip class="ml-10">
            <span slot="title"
              >请至少填写一项。关键词填写示例：较强平台开发能力
              详细描述填写示例：打造了以多行业高端装备产业为依托，构筑覆盖产业链的一体化工业互联网平台</span
            >
            <a-icon type="question-circle" theme="filled" style="color: #ddd" />
          </a-tooltip>
        </div>
      </a-form-model-item>
      <a-form-model-item
        v-for="(item, index) in dataSource.Competitives"
        :key="item.ID"
      >
        <div slot="label" class="h-40 flex items-center">
          <a-space class="text-999 text-xs" align="baseline">
            <span>竞争力{{ index + 1 }}</span>
            <a-icon
              v-if="
                dataSource.Competitives.length > 1 ||
                $store.getters.isManager
              "
              @click="deleteCompitive(index)"
              class="align-middle cursor-pointer"
              type="minus-circle"
            />
          </a-space>
        </div>
        <a-row :gutter="10">
          <a-col :span="8">
            <a-form-model-item
              :rules="[
                {
                  max: 10,
                  message: '字数超出限制',
                  trigger: ['change', 'blur'],
                },
                { required: true, message: '请填写', trigger: 'blur' },
              ]"
              :prop="`Competitives.${index}.Keyword`"
            >
              <gf-input
                placeholder="关键词,不超过10个字"
                v-model="item.Keyword"
              ></gf-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="16">
            <gf-textarea
              placeholder="对该竞争力进行详细描述"
              :max="50"
              :prop="`Competitives.${index}.Desc`"
              :required="true"
              :rows="4"
              v-model="item.Desc"
            ></gf-textarea>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item>
        <div slot="label" class="h-40 flex items-center">
          <span></span>
        </div>
        <div
          @click="add"
          class="bg-white h-40 border border-eee rounded text-center leading-10"
        >
          添加
        </div>
      </a-form-model-item>
    </a-form-model>
    <div class="text-right mt-40">
      <a-space>
        <a-button size="large" @click="cancel" type="primary" ghost
          >取消</a-button
        >
        <a-button size="large" @click="save" type="primary">保存</a-button>
      </a-space>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dataSource: {},
    };
  },
  computed: {
    container() {
      return this.$refs.container;
    },
    rules() {
      return {};
    },
  },
  methods: {
    add() {
      this.dataSource.Competitives.push({});
    },
    deleteCompitive(index) {
      this.dataSource.Competitives.splice(index, 1);
    },
    cancel() {
      this.$emit("cancel");
    },
    save() {
      this.container.validate((valid) => {
        if (valid) {
          this.$emit("save", this.dataSource);
        }
      });
    },
  },
  watch: {
    model: {
      handler(val) {
        const temp = JSON.parse(JSON.stringify(val));
        if (temp.Competitives && !temp.Competitives.length) {
          temp.Competitives.push({});
        }
        this.dataSource = temp;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>