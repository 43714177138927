<template>
  <div class="w-full h-full relative overflow-hidden">
    <img
      v-if="url"
      @load="load"
      class="w-full h-full object-contain"
      :src="url"
      alt=""
    />
    <div
      class="w-full h-full bg-white absolute z-10 left-0 top-0"
      v-if="type === 'company' && !success"
    >
      <img
        class="w-full h-full object-contain"
        src="@/assets/icons/company-empty.svg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "GfImage",
  props: {
    url: String,
    type: String,
  },
  methods: {
    load() {
      this.success = true;
    },
  },
  data() {
    return {
      success: false,
    };
  },
};
</script>