<template>
  <a-layout class="text-base min-h-screen">
    <a-affix class="z-50">
      <a-layout-header class="header">
        <global-header></global-header>
      </a-layout-header>
    </a-affix>

    <a-layout-content class="content">
      <slot></slot>
    </a-layout-content>
    <a-layout-footer class="footer">
      <global-footer></global-footer>
    </a-layout-footer>
  </a-layout>
</template>

<script>
export default {
  name: "global-layout",
};
</script>

<style scoped>
.header {
  height: 72px;
  line-height: 72px;
  padding: 0;
  background: #fff;
}
.content {
  background-color: #fff;
  min-height: calc(100% - 72px - 96px);
}
.footer {
  height: 96px;
  padding: 0;
  background-image: linear-gradient(to bottom, #fcfcfd, #f7f8f9);
}
</style>