<template>
  <premission-link :href="href">
    <div class="partner-card bg-f9">
      <div class="top-box p-20 relative">
        <div class="bg absolute top-0 left-0 w-full h-full">
          <IndustryImage
            :tag="dataSource.tags[0]"
            class="absolute top-0 left-0 w-full h-full"
          />
          <div
            class="absolute top-0 left-0 w-full h-full opacity-20 bg-black"
          ></div>
        </div>
        <div class="relative industry-wrapper" style="z-index: 2">
          <div class="mb-10" v-for="(item, index) in tags" :key="index">
            <div class="industry-tag" :class="item.color">
              {{ item.Name }}
            </div>
          </div>
          <div>
            <div
              class="industry-tag"
              :class="tags[0].color"
              v-if="dataSource.tags.length > 3"
            >
              ......
            </div>
          </div>
        </div>
      </div>

      <div class="bottom-box p-20">
        <div class="ellipsis-1 font-bold text-lg mb-10">
          {{ dataSource.shortName || dataSource.name }}
        </div>
        <gf-tag-group>
          <gf-tag v-for="(tag, index) in dataSource.tags1" :key="index">
            {{ tag.Name }}
          </gf-tag>
        </gf-tag-group>
      </div>
    </div>
  </premission-link>
</template>

<script>
import IndustryImage from "@/components/partner/industryImage.vue";
export default {
  name: "company-partner-card",
  components: { IndustryImage },
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    tags() {
      const tags = this.dataSource.tags.slice(0, 3);
      const tag = tags[0] ? tags[0].ID : 0;
      let color;
      if ([1, 4, 12].includes(tag)) {
        color = "ae";
      } else if ([6, 7, 8, 9, 10, 15, 16, 22, 23].includes(tag)) {
        color = "fj";
      } else if ([14, 13].includes(tag)) {
        color = "ko";
      } else if ([17, 19, 20, 3, 5, 11].includes(tag)) {
        color = "pt";
      } else if ([18, 21, 2, 0].includes(tag)) {
        color = "uz";
      }

      return tags.map((tag) => {
        return {
          ...tag,
          color,
        };
      });
    },
    href() {
      return `#/evaluate/company/${this.dataSource.id}`;
    },
  },
  data() {
    return {
      detail: "",
    };
  },
  created() {
    this.detail = "/detail/" + this.dataSource.id;
  },
  methods: {
    getColor(id) {
      return "ae";
    },
  },
  watch: {
    "dataSource.id"(val) {
      this.detail = "/detail/" + val;
    },
  },
};
</script>

<style scoped>
.partner-card:hover {
  box-shadow: 2px 5px 32px -10px rgba(0, 0, 0, 0.2);
}
.bg {
  transition: all 0.3s ease;
}
.partner-card:hover .bg {
  transform: scale(1.2);
}
.top-box {
  height: 200px;
  overflow: hidden;
}
.bottom-box {
  height: 98px;
}
.industry-tag {
  padding: 5px 10px;
  border: 1px solid #2e7ced;
  color: #2e7ced;
  font-size: 12px;
  color: white;
  border-radius: 4px;
  display: inline-block;
  line-height: 16px;
}
.industry-tag + .industry-tag {
  margin-top: 10px;
}
.industry-tag.ae {
  background-color: rgba(217, 52, 52, 0.5);
  border: 1px solid rgba(217, 52, 52, 0.6);
}
.industry-tag.fj {
  background-color: rgba(252, 138, 0, 0.5);
  border: 1px solid rgba(252, 138, 0, 0.6);
}
.industry-tag.ko {
  background-color: rgba(255, 160, 0, 0.5);
  border: 1px solid rgba(255, 160, 0, 0.6);
}
.industry-tag.pt {
  background-color: rgba(0, 179, 197, 0.5);
  border: 1px solid rgba(0, 179, 197, 0.6);
}
.industry-tag.uz {
  background-color: rgba(46, 124, 237, 0.5);
  border: 1px solid rgba(46, 124, 237, 0.6);
}
.custom1 {
  background-image: url("../../assets/images/custom1.jpeg");
  background-size: 100% auto;
  background-position-y: center;
}
.custom2 {
  background-image: url("../../assets/images/custom2.jpeg");
  background-size: 100% auto;
  background-position-y: center;
}
.custom3 {
  background-image: url("../../assets/images/custom3.jpeg");
  background-size: 100% auto;
  background-position-y: center;
}
.custom4 {
  background-image: url("../../assets/images/custom4.jpeg");
  background-size: 100% auto;
  background-position-y: center;
}
.custom5 {
  background-image: url("../../assets/images/custom5.jpeg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry1 {
  background-image: url("../../assets/images/dzxx.jpeg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry2 {
  background-image: url("../../assets/images/zbzz.jpeg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry3 {
  background-image: url("../../assets/images/qc.jpg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry4 {
  background-image: url("../../assets/images/cb.jpg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry5 {
  background-image: url("../../assets/images/swyy.jpg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry6 {
  background-image: url("../../assets/images/hthk.jpeg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry7 {
  background-image: url("../../assets/images/gt.jpg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry8 {
  background-image: url("../../assets/images/hg.jpg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry9 {
  background-image: url("../../assets/images/jjz.jpg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry10 {
  background-image: url("../../assets/images/gcjx.jpeg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry11 {
  background-image: url("../../assets/images/sw.jpg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry12 {
  background-image: url("../../assets/images/dj.jpg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry13 {
  background-image: url("../../assets/images/ny.jpg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry14 {
  background-image: url("../../assets/images/ny2.jpg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry15 {
  background-image: url("../../assets/images/jz.jpeg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry16 {
  background-image: url("../../assets/images/fz.jpg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry17 {
  background-image: url("../../assets/images/sp.jpg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry18 {
  background-image: url("../../assets/images/wl.jpeg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry19 {
  background-image: url("../../assets/images/qg.jpg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry20 {
  background-image: url("../../assets/images/rjfw.jpg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry21 {
  background-image: url("../../assets/images/yh.jpeg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry22 {
  background-image: url("../../assets/images/jy.jpg");
  background-size: 100% auto;
  background-position-y: center;
}
.industry23 {
  background-image: url("../../assets/images/jd.jpeg");
  background-size: 100% auto;
  background-position-y: bottom;
}
</style>