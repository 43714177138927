import { request, proRequest } from '@/utils/request.js'

// 创建企业
export function createCompany() {
    return request.post(`/user/company`)
}

// 获取企业填写进度
export function getCompanyProgress() {
    return request.get(`/user/company/progress`)
}

// 获取企业列表
export function getCompanyList(params) {
    return request.get(`/company`, { params })
}

// 获取企业信息
export function getCompany(id) {
    return request.get(`/company/${id}`)
}

// 查询统一信用码是否存在
export function checkCreditCode(params) {
    return request.get(`/company/${id}/check_credit_code`, { params })
}

// 获取当前企业信息
export function getCurrentCompany() {
    return request.get('/user/company')
}

// 修改当前企业信息
export function saveCurrentCompany(data) {
    return request.post('/user/company', data)
}

// 提交企业信息
export function submitCompany() {
    return request.post('/user/company/submit')
}

// 提交会员负责人
export function submitInitiator() {
    return request.post('/user/company/initiator/submit')
}

// 提交企业情况
export function submitInfo() {
    return request.post('/user/company/state/submit')
}

// 设置企业信息可见性
export function setCompanyVisible(data) {
    return request.post('user/company/visible', data)
}

// 获取填写进度
export function getProgress() {
    return request.get('/user/fill_progress')
}

// 获取后台企业审核列表
export function getProCompanyList(params) {
    return proRequest.get('/company', { params })
}

// 获取后台会员负责人列表
export function getProInitiatorList(params) {
    return proRequest.get('/company/_/initiators', { params })
}

// 获取后台企业情况列表
export function getProStateList(params) {
    return proRequest.get('/company/_/states', { params })
}

// 后台获取企业信息
export function getProCompany(id) {
    return proRequest.get(`/company/${id}`)
}

// 后台修改企业信息
export function updateProCompany(id, data) {
    return proRequest.put(`/company/${id}`, data)
}

// 后台审核企业信息
export function evaluateProCompany(id, data) {
    return proRequest.post(`/company/${id}/audit`, data)
}

// 后台审核会员负责人
export function evaluateProInitiator(id, data) {
    return proRequest.post(`/company/${id}/initiator/audit`, data)
}

// 后台审核企业情况
export function evaluateProState(id, data) {
    return proRequest.post(`/company/${id}/state/audit`, data)
}

// 后台保存对接情况
export function updateProSurvey(id, data) {
    return proRequest.put(`/company/${id}/state/survey`, data)
}

// 后台获取企业库列表
export function getOpCompanys(params) {
    return proRequest.get(`/library/company`, { params })
}