<template>
  <div>
    <a-modal
      v-model="visible"
      okText="确定"
      cancelText="取消"
      title="安全专业委员会简介"
      @ok="
        () => {
          visible = false;
        }
      "
    >
      <div class="overflow-y-scroll" style="max-height: 60vh">
        <h1>上海市工业互联网协会安全专业委员会</h1>
        <h2>关于安全委员会</h2>
        <p>
          上海市工业互联网协会安全专业委员会（以下简称“安全专委会”）成立于2021年1月6日，是上海市工业互联网协会下属分支机构
        </p>
        <p>
          安全专委会旨在通过行业自律，进一步加强工业互联网行业在安全相关专业领域理论、技术及应用的研究，形成创新实用的安全产品和综合解决方案，加速工业互联网安全标准规范化进程，解决行业在发展过程中的安全问题；加强成员间交流与合作，促进各成员单位在工业互联网安全领域关键技术、应用、标准等方面的更紧密合作，形成网络、平台、安全等全方位发展的良好氛围；积极响应政府号召，培养一批具有核心竞争力的工业互联网安全企业，加快构建行业安全保障体系，提升行业安全保障能力，促进行业高质量发展。
        </p>

        <h2>主任委员：</h2>
        <p>郑忠斌 工业互联网创新中心（上海）有限公司董事长兼总经理</p>

        <h2>副主任委员</h2>
        <p>刘 虹 上海工业控制安全创新科技有限公司首席技术官</p>
        <p>陆 炜 上海工业自动化仪表研究院有限公司工业互联网安全部副总经理</p>
        <p>周亚超 上海安恒时代信息技术有限公司副总经理兼首席科学家</p>
        <p>查海平 上海三零卫士信息安全有限公司网络安全研究员</p>
      </div>
    </a-modal>
    <a-form-model
      ref="container"
      :rules="rules"
      :model="dataSource"
      :colon="false"
      :labelCol="{ span: 6 }"
      :wrapperCol="{ span: 15 }"
      v-bind="$attrs"
      labelAlign="left"
    >
      <a-form-model-item prop="Website">
        <div slot="label" class="h-40 flex items-center">
          <span>安全委员会加入情况</span>
          <a-tooltip class="ml-10">
            <span slot="title"
              >查看
              <span @click="visible = true" class="text-primary cursor-pointer"
                >《上海市工业互联网协会安全专业委员会简介》</span
              >
              了解委员会详情。</span
            >
            <a-icon type="question-circle" theme="filled" style="color: #ddd" />
          </a-tooltip>
        </div>
        <a-row>
          <a-col :span="15">
            <a-select v-model="dataSource.JoinCommission">
              <a-select-option :value="1"> 已加入 </a-select-option>
              <a-select-option :value="2"> 未加入 </a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="Proposal">
        <div slot="label" class="h-40 flex items-center">
          <span>对协会工作建议</span>
        </div>
        <a-row>
          <a-col :span="24">
            <gf-textarea
              placeholder="请输入您的宝贵建议"
              prop="Proposal"
              :max="150"
              :required="true"
              :rows="6"
              v-model="dataSource.Proposal"
            ></gf-textarea>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="other">
        <div slot="label" class="h-40 flex items-center">
          <span>其他附件</span>
        </div>
        <a-row>
          <a-col :span="24">
            <file-upload
              field="3"
              :size="20"
              accept=".pdf,.docx,.doc,.png,.jpg,.jpeg"
              v-model="dataSource.Attachments"
            ></file-upload>
          </a-col>
        </a-row>
      </a-form-model-item>
    </a-form-model>
    <div class="text-right mt-40">
      <a-space>
        <a-button size="large" @click="cancel" type="primary" ghost
          >取消</a-button
        >
        <a-button size="large" @click="save" type="primary">保存</a-button>
      </a-space>
    </div>
  </div>
</template>

<script>
import { sessionGet } from "@/utils/storage.js";

export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {
    const options = sessionGet("options");
    this.whether = options.whether;
  },
  data() {
    return {
      dataSource: {},
      whether: [],
      visible: false,
    };
  },
  computed: {
    container() {
      return this.$refs.container;
    },
    rules() {
      return {};
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    save() {
      this.$refs.container.validate((valid) => {
        if (valid) {
          this.$emit("save", this.dataSource);
        }
      });
    },
  },
  watch: {
    model: {
      handler(val) {
        this.dataSource = { ...val };
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
p {
  margin-top: 10px;
}
h1 {
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}
h2 {
  margin-top: 20px;
}
</style>