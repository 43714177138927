<template>
  <a-form-model-item
    :wrapperCol="{ span }"
    :prop="prop"
    :rules="[
      { max, message: '字数超出限制', trigger: ['change','blur'] },
    ]"
  >
    <a-input :maxLength="255" v-bind="$attrs" v-on="$listeners"></a-input>
  </a-form-model-item>
</template>

<script>
export default {
  name: "GfInput",
  model: {
    prop: "value",
    event: "change.value",
  },
  props: {
    max: {
      type: Number,
      default: Infinity,
    },
    prop: String,
    span: {
      type: Number,
      default: 24,
    },
  },
};
</script>