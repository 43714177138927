<template>
  <div>
    <a-form-model
      ref="container"
      :rules="rules"
      :model="dataSource"
      :colon="false"
      :labelCol="{ span: 6 }"
      :wrapperCol="{ span: 15 }"
      v-bind="$attrs"
      labelAlign="left"
    >
      <a-form-model-item prop="MembershipTypeId" :wrapperCol="{ span: 9 }">
        <div slot="label" class="h-40 flex items-center">
          <span>会员种类</span>
          <a-tooltip class="ml-10">
            <span slot="title"
              >根据贵单位是否为上海市工业互联网协会会员单位，按真实情况填写。</span
            >
            <a-icon type="question-circle" theme="filled" style="color: #ddd" />
          </a-tooltip>
        </div>
        <a-select size="large" v-model="dataSource.MembershipTypeId">
          <a-select-option
            v-for="option in membership"
            :key="option.ID"
            :value="option.ID"
          >
            {{ option.Name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        prop="Referrer"
        v-if="isManager"
        :wrapperCol="{ span: 9 }"
      >
        <div slot="label" class="h-40 flex items-center">
          <span>推荐人姓名</span>
        </div>

        <gf-input
          placeholder="请输入"
          size="large"
          v-model="dataSource.Referrer"
        ></gf-input>
      </a-form-model-item>
      <a-form-model-item
        prop="ReferrerCompany"
        v-if="isManager"
        :wrapperCol="{ span: 9 }"
      >
        <div slot="label" class="h-40 flex items-center">
          <span>推荐人单位</span>
        </div>

        <gf-input
          placeholder="请输入"
          size="large"
          v-model="dataSource.ReferrerCompany"
        ></gf-input>
      </a-form-model-item>
      <a-form-model-item prop="Name" :wrapperCol="{ span: 9 }">
        <div slot="label" class="h-40 flex items-center">
          <span>单位名称（中文）</span>
        </div>

        <gf-input
          placeholder="单位全称请与营业执照保持一致"
          size="large"
          v-model="dataSource.Name"
        ></gf-input>
      </a-form-model-item>
      <a-form-model-item prop="EName" :wrapperCol="{ span: 9 }">
        <div slot="label" class="h-40 flex items-center">
          <span>单位名称（英文）</span>
        </div>

        <gf-input
          placeholder="如无请填写“无”"
          size="large"
          v-model="dataSource.EName"
        ></gf-input>
      </a-form-model-item>
      <a-form-model-item prop="ShortName" :wrapperCol="{ span: 9 }">
        <div slot="label" class="h-40 flex items-center">
          <span>单位简称</span>
        </div>

        <gf-input
          prop="ShortName"
          :max="10"
          size="large"
          v-model="dataSource.ShortName"
        ></gf-input>
      </a-form-model-item>
      <a-form-model-item prop="CreditCode" :wrapperCol="{ span: 9 }">
        <div slot="label" class="h-40 flex items-center">
          <span>统一社会信用码</span>
        </div>

        <gf-input
          placeholder="请填写18位社会信用代码"
          size="large"
          v-model="dataSource.CreditCode"
        ></gf-input>
      </a-form-model-item>
      <a-form-model-item prop="Representative" :wrapperCol="{ span: 9 }">
        <div slot="label" class="h-40 flex items-center">
          <span>法人代表</span>
        </div>

        <gf-input size="large" v-model="dataSource.Representative"></gf-input>
      </a-form-model-item>
      <a-form-model-item prop="RegisterTime" :wrapperCol="{ span: 9 }">
        <div slot="label" class="h-40 flex items-center">
          <span>成立时间</span>
        </div>

        <a-date-picker
          placeholder="请选择年/月/日"
          valueFormat="X"
          size="large"
          v-model="dataSource.RegisterTime"
        ></a-date-picker>
      </a-form-model-item>
      <a-form-model-item prop="RegisterCapital" :wrapperCol="{ span: 9 }">
        <div slot="label" class="h-40 flex items-center">
          <span>注册资本（万元）</span>
        </div>

        <a-input-number
          :min="0"
          size="large"
          v-model="dataSource.RegisterCapital"
        ></a-input-number>
      </a-form-model-item>
      <a-form-model-item prop="RegisterAddress" :wrapperCol="{ span: 9 }">
        <div slot="label" class="h-40 flex items-center">
          <span>注册地址</span>
        </div>

        <gf-input
          placeholder="请输入详细地址"
          size="large"
          v-model="dataSource.RegisterAddress"
        ></gf-input>
      </a-form-model-item>
      <a-form-model-item prop="BusinessAddress">
        <div slot="label" class="h-40 flex items-center">
          <span>办公地址</span>
        </div>

        <gf-input
          placeholder="请输入详细地址"
          size="large"
          v-model="dataSource.BusinessAddress"
        ></gf-input>
      </a-form-model-item>
      <a-form-model-item prop="CategoryId">
        <div slot="label" class="h-40 flex items-center">
          <span>单位性质</span>
        </div>
        <a-row>
          <a-col :span="15">
            <a-select
              placeholder="请输入详细地址"
              size="large"
              v-model="dataSource.CategoryId"
            >
              <a-select-option
                v-for="option in nature"
                :key="option.ID"
                :value="option.ID"
              >
                {{ option.Name }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="Category" v-if="dataSource.CategoryId === -1">
        <div slot="label" class="h-40 flex items-center">
          <span>自定义单位性质</span>
        </div>
        <a-row>
          <a-col :span="15">
            <gf-input size="large" v-model="dataSource.Category"></gf-input>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="License">
        <div slot="label" class="h-40 flex items-center">
          <span>营业执照</span>
        </div>
        <a-row>
          <a-col :span="15">
            <picture-upload
              :scale="false"
              v-model="dataSource.License"
              :width="338"
              :height="160"
              accept=".png,.jpg,.jpeg"
              :size="5"
              field="0"
            ></picture-upload>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="Logo">
        <div slot="label" class="h-40 flex items-center">
          <span>公司logo</span>
        </div>
        <a-row>
          <a-col :span="15">
            <picture-upload
              v-model="dataSource.Logo"
              :scale="{ w: 1, h: 1 }"
              :width="338"
              :height="160"
              accept=".png,.jpg,.jpeg"
              :size="5"
              field="11"
            ></picture-upload>
          </a-col>
        </a-row>
      </a-form-model-item>
    </a-form-model>
    <div class="text-right mt-40">
      <a-space>
        <a-button size="large" @click="cancel" type="primary" ghost
          >取消</a-button
        >
        <a-button size="large" @click="save" type="primary">保存</a-button>
      </a-space>
    </div>
  </div>
</template>

<script>
import { validateCodeExist } from "@/utils/validator.js";
export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dataSource: {},
    };
  },
  computed: {
    role() {
      return this.$store.state.role;
    },
    isManager() {
      return this.role === 6 || this.role === 7;
    },
    membership() {
      return this.options.membership;
    },
    whether() {
      return this.options.whether;
    },
    nature() {
      return this.options.nature;
    },
    container() {
      return this.$refs.container;
    },
    rules() {
      return {
        MembershipTypeId: [
          {
            required: !this.$store.getters.isManager,
            message: "请选择会员单位",
            trigger: "blur",
          },
        ],
        Name: [
          {
            required: !this.$store.getters.isManager,
            message: "请输入公司名称",
            trigger: "blur",
          },
        ],
        ShortName: [
          {
            required: !this.$store.getters.isManager,
            message: "请输入公司简称",
            trigger: "blur",
          },
        ],
        CreditCode: [
          {
            required: !this.$store.getters.isManager,
            message: "请输入统一社会信用码",
            trigger: "blur",
          },
          { len: 18, message: "请输入18位统一社会信用码", trigger: "blur" },
          {
            validator: validateCodeExist(this.dataSource.id),
            message: "社会信用码已存在",
            trigger: "blur",
          },
        ],
        CategoryId: [
          {
            required: !this.$store.getters.isManager,
            message: "请选择单位性质",
            trigger: "blur",
          },
        ],
        Category: [
          {
            required: !this.$store.getters.isManager,
            message: "请输入自定义单位性质",
            trigger: "blur",
          },
        ],
        Logo: [
          {
            required: !this.$store.getters.isManager,
            message: "请上传公司logo",
            trigger: "blur",
          },
        ],
        RegisterAddress: [
          {
            required:
              this.dataSource.MembershipTypeId !== 0 &&
              !this.$store.getters.isManager,
            message: "请输入注册地址",
            trigger: "blur",
          },
        ],
        BusinessAddress: [
          {
            required:
              this.dataSource.MembershipTypeId !== 0 &&
              !this.$store.getters.isManager,
            message: "请输入办公地址",
            trigger: "blur",
          },
        ],
      };
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    save() {
      this.$refs.container.validate((valid) => {
        if (valid) {
          this.$emit("save", this.dataSource);
        }
      });
    },
  },
  watch: {
    model: {
      handler(val) {
        const temp = { ...val };
        temp.RegisterTime = temp.RegisterTime ? +temp.RegisterTime + "" : null;
        if (temp.Property) {
          if (temp.Property.ID === 0) {
            temp.CategoryId = -1;
            temp.Category = temp.Property.Name;
          } else {
            temp.CategoryId = temp.Property.ID;
            temp.Category = "";
          }
        }
        if (temp.MembershipTypeId) {
          temp.MembershipTypeId = temp.MembershipTypeId.ID;
        }

        this.dataSource = temp;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>