<template>
  <div>
    <a-form-model
      ref="container"
      :rules="rules"
      :model="dataSource"
      :colon="false"
      :labelCol="{ span: 6 }"
      :wrapperCol="{ span: 15 }"
      v-bind="$attrs"
      labelAlign="left"
    >
      <a-form-model-item prop="Name">
        <div slot="label" class="h-40 flex items-center">
          <span>姓名</span>
        </div>
        <a-row>
          <a-col :span="15">
            <a-form-model-item prop="Name">
              <gf-input
                prop="Name"
                size="large"
                v-model="dataSource.Name"
              ></gf-input>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model-item>
      <!-- <a-form-model-item prop="Gender">
        <div slot="label" class="h-40 flex items-center">
          <span>性别</span>
        </div>
        <a-row>
          <a-col :span="15">
            <a-select size="large" v-model="dataSource.Gender">
              <a-select-option
                v-for="option in gender"
                :key="option.ID"
                :value="option.ID"
              >
                {{ option.Name }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-form-model-item> -->
      <a-form-model-item prop="Department">
        <div slot="label" class="h-40 flex items-center">
          <span>部门</span>
        </div>
        <a-row>
          <a-col :span="15">
            <a-form-model-item prop="Department">
              <gf-input
                prop="Department"
                size="large"
                v-model="dataSource.Department"
              ></gf-input>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="Position">
        <div slot="label" class="h-40 flex items-center">
          <span>职位</span>
        </div>
        <a-row>
          <a-col :span="15">
            <a-form-model-item prop="Position">
              <gf-input
                prop="Position"
                size="large"
                v-model="dataSource.Position"
              ></gf-input>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="Phone">
        <div slot="label" class="h-40 flex items-center">
          <span>手机号码</span>
        </div>
        <a-row>
          <a-col :span="15">
            <a-form-model-item prop="Phone">
              <gf-input
                prop="Phone"
                size="large"
                v-model="dataSource.Phone"
              ></gf-input>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="Wechat">
        <div slot="label" class="h-40 flex items-center">
          <span>微信号</span>
        </div>
        <a-row>
          <a-col :span="15">
            <a-form-model-item prop="Wechat">
              <gf-input
                prop="Wechat"
                size="large"
                v-model="dataSource.Wechat"
              ></gf-input>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item>
        <div slot="label" class="h-40 flex items-center">
          <span>座机</span>
        </div>
        <a-row>
          <a-col :span="15">
            <gf-input size="large" v-model="dataSource.Telephone"></gf-input>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="Email">
        <div slot="label" class="h-40 flex items-center">
          <span>Email</span>
        </div>

        <gf-input
          prop="Email"
          size="large"
          v-model="dataSource.Email"
        ></gf-input>
      </a-form-model-item>
      <a-form-model-item prop="Identity">
        <div slot="label" class="h-40 flex items-center">
          <span>身份证号码</span>
        </div>

        <gf-input size="large" v-model="dataSource.Identity"></gf-input>
      </a-form-model-item>
      <a-form-model-item>
        <div slot="label" class="h-40 flex items-center">
          <span>身份证正反面</span>
        </div>
        <a-row :gutter="40">
          <a-col :span="12">
            <a-form-model-item prop="IDFront">
              <picture-upload
              :scale="false"
                v-model="dataSource.IDFront"
                :width="240"
                :height="160"
                accept=".png,.jpg,.jpeg"
                :size="5"
                field="1"
              ></picture-upload>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item prop="IDBack">
              <picture-upload
              :scale="false"
                v-model="dataSource.IDBack"
                :width="240"
                :height="160"
                accept=".png,.jpg,.jpeg"
                :size="5"
                field="2"
              ></picture-upload>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item>
        <div slot="label" class="h-40 flex flex-col">
          <div>认证公函</div>
          <a
            class="text-xs text-primary"
            download
            href="https://minio.gongfuxingqiu.com/static/template/工赋星球认证申请公函.docx"
            >下载公函</a
          >
        </div>
        <a-row>
          <a-col :span="15">
            <file-upload
              :multiple="false"
              v-model="dataSource.Missive"
              accept=".doc,.docx,.pdf,.png,.jpg,.jpeg"
              :size="20"
              field="16"
            ></file-upload>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="ContactStaffOption" v-if="isMember">
        <div slot="label" class="h-40 flex items-center">
          <span>协会对接人选项</span>
        </div>
        <a-select size="large" v-model="dataSource.ContactStaffOption">
          <a-select-option :value="0">单独填写</a-select-option>
          <a-select-option :value="1">与平台运营人一致</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop="MarketStaffOption" v-if="isMember">
        <div slot="label" class="h-40 flex items-center">
          <span>市场负责人选项</span>
        </div>
        <a-select size="large" v-model="dataSource.MarketStaffOption">
          <a-select-option :value="0">单独填写</a-select-option>
          <a-select-option :value="1">与平台运营人一致</a-select-option>
          <a-select-option :value="2">与协会对接人一致</a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
    <div class="text-right mt-40">
      <a-space>
        <a-button size="large" @click="cancel" type="primary" ghost
          >取消</a-button
        >
        <a-button size="large" @click="save" type="primary">保存</a-button>
      </a-space>
    </div>
  </div>
</template>

<script>
import { validateEmail, validateId } from "@/utils/validator.js";
export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    isMember: Boolean,
  },
  created() {
    this.gender = this.options.gender;
  },
  data() {
    return {
      dataSource: {},
      gender: [],
    };
  },
  computed: {
    container() {
      return this.$refs.container;
    },
    rules() {
      return {
        Name: [
          {
            required: !this.$store.getters.isManager,
            message: "请填写",
            trigger: "blur",
          },
        ],
        // Gender: [{ required: !this.$store.getters.isManager, message: "请填写", trigger: "blur" }],
        Department: [
          {
            required: !this.$store.getters.isManager,
            message: "请填写",
            trigger: "blur",
          },
        ],
        Email: [
          {
            required: !this.$store.getters.isManager,
            message: "请填写",
            trigger: "blur",
          },
          {
            validator: validateEmail,
            message: "请填写正确的邮箱格式",
            trigger: "blur",
          },
        ],
        Wechat: [
          {
            required: !this.$store.getters.isManager,
            message: "请填写",
            trigger: "blur",
          },
        ],
        Phone: [
          {
            required: !this.$store.getters.isManager,
            message: "请填写",
            trigger: "blur",
          },
        ],
        ContactStaffOption: [
          {
            required: !this.$store.getters.isManager,
            message: "请填写",
            trigger: "blur",
          },
        ],
        MarketStaffOption: [
          {
            required: !this.$store.getters.isManager,
            message: "请填写",
            trigger: "blur",
          },
        ],
        Position: [
          {
            required: !this.$store.getters.isManager,
            message: "请填写",
            trigger: "blur",
          },
        ],
        Identity: [
          {
            validator: validateId,
            message: "请填写正确的身份证格式",
            trigger: "blur",
          },
        ],
      };
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    save() {
      this.$refs.container.validate((valid) => {
        if (valid) {
          this.$emit("save", this.dataSource);
        }
      });
    },
  },
  watch: {
    model: {
      handler(val) {
        const temp = { ...val };
        temp.IDBack = temp.IDBack || {};
        temp.IDFront = temp.IDFront || {};
        temp.Missive = temp.Missive || {};
        if (temp.Missive.ID) {
          temp.Missive = [temp.Missive];
        } else {
          temp.Missive = [];
        }
        this.dataSource = temp;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>