<template>
  <div>
    <a-spin :spinning="loading">
      <a-upload
        :action="upload()"
        list-type="picture-card"
        :show-upload-list="false"
        :accept="accept"
        @change="handleChange"
        :before-upload="beforeUpload"
      >
        <div
          v-if="url"
          class="bg-white flex flex-col justify-center items-center"
          :style="{ height: height + 'px', width: width + 'px' }"
        >
          <img
            :src="upload() + '/' + url"
            alt=""
            class="w-full h-full object-contain"
          />
        </div>
        <div
          v-else
          class="bg-white flex flex-col justify-center items-center"
          :style="{ height: height + 'px', width: width + 'px' }"
        >
          <div class="text-primary text-sm mb-10">点击上传</div>
          <div class="text-xs">支持.png,.jpg,.jpeg,小于5M</div>
        </div>
      </a-upload>
    </a-spin>

    <a-modal
      :width="600"
      :destroyOnClose="true"
      title="裁切图片"
      v-model="visible"
      okText="裁切"
      cancelText="取消"
      @ok="cropper"
    >
      <VueCropper
        ref="cropper"
        :img="file"
        style="height: 400px"
        :outputSize="1"
        outputType="jpg"
        :info="false"
        :autoCrop="true"
        :fixedNumber="[scale.w, scale.h]"
        :centerBox="true"
        :fixedBox="true"
        :fixed="true"
        :full="true"
        :infoTrue="true"
      ></VueCropper>
    </a-modal>
  </div>
</template>

<script>
import { upload } from "@/api";
import { VueCropper } from "vue-cropper";
export default {
  name: "picture-upload",
  components: {
    VueCropper,
  },
  props: {
    size: Number,
    accept: String,
    value: {
      type: Object,
      default: () => ({}),
    },
    height: Number,
    width: Number,
    scale: {
      type: [Object, Boolean],
      default: () => ({
        w: 4,
        h: 3,
      }),
    },
  },
  data() {
    return {
      loading: false,
      url: "",
      visible: false,
      file: "",
      output: "",
      origin: "",
    };
  },
  methods: {
    handleChange({ file }) {
      if (file.status === "uploading") {
        this.loading = true;
      }
      if (file.status === "done") {
        this.loading = false;
        this.$message.success("上传成功");

        const data = file.response.data;
        this.url = data.id;

        this.$emit("input", data);
      }
    },
    beforeUpload(file) {
      this.output = "";
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("只能上传图片文件!");
      }
      const isLt2M = file.size / 1024 / 1024 < this.size;
      if (!isLt2M) {
        this.$message.error(`图片大小不能超过${this.size}MB!`);
      }
      if (isLt2M && isJpgOrPng) {
        if (this.scale) {
          this.origin = file;
          this.file = URL.createObjectURL(file);
          this.visible = true;
          return new Promise((resolve) => {
            this.timer = setInterval(() => {
              if (this.output) {
                resolve(this.b64toBlob(this.output));
                clearInterval(this.timer);
              }
            }, 100);
          });
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    upload() {
      return upload();
    },
    cropper() {
      this.$refs.cropper.getCropData((data) => {
        this.output = data;
        this.visible = false;
      });
    },
    b64toBlob(dataURI) {
      var byteString = atob(dataURI.split(",")[1]);
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);

      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new File([ab], this.origin.name, { type: this.origin.type });
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.url = val.ID;
        }
      },
      immediate: true,
    },
  },
};
</script>