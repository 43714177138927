<template>
  <div class="text-333 text-sm">
    <a-row class="pt-10">
      <a-col :span="12" class="mt-10">
        <a-space>
          <div class="text-999">单位规模：</div>
          <div>{{ ScaleId }}</div>
        </a-space>
      </a-col>
      <a-col :span="12" class="mt-10">
        <a-space>
          <div class="text-999">上市情况：</div>
          <div>{{ Listed }}</div>
        </a-space>
      </a-col>
      <a-col :span="12" class="mt-10">
        <a-space>
          <div class="text-999">科研人员占比：</div>
          <div>{{ Resercher }}</div>
        </a-space>
      </a-col>
      <a-col :span="12" class="mt-10">
        <a-space>
          <div class="text-999">上年度营收：</div>
          <div>{{ LastYearRevenue }}万</div>
        </a-space>
      </a-col>
      <a-col :span="12" class="mt-10">
        <a-space>
          <div class="text-999">企业融资轮次：</div>
          <div>{{ FinancingId }}</div>
        </a-space>
      </a-col>
      <a-col :span="12" class="mt-10">
        <a-space>
          <div class="text-999">企业估值：</div>
          <div>{{ Valuation }}万</div>
        </a-space>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    scale() {
      return this.options.scale;
    },
    whether() {
      return this.options.whether;
    },
    turn() {
      return this.options.turn;
    },
    ScaleId() {
      const scale = this.model.Scale;
      if (scale) {
        if (scale.Name) {
          return scale.Name;
        } else {
          const option = this.scale[scale.ID - 1];
          return option ? option.Name : "-";
        }
      } else {
        return "-";
      }
    },
    Listed() {
      return this.model.Listed === 1 ? "已上市" : "未上市";
    },
    Resercher() {
      return this.model.Resercher ? this.model.Resercher + "%" : "-";
    },
    LastYearRevenue() {
      return this.model.Revenue;
    },
    FinancingId() {
      const turn = this.model.Financing
      if (turn) {
        if (turn.Name) {
          return turn.Name;
        } else {
          const option = this.turn[turn.ID - 1];
          return option ? option.Name : "-";
        }
      } else {
        return "-";
      }
    },
    Valuation() {
      return this.model.Valuation;
    },
  },
};
</script>
