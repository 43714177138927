<template>
  <a-modal
    v-bind="$attrs"
    v-on="$listeners"
    title="审核意见"
    okText="确定"
    cancelText="取消"
    :afterClose="afterClose"
    :destroyOnClose="true"
    @ok="ok"
  >
    <a-form-model :model="model" ref="container">
      <gf-form-item
        prop="feedback"
        :rules="[
          {
            required: required,
            message: '请填写审核意见',
            trigger: ['change', 'blur'],
          },
          { max: 300, message: '字数超出限制', trigger: ['change', 'blur'] },
        ]"
        style="margin: 0"
      >
        <gf-re-textarea :max="300" v-model="model.feedback"></gf-re-textarea>
      </gf-form-item>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  name: "RefuseModal",
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    required: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      model: {
        feedback: "",
      },
    };
  },
  methods: {
    ok() {
      this.$refs.container.validate((valid) => {
        if (valid) {
          this.$emit("confirm", this.model);
        }
      });
    },
    afterClose() {
      this.model = {};
    },
  },
};
</script>