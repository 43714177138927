<template>
  <div class="py-20">
    <div class="p-20 bg-f9">
      <div
        class="filter-option text-sm flex"
        v-for="item in filters"
        :key="item.label"
      >
        <div class="label">{{ item.label }}：</div>
        <div class="option-wrapper flex flex-wrap">
          <div
            :class="{
              'text-primary':
                find({ id: option.ID, flag: item.optionFlag }) !== -1,
            }"
            @click="
              select({ id: option.ID, flag: item.optionFlag, mode: item.mode })
            "
            class="mr-20 mb-20 cursor-pointer"
            v-for="option in options1[item.optionFlag]"
            :key="option.ID"
          >
            {{ option.Name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "list-filter",
  inject: ["options"],
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: [],
      options1: {},
    };
  },
  created() {
    const temp = {};
    this.filters.forEach((item) => {
      const tempA = [...this.options[item.optionFlag]];
      tempA.unshift({ ID: 0, Name: "全部" });
      temp[item.optionFlag] = tempA;
      this.selected.push({ id: 0, flag: item.optionFlag });
    });
    this.options1 = temp;
  },
  methods: {
    select(option) {
      const i = this.find(option);
      if (i === -1) {
        if (option.id !== 0) {
          if (option.mode === "multiple") {
            this.selected.push(option);
          } else {
            const temp = this.selected.filter(
              (item) => item.flag !== option.flag
            );
            temp.push(option);
            this.selected = temp;
          }
          const i1 = this.find({ id: 0, flag: option.flag });
          if (i1 !== -1) {
            this.selected.splice(i1, 1);
          }
        } else {
          this.selected = this.selected.filter(
            (item) => item.flag !== option.flag
          );
          this.selected.push(option);
        }
      } else {
        this.selected.splice(i, 1);
        if (!this.selected.filter((item) => item.flag === option.flag).length) {
          this.selected.push({
            id: this.options1[option.flag][0].ID,
            name: this.options1[option.flag][0].Name,
            flag: option.flag,
          });
        }
      }
      this.$emit("input", this.selected);
      this.$emit("filter", this.selected);
    },
    find(option) {
      let index = -1;
      if (!option) {
        return index;
      }
      for (let i = 0; i < this.selected.length; i++) {
        if (
          this.selected[i].id === option.id &&
          this.selected[i].flag === option.flag
        ) {
          index = i;
        }
      }
      return index;
    },
  },
};
</script>

<style scoped>
/* .filter-option + .filter-option {
  margin-top: 20px;
} */
.label {
  width: 108px;
}
.option-wrapper {
  width: 1000px;
}
</style>