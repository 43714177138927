<template>
  <div class="text-333 text-sm">
    <a-row class="pt-10">
      <a-col :span="24">
        <a-row :gutter="20" type="flex">
          <a-col>
            <div class="Photo">
              <img
                v-if="Photo"
                class="w-full h-full object-cover"
                :src="Photo"
                alt=""
              />
            </div>
          </a-col>
          <a-col :span="20">
            <a-row>
              <a-col :span="12" class="mb-10">
                <a-space>
                  <span class="text-999">性别：</span>
                  <span>{{ Gender }}</span>
                </a-space>
              </a-col>
              <a-col :span="12" class="mb-10">
                <a-space>
                  <span class="text-999">身份证号码：</span>
                  <span>{{ Identity }}</span>
                </a-space>
              </a-col>
              <a-col :span="12" class="mb-10">
                <a-space>
                  <span class="text-999">政治面貌：</span>
                  <span>{{ Political }}</span>
                </a-space>
              </a-col>
              <a-col :span="12" class="mb-10">
                <a-space>
                  <span class="text-999">学历：</span>
                  <span>{{ Education }}</span>
                </a-space>
              </a-col>
              <a-col :span="12" class="mb-10">
                <a-space>
                  <span class="text-999">技术职称：</span>
                  <span>{{ Title }}</span>
                </a-space>
              </a-col>
              <a-col :span="12" class="mb-10">
                <a-space>
                  <span class="text-999">部门：</span>
                  <span>{{ Department }}</span>
                </a-space>
              </a-col>
              <a-col :span="12" class="mb-10">
                <a-space>
                  <span class="text-999">Email：</span>
                  <span>{{ Email }}</span>
                </a-space>
              </a-col>
              <a-col :span="12" class="mb-10">
                <a-space>
                  <span class="text-999">手机号码：</span>
                  <span>{{ Phone }}</span>
                </a-space>
              </a-col>
              <a-col :span="24" class="mb-10">
                <a-space>
                  <span class="text-999">开户银行及账号：</span>
                  <span>{{ Bank }}</span>
                </a-space>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="24" class="mt-20" v-if="model.Tags.length">
        <div class="text-999 mb-10">个人标签：</div>
        <div class="flex flex-wrap">
          <div class="tag" v-for="item in model.Tags" :key="item">
            {{ item }}
          </div>
        </div>
      </a-col>
      <a-col :span="24" class="mt-20" v-if="industryText.length">
        <div class="text-999 mb-10">擅长行业：</div>
        <div class="flex flex-wrap">
          <div class="tag" v-for="item in industryText" :key="item">
            {{ item }}
          </div>
        </div>
      </a-col>
      <a-col :span="24" class="mt-20" v-if="fieldText.length">
        <div class="text-999 mb-10">擅长领域：</div>
        <div class="flex flex-wrap">
          <div class="tag" v-for="item in fieldText" :key="item">
            {{ item }}
          </div>
        </div>
      </a-col>
      <a-col :span="24" class="mt-20" v-if="serviceText.length">
        <div class="text-999 mb-10">擅长服务：</div>
        <div class="flex flex-wrap">
          <div class="tag" v-for="item in serviceText" :key="item">
            {{ item }}
          </div>
        </div>
      </a-col>
      <a-col :span="24" class="mt-20" v-if="model.Honors.length">
        <div class="text-999 mb-10">获得荣誉：</div>
        <div v-for="(item, index) in model.Honors" :key="index">
          <span>
            {{ index + 1 }}、{{
              honorLevel[item.Level - 1] ? honorLevel[item.Level - 1].Name : ""
            }}
          </span>
          ---
          <span>{{
            honorClass[item.Class - 1] ? honorClass[item.Class - 1].Name : ""
          }}</span>
          ---
          <span>{{ item.Name }}</span>
        </div>
      </a-col>
      <a-col :span="24" class="mt-20">
        <div class="text-999 mb-10">个人成果：</div>
        <div>
          <div v-for="(item, index) in Desc" :key="index">{{ item }}</div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { upload } from "@/api";
export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    gender() {
      return this.options.gender;
    },
    service() {
      return this.options.service;
    },
    honorClass() {
      return this.options.honorClass;
    },
    honorLevel() {
      return this.options.honorLevel;
    },
    expertField() {
      return this.options.expertField;
    },
    education() {
      return this.options.education;
    },
    political() {
      return this.options.political;
    },
    container() {
      return this.$refs.container;
    },
    Gender() {
      const gender = this.model.Gender;
      if (gender === 0) {
        return "男";
      } else if (gender === 1) {
        return "女";
      } else {
        return "-";
      }
    },
    industryText() {
      return this.model.Industries.map((item) => {
        if (item.Name) {
          return item.Name;
        } else {
          const option = this.options.industry[item.ID - 1];
          return option ? option.Name : "";
        }
      });
    },
    fieldText() {
      return this.model.Fields.map((item) => {
        if (item.Name) {
          return item.Name;
        } else {
          const option = this.options.expertField[item.ID - 1];
          return option ? option.Name : "";
        }
      });
    },
    serviceText() {
      return this.model.Services.map((item) => {
        if (item.Name) {
          return item.Name;
        } else {
          const option = this.options.service[item.ID - 1];
          return option ? option.Name : "";
        }
      });
    },
    Political() {
      if (this.model.Political) {
        const item = this.model.Political;
        if (item.Name) {
          return item.Name;
        } else {
          const option = this.options.political[item.ID - 1];
          return option ? option.Name : "";
        }
      } else {
        return "-";
      }
    },
    Education() {
      if (this.model.Education) {
        const item = this.model.Education;
        if (item.Name) {
          return item.Name;
        } else {
          const option = this.options.education[item.ID - 1];
          return option ? option.Name : "";
        }
      } else {
        return "-";
      }
    },
    Photo() {
      return this.model.Photo && this.model.Photo.ID
        ? upload() + "/" + this.model.Photo.ID
        : "";
    },
    Identity() {
      return this.model.Identity || "-";
    },
    Title() {
      return this.model.Title || "-";
    },
    Desc() {
      return this.model.Desc.split("\n");
    },
    Department() {
      return this.model.Department || "-";
    },
    Email() {
      return this.model.Email || "-";
    },
    Phone() {
      return this.model.Phone || "-";
    },
    Bank() {
      return `${this.model.BankName}-${this.model.BankBranch}-${this.model.BankAccount}`;
    },
  },
};
</script>

<style scoped>
.Photo {
  width: 120px;
  height: 160px;
  border: 1px solid #eee;
}
.tag {
  padding: 5px 10px;
  border: 1px solid #2e7ced;
  color: #2e7ced;
  border: 1px solid #eee;
  background-color: #f9f9f9;
  margin-bottom: 10px;
  margin-right: 10px;
}
</style>
