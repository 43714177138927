<template>
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    :class="`flag-${id}`"
  >
    <g
      id="页面1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="工业企业画像" transform="translate(-712.000000, -747.000000)">
        <g id="小节点" transform="translate(708.000000, 743.000000)">
          <g id="旗帜1-01" transform="translate(4.000000, 4.000000)">
            <rect
              id="矩形"
              fill="#000000"
              fill-rule="nonzero"
              opacity="0"
              x="0"
              y="0"
              width="16"
              height="16"
            ></rect>
            <path
              d="M12.8957498,2.59668508 C12.8155573,2.62983425 12.6952686,2.67955801 12.5348837,2.72928177 C12.3744988,2.78729282 12.1900561,2.83701657 11.989575,2.89502762 C11.7890938,2.95303867 11.5404972,2.99447514 11.2518043,3.02762431 C10.9631115,3.06077348 10.6744186,3.08563536 10.3777065,3.08563536 C10.1932638,3.08563536 9.71210906,3.0441989 9.43945469,2.96961326 C8.797915,2.78729282 8.4691259,2.6961326 8.4691259,2.6961326 L7.53889334,2.37292818 C7.22614274,2.26519337 6.90537289,2.17403315 6.56856455,2.09944751 C6.23977546,2.03314917 5.91900561,2 5.63031275,2 C5.33360064,2 5.03688853,2.01657459 4.74819567,2.05801105 C4.45950281,2.09944751 4.21090617,2.14088398 4.01844427,2.19060773 C3.8340016,2.2320442 3.64955894,2.29005525 3.46511628,2.35635359 C3.28869286,2.42265193 3.16840417,2.4640884 3.12028869,2.48895028 C3.06415397,2.51381215 3.02405774,2.53038674 3,2.54696133 L3,13.4530387 C3,13.6022099 3.04811548,13.7348066 3.15236568,13.8425414 C3.25661588,13.9502762 3.37690457,14 3.521251,14 C3.66559743,14 3.79390537,13.9502762 3.89013633,13.8425414 C3.99438653,13.7348066 4.042502,13.6104972 4.042502,13.4530387 L4.042502,9.82320442 C4.57177225,9.69889503 5.1010425,9.64088398 5.6222935,9.64088398 C6.07137129,9.64917127 6.40817963,9.71546961 6.56054531,9.75690608 L7.5308741,10.0303867 L8.46110666,10.3453039 C8.7818765,10.4530387 9.10264635,10.5441989 9.43143545,10.6187845 C9.76824379,10.6933702 10.0809944,10.7348066 10.3696872,10.7348066 C10.6663994,10.7348066 10.9631115,10.718232 11.2518043,10.6767956 C11.5485164,10.6353591 11.7890938,10.5939227 11.9815557,10.5441989 C12.1659984,10.5027624 12.3504411,10.4447514 12.5348837,10.378453 C12.7113071,10.3121547 12.8315958,10.2707182 12.8797113,10.2458564 C12.935846,10.2209945 12.9759423,10.2044199 13,10.1878453 L13,2.54696133 L12.8957498,2.59668508 Z"
              id="路径"
              fill="currentColor"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "CustomIcon",
  props: {
    id: {
      type: Number,
      default: 2,
    },
  },
};
</script>
