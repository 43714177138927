<template>
  <div class="text-333 text-sm">
    <a-row class="pt-10">
      <a-col :span="24" class="mt-20">
        <a-space>
          <div class="text-999">安全委员会加入情况：</div>
          <div>{{ JoinCommission }}</div>
        </a-space>
      </a-col>
      <a-col :span="24" class="mt-20">
        <a-space direction="vertical" class="w-full">
          <div class="text-999">对协会工作建议：</div>
          <div>
            <div v-for="(item, index) in Proposal" :key="index">
              {{ item }}
            </div>
          </div>
        </a-space>
      </a-col>
      <a-col :span="24" class="mt-20">
        <a-space direction="vertical" class="w-full">
          <div class="text-999">其他附件：<span v-if="!Attachments.length">无</span></div>
          <div v-if="Attachments.length">
            <div
              style="margin-bottom:4px"
              v-for="(item, index) in Attachments"
              :key="index"
            >
              <a-space>
                <img src="@/assets/icons/attachment.svg" alt="" />
                <a target="blank" :href="item.link">{{
                  item.Name || item.name
                }}</a>
              </a-space>
            </div>
          </div>
        </a-space>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { upload } from "@/api";
export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    JoinCommission() {
      return this.model.JoinCommission === 1 ? "已加入" : "未加入";
    },
    Proposal() {
      return this.model.Proposal.split("\n");
    },
    Attachments() {
      const attach = this.model.Attachments || [];
      return attach.map((item) => {
        return {
          link: upload() + "/" + item.ID,
          Name: item.Name,
        };
      });
    },
  },
};
</script>
