<template>
  <a-input
    v-bind="$attrs"
    v-on="$listeners"
    :maxLength="maxLength"
    :placeholder="placeholder"
  >
    <slot slot="addonBefore" name="addonBefore"></slot>
  </a-input>
</template>

<script>
export default {
  name: "GfReInput",
  model: {
    prop: "value",
    event: "change.value",
  },
  props: {
    maxLength: {
      type: Number,
      default: 255,
    },
    placeholder: {
      type: String,
      default: "请输入",
    },
  },
};
</script>