<template>
  <div class="px-20 border border-eee hover:shadow bg-white rounded">
    <div class="pt-20">
      <div class="relative flex">
        <div class="logo mr-20">
          <img
            class="w-full"
            v-if="dataSource.cover && dataSource.cover.url && dataSource.weight"
            :src="dataSource.cover.url"
            alt=""
          />
          <img
            v-if="[6, 8].includes(dataSource.categoryId) && !dataSource.weight"
            src="@/assets/images/knowledge-2.png"
            alt=""
          />
          <img
            v-else-if="
              [1, 2, 3, 9].includes(dataSource.categoryId) && !dataSource.weight
            "
            src="@/assets/images/knowledge-1.png"
            alt=""
          />
          <img
            v-else-if="
              [4, 5, 7].includes(dataSource.categoryId) && !dataSource.weight
            "
            src="@/assets/images/knowledge-3.png"
            alt=""
          />
        </div>
        <div class="info pb-20">
          <div
            style="24px"
            class="ellipsis-1 mb-10 font-bold cursor-pointer hover:text-primary"
            @click="open"
          >
            {{ dataSource.name }}
          </div>
          <div class="ellipsis-2 h-40 mb-20 text-sm">
            {{ dataSource.desc }}
          </div>
          <div class="text-999 text-xs ellipsis-1">
            来源：{{ dataSource.source }}
          </div>
        </div>
        <div class="absolute -right-10 -top-10">
          <slot name="opt" />
        </div>
      </div>
    </div>
    <div
      style="height: 56px"
      class="
        flex
        justify-between
        items-center
        border-t border-eee
        text-xs text-999
      "
    >
      <div
        style="height: 27px"
        class="tag-wrapper flex flex-wrap overflow-hidden"
      >
        <div
          class="tag mr-10"
          v-for="(tag, index) in dataSource.tags"
          :key="index"
        >
          {{ tag.Name }}
        </div>
      </div>
      <div style="width: 130px" class="text-right">
        {{ dataSource.updateTime }} 更新
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "user-knowledge-card",
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    open() {
      this.$emit("detail", this.dataSource);
    },
  },
};
</script>

<style scoped>
.logo {
  width: 90px;
  height: 128px;
}
.info {
  width: calc(100% - 140px);
}
.tag {
  padding: 5px 10px;
  border: 1px solid #2e7ced;
  color: #2e7ced;
}
.tag-wrapper {
  width: calc(100% - 150px);
}
</style>