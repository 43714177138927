<template>
  <a-row class="wrapper">
    <div class="heat-calendar" ref="calendar"></div>
  </a-row>
</template>

<script>
import { init, number } from "echarts";

export default {
  name: "HeatCanlendar",
  props: {
    dataSource: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.years = this.getYears();
    this.selectYear(this.years[0]);
  },
  data() {
    return {
      year: "",
      years: [],
    };
  },
  computed: {
    chartOptions() {
      return {
        grid: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 30,
        },
        tooltip: {
          formatter(params) {
            return `${params.data}`;
          },
        },
        visualMap: {
          show: false,
          min: 0,
          max: 10000,
          type: "piecewise",
          orient: "horizontal",
          left: "center",
          top: 65,
          inRange: {
            color: ["#ebedf0", "#216e39"],
          },
        },
        calendar: {
          left: 70,
          top: 36,
          width: "90%",
          // height: "100%",
          range: this.year,
          cellSize: 20,
          itemStyle: {
            borderWidth: 3,
            borderColor: "#eee",
            color: "#fff",
          },
          splitLine: { show: false },
          yearLabel: { show: false },
          dayLabel: {
            show: true,
            nameMap: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
          },
          monthLabel: {
            show: true,
            nameMap: [
              "一月",
              "二月",
              "三月",
              "四月",
              "五月",
              "六月",
              "七月",
              "八月",
              "九月",
              "十月",
              "十一月",
              "十二月",
            ],
          },
        },
        series: {
          type: "heatmap",
          coordinateSystem: "calendar",
          data: [],
        },
        visualMap: {
          type: "piecewise",
          orient: "horizontal",
          left: 30,
          bottom: 20,
          pieces: [
            { gte: 1, lte: 5, color: "#59D4FF" },
            { gte: 6, lte: 10, color: "#00A9FF" },
            { gte: 11, lte: 15, color: "#0A7AF5" },
            { gte: 16, color: "#003E8F" },
          ],
        },
      };
    },
  },
  methods: {
    selectYear(e) {
      this.year = e;
      const start = +number.parseDate(e + "-01-01") / 1000;
      const end = +number.parseDate(+e + 1 + "-01-01") / 1000;
      this.$emit("change", { start, end });
    },
    initChart() {
      this.instance = init(this.$refs.calendar);
      this.instance.setOption(this.chartOptions);
      this.instance.on("click", (params) => {
        this.$emit("select", params.data);
      });
    },
    updateChart(val) {
      const data = val,
        e = this.year;
      this.instance.setOption({
        calendar: {
          range: e,
        },
        series: {
          data,
        },
      });
    },
    getYears() {
      const year = new Date().getFullYear();
      let temp = [];
      for (let i = 0; i < 4; i++) {
        temp.push(year - i);
      }

      return temp;
    },
  },
  watch: {
    dataSource(val) {
      this.updateChart(val);
    },
  },
  mounted() {
    this.initChart();
  },
};
</script>

<style lang="less">
.wrapper {
  background-color: #f9f9f9;
}
.heat-calendar {
  height: 240px;
}
.year-group {
  width: 100%;
  height: 240px;
  overflow-y: scroll;
  padding: 20px 10px;
  &::-webkit-scrollbar {
    display: none;
  }
}
.year-card {
  height: 40px;
  border-radius: 4px;
  background-color: #fff;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  &:hover {
    @apply text-primary;
  }
}
</style>