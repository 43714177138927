<template>
  <div class="pr-3 relative">
    <div class="text-999 text-xs">
      <slot></slot>
    </div>
    <div
      class="absolute w-2 h-2 rounded-full right-0 top-1"
      :class="color"
    ></div>
  </div>
</template>

<script>
export default {
  name: "StatusBox",
  props: {
    status: Number,
    evaluate: {
      type: Number,
      default: undefined,
    },
    selected: {
      type: Boolean,
      default: undefined,
    },
    ReviewCount: {
      type: Number,
      default: undefined,
    },
    type: String,
  },
  computed: {
    color() {
      if (this.status === -1) {
        return "close";
      } else if (this.status === 1) {
        return "first";
      } else if (this.status === 10 && !this.type) {
        return "expert";
      } else if (this.status === 10 && this.type === "expert") {
        return "first";
      } else if (this.status === 20) {
        return "second";
      } else if (this.status === 30 && this.evaluate === undefined) {
        return "finish";
      } else if (this.status === 30 && this.evaluate === 0) {
        return "publish";
      } else if (this.status === 30 && this.evaluate === 1) {
        return "evaluate";
      } else if (
        this.status === 30 &&
        this.evaluate === 2 &&
        this.selected === undefined &&
        this.ReviewCount === undefined
      ) {
        return "finish";
      } else if (
        this.status === 30 &&
        this.evaluate === 2 &&
        this.selected === true
      ) {
        return "finish";
      } else if (
        this.status === 30 &&
        this.evaluate === 2 &&
        this.selected === false
      ) {
        return "reject";
      } else if (
        this.status === 30 &&
        this.evaluate === 2 &&
        this.ReviewCount === 0
      ) {
        return "reviewing";
      } else if (
        this.status === 30 &&
        this.evaluate === 2 &&
        this.ReviewCount > 0
      ) {
        return "review";
      } else if (this.status === 99) {
        return "reject";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.first {
  background-color: #2e7ced;
}
.expert {
  background-color: #fcbe00;
}
.second {
  background-color: #fc8a00;
}
.publish {
  background-color: #fcbe00;
}
.evaluate {
  background-color: #fc8a00;
}
.finish {
  background-color: #52c42b;
}
.reviewing {
  background-color: #52c42b;
}
.review {
  background-color: #881bc4;
}
.reject {
  background-color: #d93434;
}
.close {
  background-color: #ddd;
}
</style>