<template>
  <div class="text-333 text-sm">
    <a-row class="pt-10">
      <a-col :span="24">
        <div class="mt-10" v-for="(item, index) in model.Honors" :key="index">
          <span>
            {{ index + 1 }}、{{
              options.honorLevel.find((honor) => honor.ID === item.Level).Name
            }}
          </span>
          ---
          <span>{{
            options.honorLevel.find((honor) => honor.ID === item.Level).Name
          }}</span>
          ---
          <span>{{ item.Name }}</span>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {
    this.honorLevel = this.options.honorLevel;
    this.honorClass = this.options.honorClass;
  },
};
</script>
