<template>
  <a-input-number
    type="number"
    v-bind="$attrs"
    v-model="value1"
    :parser="parser"
    :formatter="formatter"
    @blur="blur"
  ></a-input-number>
</template>

<script>
export default {
  name: "TenThousandNumber",
  props: {
    min: Number,
    max: Number,
    value: Number,
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      value1: "",
    };
  },
  methods: {
    toFixed(val) {
      const arr = val.toString().split(".");
      if (arr[1]) arr[1] = arr[1].substring(0, 4);
      return arr.join(".");
    },
    formatter(val) {
      if (["", undefined].includes(val)) return undefined;
      let value = this.toFixed(val / 10000);
      return value;
    },
    parser(val) {
      if (["", undefined].includes(val)) return undefined;
      let value = Math.floor(+val * 10000);
      return value;
    },
    blur() {
      if (this.min !== undefined && this.value1 < this.min)
        this.value1 = this.min;
      if (this.max && this.value1 > this.max) this.value1 = this.max;

      this.$emit("change", this.value1);
    },
  },
  watch: {
    value: {
      handler(val) {
        this.value1 = val;
      },
      immediate: true,
    },
  },
};
</script>