import axios from 'axios';
import { localGet } from './storage'

const base = config.API_BASE_URL || 'https://gf.app.dadax/';

const instance = axios.create({
    baseURL: `${base}api/v1/`
})

instance.interceptors.request.use(config => {
    const token = localGet('token')
    config.headers['Authorization'] = token
    return config;
})

instance.interceptors.response.use(config => {
    return config.data;
})

export default instance;