<template>
  <div>
    <a-row :gutter="gutter" v-if="dataSource.length">
      <a-col
        :class="{ 'mt-20': index + 1 > size }"
        :span="24 / size"
        v-for="(item, index) in dataSource"
        :key="item.ID"
      >
        <div class="inline-block hover:shadow w-full">
          <partner-card
            :dataSource="item"
            v-if="type === 'partner'"
          ></partner-card>
          <company-partner-card
            :dataSource="item"
            v-if="type === 'company-partner'"
          ></company-partner-card>
          <knowledge-card
            :dataSource="item"
            v-if="type === 'knowledge'"
          ></knowledge-card>
          <expert-card
            :dataSource="item"
            v-if="type === 'expert'"
          ></expert-card>
          <product-card
            :dataSource="item"
            v-if="type === 'product'"
          ></product-card>
          <case-card :dataSource="item" v-if="type === 'case'"></case-card>
          <solution-card
            :dataSource="item"
            v-if="type === 'solution'"
          ></solution-card>
          <question-card
            :dataSource="item"
            v-if="type === 'question'"
          ></question-card>
          <e-question-card
            :dataSource="item"
            v-if="type === 'e-question'"
          ></e-question-card>
          <evaluate-partner-card
            :dataSource="item"
            v-if="type === 'e-partner'"
          ></evaluate-partner-card>
          <e-expert-card
            :dataSource="item"
            v-if="type === 'e-expert'"
          ></e-expert-card>
          <evaluate-partner-state-card
            :dataSource="item"
            v-if="type === 'e-partner-state'"
          ></evaluate-partner-state-card>
          <evaluate-partner-event-card
            :dataSource="item"
            v-if="type === 'e-partner-event'"
          ></evaluate-partner-event-card>
          <evaluate-partner-leader-card
            :dataSource="item"
            v-if="type === 'e-partner-leader'"
          ></evaluate-partner-leader-card>
          <e-product-card
            :dataSource="item"
            v-if="type === 'e-product'"
          ></e-product-card>
          <e-base-card
            :dataSource="item"
            v-if="type === 'e-base'"
          ></e-base-card>
          <e-case-card
            :dataSource="item"
            v-if="type === 'e-case'"
          ></e-case-card>
          <e-solution-card
            :dataSource="item"
            v-if="type === 'e-solution'"
          ></e-solution-card>
          <policy-card
            :dataSource="item"
            v-if="type === 'policy'"
          ></policy-card>
        </div>
      </a-col>
    </a-row>
    <list-empty v-else></list-empty>
    <div class="text-center mt-40" v-if="dataSource.length && pagination.limit">
      <a-pagination
        :current="pagination.offset / pagination.limit + 1"
        :pageSize="pagination.limit"
        :total="total"
        @change="change"
      ></a-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "pagination-list",
  props: {
    dataSource: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Object,
      default: () => ({}),
    },
    type: String,
    size: Number,
    total: Number,
    gutter: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    change(e) {
      const temp = { ...this.pagination };
      temp.offset = temp.limit * (e - 1);
      this.$emit("update:pagination", temp);
      this.$emit("change");
    },
  },
};
</script>