<template>
  <router-link custom :to="to" v-slot="{ navigate }">
    <a class="text-current" @click="navigate">
      <slot></slot>
    </a>
  </router-link>
</template>

<script>
export default {
  name: "Link",
  props: {
    to: {
      type: String,
      default: "/",
    },
  },
};
</script>