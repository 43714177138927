import { getMessageList } from '@/api/api/message'

export default {
    namespaced: true,
    state: {
        unReadMessage: [],
        unReadCount: 0,
    },
    mutations: {
        setMessage(state, message) {
            state.unReadMessage = message
        },
        setMessageCount(state, count) {
            state.unReadCount = count
        }
    },
    actions: {
        getMessage({ commit }, id) {
            getMessageList(id, { unread: true, subclass: 10 }).then(({ data }) => {
                commit('setMessage', data.rows || [])
                commit('setMessageCount', data.count)
            })
        }
    }
}