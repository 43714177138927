<template>
  <div class="border border-eee text-333">
    <div class="title font-bold px-20 ellipsis-1">{{ dataSource.title }}</div>
    <div class="p-20 bg-white">
      <div class="mb-10 text-xs overflow-hidden" style="height:26px">
        <a-space align="baseline">
          <div style="width: 60px">所属领域：</div>
          <div class="flex flex-wrap">
            <div
              class="tag1"
              v-for="(tag, index) in dataSource.fields"
              :key="index"
            >
              {{ tag.Name }}
            </div>
          </div>
        </a-space>
      </div>
      <div class="mb-10 text-xs overflow-hidden" style="height:26px">
        <a-space align="baseline">
          <span>所属行业：</span>
          <div class="flex flex-wrap">
            <div
              class="tag1"
              v-for="(tag, index) in dataSource.industries"
              :key="index"
            >
              {{ tag.Name }}
            </div>
          </div>
        </a-space>
      </div>
      <div class="flex pt-20" v-if="dataSource.answer">
        <div style="width: 24px; max-height: 80px" class="mr-10">
          <img src="@/assets/icons/question.svg" class="w-full h-full object-contain" alt="" />
        </div>
        <div style="width: calc(100% - 34px)" class="text-999">{{ dataSource.answer }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "question-card",
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style scoped>
.title {
  height: 64px;
  line-height: 64px;
  background-color: #f0f8ff;
}
.tag1 {
  padding: 5px 10px;
  border: 1px solid #eee;
  color: #333;
  line-height: 1;
  display: inline-block;
  height: 26px;
  font-size: 12px;
}
</style>