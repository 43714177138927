<template>
  <div>
    <a-form-model
      ref="container"
      :rules="rules"
      :model="dataSource"
      :colon="false"
      :labelCol="{ span: 6 }"
      :wrapperCol="{ span: 15 }"
      v-bind="$attrs"
      labelAlign="left"
    >
      <a-form-model-item prop="Name" :wrapperCol="{ span: 9 }">
        <div slot="label" class="h-40 flex items-center">
          <span>姓名</span>
        </div>
        <gf-input size="large" v-model="dataSource.Name"></gf-input>
      </a-form-model-item>
      <a-form-model-item prop="Gender" :wrapperCol="{ span: 9 }">
        <div slot="label" class="h-40 flex items-center">
          <span>性别</span>
        </div>

        <a-select size="large" v-model="dataSource.Gender">
          <a-select-option
            v-for="option in gender"
            :key="option.ID"
            :value="option.ID"
          >
            {{ option.Name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop="Identity" :wrapperCol="{ span: 9 }">
        <div slot="label" class="h-40 flex items-center">
          <span>身份证号码</span>
        </div>

        <gf-input size="large" v-model="dataSource.Identity"></gf-input>
      </a-form-model-item>
      <a-form-model-item prop="Political" :wrapperCol="{ span: 9 }">
        <div slot="label" class="h-40 flex items-center">
          <span>政治面貌</span>
        </div>

        <a-select size="large" v-model="dataSource.Political">
          <a-select-option
            v-for="option in political"
            :key="option.ID"
            :value="option.ID"
          >
            {{ option.Name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop="Education" :wrapperCol="{ span: 9 }">
        <div slot="label" class="h-40 flex items-center">
          <span>学历</span>
        </div>

        <a-select size="large" v-model="dataSource.Education">
          <a-select-option
            v-for="option in education"
            :key="option.ID"
            :value="option.ID"
          >
            {{ option.Name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop="Title" :wrapperCol="{ span: 9 }">
        <div slot="label" class="h-40 flex items-center">
          <span>技术职称</span>
        </div>

        <gf-input size="large" v-model="dataSource.Title"></gf-input>
      </a-form-model-item>
      <a-form-model-item prop="Department" :wrapperCol="{ span: 9 }">
        <div slot="label" class="h-40 flex items-center">
          <span>部门</span>
        </div>

        <gf-input size="large" v-model="dataSource.Department"></gf-input>
      </a-form-model-item>
      <a-form-model-item prop="Position" :wrapperCol="{ span: 9 }">
        <div slot="label" class="h-40 flex items-center">
          <span>职位</span>
        </div>

        <gf-input size="large" v-model="dataSource.Position"></gf-input>
      </a-form-model-item>
      <a-form-model-item prop="Email" :wrapperCol="{ span: 9 }">
        <div slot="label" class="h-40 flex items-center">
          <span>Email</span>
        </div>

        <gf-input size="large" v-model="dataSource.Email"></gf-input>
      </a-form-model-item>
      <a-form-model-item prop="Phone" :wrapperCol="{ span: 9 }">
        <div slot="label" class="h-40 flex items-center">
          <span>联系电话</span>
        </div>

        <gf-input size="large" v-model="dataSource.Phone"></gf-input>
      </a-form-model-item>
      <a-form-model-item prop="Photo">
        <div slot="label" class="h-40 flex items-center">
          <span>高清个人照</span>
        </div>
        <a-row>
          <a-col :span="15">
            <a-form-model-item prop="Photo">
              <picture-upload
                :scale="{ w: 413, h: 626 }"
                v-model="dataSource.Photo"
                :width="338"
                :height="160"
                accept=".png,.jpg,.jpeg"
                :size="5"
                field="6"
              ></picture-upload>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item :required="!$store.getters.isManager">
        <div slot="label" class="h-40 flex items-center">
          <span>开户银行和账号</span>
        </div>
        <a-row :gutter="10">
          <a-col :span="8">
            <a-form-model-item prop="BankName">
              <gf-input
                placeholder="请输入开户银行"
                v-model="dataSource.BankName"
              ></gf-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="16">
            <a-form-model-item prop="BankBranch">
              <gf-input
                placeholder="请输入开户银行具体支行"
                v-model="dataSource.BankBranch"
              ></gf-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="24" class="mt-20">
            <a-form-model-item prop="BankAccount">
              <gf-input
                placeholder="请输入银行账号"
                v-model="dataSource.BankAccount"
              ></gf-input>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="Tags">
        <div slot="label" class="h-40 flex items-center">
          <span>个人标签</span>
        </div>
        <a-select
          placeholder="自定义标签，最多选择3个"
          mode="tags"
          size="large"
          v-model="dataSource.Tags"
        ></a-select>
      </a-form-model-item>

      <a-form-model-item prop="IndustryIDs">
        <div slot="label" class="h-40 flex items-center">
          <span>擅长行业</span>
        </div>
        <a-select
          placeholder="请选择，最多选择5个"
          size="large"
          mode="multiple"
          v-model="dataSource.IndustryIDs"
        >
          <a-select-option
            v-for="option in industry"
            :key="option.ID"
            :value="option.ID"
          >
            {{ option.Name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        prop="Industry"
        v-if="dataSource.IndustryIDs.includes(-1)"
      >
        <div slot="label" class="h-40 flex items-center">
          <span>自定义擅长行业</span>
        </div>

        <a-select
          mode="tags"
          size="large"
          v-model="dataSource.Industry"
        ></a-select>
      </a-form-model-item>
      <a-form-model-item prop="FieldIDs">
        <div slot="label" class="h-40 flex items-center">
          <span>擅长领域</span>
        </div>

        <a-select
          placeholder="请选择，最多选择4个"
          size="large"
          mode="multiple"
          v-model="dataSource.FieldIDs"
        >
          <a-select-option
            v-for="option in expertField"
            :key="option.ID"
            :value="option.ID"
          >
            {{ option.Name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop="Field" v-if="dataSource.FieldIDs.includes(-1)">
        <div slot="label" class="h-40 flex items-center">
          <span>自定义擅长领域</span>
        </div>

        <a-select
          mode="tags"
          size="large"
          v-model="dataSource.Field"
        ></a-select>
      </a-form-model-item>
      <a-form-model-item prop="ServiceIDs">
        <div slot="label" class="h-40 flex items-center">
          <span>擅长服务</span>
        </div>

        <a-select size="large" mode="multiple" v-model="dataSource.ServiceIDs">
          <a-select-option
            v-for="option in service"
            :key="option.ID"
            :value="option.ID"
          >
            {{ option.Name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        prop="Service"
        v-if="dataSource.ServiceIDs.includes(-1)"
      >
        <div slot="label" class="h-40 flex items-center">
          <span>自定义擅长服务</span>
        </div>

        <a-select
          mode="tags"
          size="large"
          v-model="dataSource.Service"
        ></a-select>
      </a-form-model-item>

      <a-form-model-item>
        <div slot="label" class="h-40 flex items-center">
          <span>所获荣誉</span>
        </div>
      </a-form-model-item>
      <a-form-model-item
        v-for="(item, index) in dataSource.Honors"
        :key="item.ID"
      >
        <div slot="label" class="h-40 flex items-center">
          <a-space class="text-999 text-xs" align="baseline">
            <span>荣誉{{ index + 1 }}</span>
            <a-icon
              @click="deleteHonor(index)"
              class="align-middle cursor-pointer"
              type="minus-circle"
            />
          </a-space>
        </div>
        <a-row :gutter="10">
          <a-col :span="6">
            <a-form-model-item
              :prop="`Honors.${index}.Level`"
              :rules="{ required: true, message: '请选择', trigger: 'blur' }"
            >
              <a-select v-model="item.Level">
                <a-select-option
                  v-for="option in honorLevel"
                  :key="option.ID"
                  :value="option.ID"
                >
                  {{ option.Name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item
              :prop="`Honors.${index}.Class`"
              :rules="{ required: true, message: '请选择', trigger: 'blur' }"
            >
              <a-select v-model="item.Class">
                <a-select-option
                  v-for="option in honorClass"
                  :key="option.ID"
                  :value="option.ID"
                >
                  {{ option.Name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              :prop="`Honors.${index}.Name`"
              :rules="{ required: true, message: '请输入', trigger: 'blur' }"
            >
              <gf-input v-model="item.Name"></gf-input>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item>
        <div slot="label" class="h-40 flex items-center">
          <span></span>
        </div>
        <div
          @click="add"
          class="bg-white h-40 border border-eee rounded text-center leading-10"
        >
          添加
        </div>
      </a-form-model-item>

      <a-form-model-item prop="Desc">
        <div slot="label" class="h-40 flex items-center">
          <span>个人成果</span>
        </div>
        <gf-textarea
          prop="Desc"
          :max="300"
          :required="true"
          :rows="6"
          v-model="dataSource.Desc"
        ></gf-textarea>
      </a-form-model-item>
    </a-form-model>
    <div class="text-right mt-40">
      <a-space>
        <a-button size="large" @click="cancel" type="primary" ghost
          >取消</a-button
        >
        <a-button size="large" @click="save" type="primary">保存</a-button>
      </a-space>
    </div>
  </div>
</template>

<script>
import { validateId, validateEmail } from "@/utils/validator.js";
function validate1($this) {
  return (rule, value, callback) => {
    let total =
      $this.dataSource.FieldIDs.length + $this.dataSource.Field.length;

    if ($this.dataSource.FieldIDs.includes(-1)) {
      total--;
    }

    if (total === 0) {
      return callback(new Error("请选择"));
    }

    if (total > 4) {
      return callback(new Error("最多只能选择4个"));
    }

    callback();
  };
}
function validate2($this) {
  return (rule, value, callback) => {
    let total =
      $this.dataSource.IndustryIDs.length + $this.dataSource.Industry.length;
    if ($this.dataSource.IndustryIDs.includes(-1)) {
      total--;
    }
    if (total === 0) {
      return callback(new Error("请选择"));
    }

    if (total > 5) {
      return callback(new Error("最多只能选择5个"));
    }

    callback();
  };
}
function validate4($this) {
  return (rule, value, callback) => {
    let total =
      $this.dataSource.ServiceIDs.length + $this.dataSource.Service.length;
    if ($this.dataSource.ServiceIDs.includes(-1)) {
      total--;
    }
    if (total === 0) {
      return callback(new Error("请选择"));
    }

    if (total > 5) {
      return callback(new Error("最多只能选择5个"));
    }

    callback();
  };
}
function validate5($this) {
  return (rule, value, callback) => {
    let total = $this.dataSource.Tags.length;
    if (total === 0) {
      return callback(new Error("请选择"));
    }

    if (total > 3) {
      return callback(new Error("最多只能选择3个"));
    }

    callback();
  };
}
export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dataSource: {},
    };
  },
  computed: {
    gender() {
      return this.options.gender;
    },
    service() {
      return this.options.service;
    },
    honorClass() {
      return this.options.honorClass;
    },
    honorLevel() {
      return this.options.honorLevel;
    },
    expertField() {
      return this.options.expertField;
    },
    education() {
      return this.options.education;
    },
    political() {
      return this.options.political;
    },
    container() {
      return this.$refs.container;
    },
    industry() {
      return this.options.industry;
    },
    field() {
      return this.options.field;
    },
    rules() {
      return {
        Name: [
          {
            required: !this.$store.getters.isManager,
            message: "请输入",
            trigger: "blur",
          },
        ],
        Gender: [
          {
            required: !this.$store.getters.isManager,
            message: "请输入",
            trigger: "blur",
          },
        ],
        Identity: [
          // {
          //   validator: validateId,
          //   message: "请填写正确的身份证格式",
          //   trigger: "blur",
          // },
        ],
        Political: [
          {
            required: !this.$store.getters.isManager,
            message: "请选择",
            trigger: "blur",
          },
        ],
        Education: [
          {
            required: !this.$store.getters.isManager,
            message: "请选择",
            trigger: "blur",
          },
        ],
        Title: [
          {
            required: !this.$store.getters.isManager,
            message: "请输入",
            trigger: "blur",
          },
        ],
        BankName: [
          {
            required: !this.$store.getters.isManager,
            message: "请输入",
            trigger: "blur",
          },
        ],
        BankBranch: [
          {
            required: !this.$store.getters.isManager,
            message: "请输入",
            trigger: "blur",
          },
        ],
        BankAccount: [
          {
            required: !this.$store.getters.isManager,
            message: "请输入",
            trigger: "blur",
          },
        ],
        Position: [
          {
            required: !this.$store.getters.isManager,
            message: "请输入",
            trigger: "blur",
          },
        ],
        Email: [
          {
            required: !this.$store.getters.isManager,
            message: "请填写",
            trigger: "blur",
          },
          {
            validator: validateEmail,
            message: "请填写正确的邮箱格式",
            trigger: "blur",
          },
        ],
        Phone: [
          {
            required: !this.$store.getters.isManager,
            message: "请输入",
            trigger: "blur",
          },
        ],
        Photo: [
          {
            required: !this.$store.getters.isManager,
            message: "请上传",
            trigger: "blur",
          },
        ],
        Desc: [
          {
            required: !this.$store.getters.isManager,
            message: "请输入",
            trigger: "blur",
          },
        ],
        ServiceIDs: [
          {
            required: !this.$store.getters.isManager,
            message: "请选择",
            trigger: "blur",
          },
        ],
        Service: [
          {
            required: !this.$store.getters.isManager,
            message: "请填写",
            trigger: "blur",
          },
        ],
        IndustryIDs: [
          {
            required: !this.$store.getters.isManager,
            message: "请选择",
            trigger: "blur",
          },
          { validator: validate2(this), trigger: ["change", "blur"] },
        ],
        Industry: [
          {
            required: !this.$store.getters.isManager,
            message: "请填写",
            trigger: "blur",
          },
          { validator: validate2(this), trigger: ["change", "blur"] },
        ],
        FieldIDs: [
          {
            required: !this.$store.getters.isManager,
            message: "请选择",
            trigger: "blur",
          },
          { validator: validate1(this), trigger: ["change", "blur"] },
        ],
        Field: [
          {
            required: !this.$store.getters.isManager,
            message: "请填写",
            trigger: "blur",
          },
          { validator: validate1(this), trigger: ["change", "blur"] },
        ],
        Tags: [
          {
            required: !this.$store.getters.isManager,
            message: "请选择",
            trigger: "blur",
          },
          { validator: validate5(this), trigger: ["change", "blur"] },
        ],
      };
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel", this.dataSource);
    },
    save() {
      this.$refs.container.validate((valid) => {
        if (valid) {
          this.$emit("save", this.dataSource);
          this.$emit("update");
        }
      });
    },
    deleteHonor(index) {
      this.dataSource.Honors.splice(index, 1);
    },
    add() {
      this.dataSource.Honors.push({});
    },
  },
  watch: {
    model: {
      handler(val) {
        const temp = { ...val };
        temp.IndustryIDs = temp.Industries.filter((item) => item.ID !== 0).map(
          (item) => item.ID
        );
        temp.Industry = temp.Industries.filter((item) => item.ID === 0).map(
          (item) => item.Name
        );
        temp.FieldIDs = temp.Fields.filter((item) => item.ID !== 0).map(
          (item) => item.ID
        );
        temp.Field = temp.Fields.filter((item) => item.ID === 0).map(
          (item) => item.Name
        );
        temp.ServiceIDs = temp.Services.filter((item) => item.ID !== 0).map(
          (item) => item.ID
        );
        temp.Service = temp.Services.filter((item) => item.ID === 0).map(
          (item) => item.Name
        );
        if (temp.Industry.length) {
          temp.IndustryIDs.push(-1);
        }
        if (temp.Service.length) {
          temp.ServiceIDs.push(-1);
        }
        if (temp.Field.length) {
          temp.FieldIDs.push(-1);
        }
        if (temp.Education) {
          temp.Education = temp.Education.ID || undefined;
        }
        if (temp.Political) {
          temp.Political = temp.Political.ID || undefined;
        }
        this.dataSource = JSON.parse(JSON.stringify(temp));
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>