<template>
  <a
    @click="push"
    :href="href"
    class="relative"
    v-if="!(![4, 5].includes(this.role) && type === 'apply')"
  >
    <slot></slot>
  </a>
</template>

<script>
import { localGet } from "@/utils/storage.js";

export default {
  name: "PremissionLink",
  props: {
    href: String,
    type: String,
    stop: Boolean
  },
  methods: {
    push(e) {
      if(stop){
        e.stopPropagation()
      }
      const token = localGet("token");
      if (!token) {
        e.preventDefault();
        this.$confirm({
          title: "权限不足",
          content: "当前尚未登录，没有权限进行此操作，请前往登录",
          okText: "登录",
          cancelText: "取消",
          onOk() {
            window.location.href = "#/auth/login";
          },
        });
      }
    },
  },
  computed: {
    role() {
      return this.$store.state.role;
    },
  },
};
</script>