export default {
    'requirement-apply': { type: 3 },
    'requirement-response': { type: 3 },
    'requirement-detail': { type: 3 },
    'product-detail': { type: 3 },
    'case-detail': { type: 3 },
    'solution-detail': { type: 3 },
    'activity-detail': { type: 3 },
    'partner-detail': { type: 3 },
    'expert-detail': { type: 3 },
    'knowledge-detail': { type: 3 },
    'policy-detail': { type: 3 },
}