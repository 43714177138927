<template>
  <router-link
    :to="{
      path: `/evaluate/comment-detail/${dataSource.targetId}`,
      query: { type: dataSource.targetType },
    }"
  >
    <div class="p-20 bg-white border border-eee">
      <div class="flex">
        <div
          class="rounded mr-20 border border-eee relative"
          style="width: 214px; height: 160px"
        >
          <img
            class="w-full h-full object-contain"
            :src="dataSource.targetPhoto.url"
            alt=""
          />
          <div
            class="absolute bottom-0 right-0 text-xs bg-black text-white"
            style="padding: 5px 8px 5px 12px"
          >
            {{ dataSource.targetTypeName }}
          </div>
          <div
            class="
              absolute
              top-20
              left-0
              bg-primary
              text-xs text-white
              rounded-tr-full rounded-br-full
            "
            style="padding: 5px 10px"
          >
            {{ dataSource.statusName }}
          </div>
        </div>
        <div style="width: calc(100% - 234px)" class="relative">
          <div class="font-bold ellipsis-2 mb-20">
            {{ dataSource.title }}
          </div>
          <div class="p-10 ellipsis-1 bg-f9 text-666 text-sm mb-20">
            {{ dataSource.targetName }}
          </div>
          <div class="flex justify-between absolute bottom-0 w-full">
            <div class="text-xs text-999">
              <span>{{ dataSource.updateTime }}</span>
              <span> 更新</span>
              <!-- <span>暂无专家回复</span> -->
            </div>
            <slot name="opt" />
          </div>
        </div>
      </div>
      <slot />
    </div>
  </router-link>
</template>

<script>
export default {
  name: "evaluate-comment-card",
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>