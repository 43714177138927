<template>
  <div>
    <a-form-model
      ref="container"
      :rules="rules"
      :model="dataSource"
      :colon="false"
      :labelCol="{ span: 6 }"
      :wrapperCol="{ span: 15 }"
      v-bind="$attrs"
      labelAlign="left"
    >
      <a-form-model-item>
        <div slot="label" class="h-40 flex items-center">
          <span>合作伙伴</span>
        </div>
      </a-form-model-item>
      <a-form-model-item
        v-for="(item, index) in dataSource.Partners"
        :key="item.ID"
      >
        <div slot="label" class="h-40 flex items-center">
          <a-space class="text-999 text-xs" align="baseline">
            <span>伙伴{{ index + 1 }}</span>
            <a-icon
              @click="deleteCompitive(index)"
              class="align-middle cursor-pointer"
              type="minus-circle"
            />
          </a-space>
        </div>
        <a-row :gutter="10">
          <a-col :span="20">
            <a-form-model-item
              :prop="`Partners.${index}.Name`"
              :rules="{ required: true, message: '请输入', trigger: 'blur' }"
            >
              <gf-input
                placeholder="请输入公司名称"
                v-model="item.Name"
              ></gf-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item
              :prop="`Partners.${index}.Logo`"
              :rules="{ required: true, message: '请输入', trigger: 'blur' }"
            >
              <picture-upload
                :scale="{ w: 1, h: 1 }"
                v-model="item.Logo"
                :width="80"
                :height="80"
                accept=".png,.jpg,.jpeg"
                :size="5"
              ></picture-upload>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item prop="MainBusiness">
        <div slot="label" class="h-40 flex items-center">
          <span></span>
        </div>
        <div
          @click="add"
          class="bg-white h-40 border border-eee rounded text-center leading-10"
        >
          添加
        </div>
      </a-form-model-item>
    </a-form-model>
    <div class="text-right mt-40">
      <a-space>
        <a-button size="large" @click="cancel" type="primary" ghost
          >取消</a-button
        >
        <a-button size="large" @click="save" type="primary">保存</a-button>
      </a-space>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dataSource: {},
    };
  },
  computed: {
    container() {
      return this.$refs.container;
    },
    rules() {
      return {};
    },
  },
  methods: {
    add() {
      this.dataSource.Partners.push({});
    },
    deleteCompitive(index) {
      this.dataSource.Partners.splice(index, 1);
    },
    cancel() {
      this.$emit("cancel");
    },
    save() {
      console.log(this.dataSource);
      this.container.validate((valid) => {
        if (valid) {
          this.$emit("save", this.dataSource);
        }
      });
    },
  },
  watch: {
    model: {
      handler(val) {
        const temp = JSON.parse(JSON.stringify(val));
        if (temp.Partners && !temp.Partners.length) {
          temp.Partners.push({});
        }
        this.dataSource = temp;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>