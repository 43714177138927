<template>
  <div class="card">
    <div class="photo">
      <img
        class="w-full h-full"
        v-if="dataSource.logo"
        :src="dataSource.logo"
        alt=""
      />
    </div>
    <div class="desc bg-f9 p-20 text-center">
      <div class="font-bold ellipsis">
        <a :href="detail">{{ dataSource.name }}</a>
      </div>
      <div class="ellipsis">{{ dataSource.company }}</div>
      <div>
        <a-space>
          <div>{{ dataSource.SubmitTime }}</div>
          <div>提交</div>
        </a-space>
      </div>
      <div>{{ dataSource.status }}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: "e-product-card",
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      detail: "",
    };
  },
  created() {
    this.detail = '#/evaluate/product-detail/:id'.replace(
      /:id/,
      this.dataSource.id
    );
  },
  watch: {
    "dataSource.id"(val) {
      this.detail = '#/evaluate/product-detail/:id'.replace(/:id/, val);
    },
  },
};
</script>

<style scoped>
.card {
  width: 285px;
}
.photo {
  height: 210px;
}
.name {
  height: 48px;
}
.tag-wrapper {
  max-height: 62px;
}
.tag {
  padding: 0 10px;
  line-height: 26px;
  height: 26px;
}
</style>