import request from '@/utils/request1'

let promission = {};

// 获取用户信息
export function getUserInfo() {
    return request.get('/user/info')
}
promission.getUserInfo = {}

export default promission;