<template>
  <div class="text-333 text-sm">
    <a-row class="pt-10">
      <a-col :span="24" class="mt-10">
        <div class="relative" style="height: 60px">
          <div
            class="img absolute"
            v-for="(item, index) in Partners"
            :key="index"
            :style="{ left: `${40 * index}px` }"
          >
            <a-tooltip placement="top">
              <template slot="title">{{ item.Name }}</template>
              <img
                v-if="item.url"
                :src="item.url"
                class="w-full h-full object-cover"
                alt=""
              />
            </a-tooltip>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { upload } from "@/api";
export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    Partners() {
      return this.model.Partners.map((item) => {
        return {
          Name: item.Name,
          url: upload() + "/" + item.Logo.ID,
        };
      });
    },
  },
};
</script>

<style scoped>
.img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #eee;
  overflow: hidden;
}
</style>
