// 合作伙伴
import request from '@/utils/request1'
import { transformCompany } from '@/utils/transform/company.js'

export function getCompanyList(params) {
    return request.get('/company', { params }).then(({ data = {} }) => {
        const rows = data.rows || []
        const temp = {
            count: data.count,
            rows: rows.map(transformCompany)
        }
        return Promise.resolve(temp)
    })
}

// 获取当前企业信息
export function getUserCompany() {
    return request.get('/user/company').then(({ data = {} }) => {
        return Promise.resolve(transformCompany(data))
    })
}