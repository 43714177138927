import { sessionGet } from '../storage.js'
import { getFileUrl } from '@/api/api/common'

export function transformStatus(status) {
    switch (status) {
        case 0:
            return '未提交审核';
        case 1:
            return '待审核';
        case 2:
            return '审核成功';
        case 3:
            return '审核失败';
        case 10:
            return '专家审核中';
        case 20:
            return '管理员审核中';
        case 30:
            return '审核成功';
        case 99:
            return '审核失败';
        case -1:
            return '已关闭';
    }
}

export function transformAttachment(attachments) {
    if (!attachments) return;

    if (isArray(attachments)) {
        return attachments.map(attach => {
            if (attach && attach.ID) {
                return {
                    name: attach.Name,
                    id: attach.ID,
                    url: getFileUrl(attach.ID)
                }
            } else {
                return;
            }

        }).filter(item => item)
    } else {
        if (attachments && attachments.ID) {
            return {
                Name: attachments.Name,
                ID: attachments.ID,
                url: getFileUrl(attachments.ID)
            }
        } else {
            return;
        }

    }
}

export function transformOptions(options, flag) {
    const Options = sessionGet('options1')
    if (!options || !flag) return;
    if (isArray(options)) {
        return options.map(option => {
            const temp = { ...option }

            if (option.ID) {
                const newOption = Options[flag][option.ID - 1]
                if (newOption) {
                    return Object.assign(temp, newOption)
                } else {
                    return;
                }
            } else if (option.Name && option.ID === 0) {

                return option;
            } else {
                return;
            }
        }).filter(item => item)
    } else {
        const temp = { ...options }
        if (options.ID) {
            const newOption = Options[flag][options.ID - 1]
            if (newOption) {
                return newOption
            } else {
                return;
            }
        } else if (options.Name && options.ID === 0) {
            return temp;
        } else {
            return;
        }
    }
}

export function restoreOptions(options, flag) {
    const Options = sessionGet('options1')
    if (!options || !flag) return;
    let temp = []
    if (isArray(options)) {
        temp = options.map(option => {
            return Options[flag][option - 1] || { ID: 0, Name: option }
        }).filter(item => item)
    } else {
        temp = Options[flag][options - 1] || { ID: 0, Name: options }
    }

    return temp;
}

export function transformRegion(data) {
    if (!data) return;
    const Province = data.Province || {},
        City = data.City || {},
        County = data.County || {};


    let strArr = [];
    if (Province.Name) {
        strArr.push(Province.Name)
    }
    if (City.Name) {
        strArr.push(City.Name)
    }
    if (County.Name) {
        strArr.push(County.Name)
    }
    let str = strArr.join('-')

    return {
        ids: {
            province: Province.ID,
            city: City.ID,
            county: County.ID,
        },
        str: str
    }
}

export function getTime(time) {
    return new Date(time).getTime()
}

function isArray(data) {
    return Object.prototype.toString.call(data) === '[object Array]'
}