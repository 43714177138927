<template>
  <div
    class="flex overflow-hidden tag-group"
    :style="{ height: `${rows * 28 + (rows - 1) * 10}px` }"
  >
    <div class="mr-2.5 flex items-center" v-if="$slots.prefix">
      <slot name="prefix"></slot>
    </div>
    <div class="text-left flex items-center flex-wrap">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "GfTagGroup",
  props: {
    rows: {
      type: Number,
      default: 1,
    },
    scroll: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="less" scoped>
.tag-group {
  .gf-tag {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
</style>