<template>
  <div class="p-10 border border-eee rounded flex">
    <div
      class="rounded overflow-hidden border border-eee mr-10"
      style="width: 66px; height: 88px"
    >
      <img class="object-contain" v-if="dataSource.logo" :src="dataSource.logo.url" alt="" />
    </div>
    <div style="width: calc(100% - 76px)">
      <a-space class="mb-10">
        <span class="text-111 font-bold">{{ dataSource.name }}</span>
        <span class="text-999">|</span>
        <span class="text-999">{{ dataSource.position }}</span>
      </a-space>
      <div class="text-xs mb-10">{{ dataSource.companyName }}</div>
      <div class="overflow-hidden flex flex-wrap" style="height: 26px">
        <div
          class="tag1"
          v-for="(field, index) in dataSource.fields"
          :key="index"
        >
          {{ field.Name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "expert-select-card",
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style scoped>
.tag1 {
  padding: 5px 10px;
  border: 1px solid #eee;
  border-radius: 4px;
  height: 26px;
  font-size: 12px;
  margin-right: 10px;
  margin-bottom: 20px;
  line-height: 1;
  display: inline-block;
}
</style>
