var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form-model',_vm._b({ref:"container",attrs:{"rules":_vm.rules,"model":_vm.dataSource,"colon":false,"labelCol":{ span: 6 },"wrapperCol":{ span: 15 },"labelAlign":"left"}},'a-form-model',_vm.$attrs,false),[_c('a-form-model-item',{attrs:{"required":!_vm.$store.getters.isManager}},[_c('div',{staticClass:"h-40 flex items-center",attrs:{"slot":"label"},slot:"label"},[_c('span',[_vm._v("核心竞争力")]),_c('a-tooltip',{staticClass:"ml-10"},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("请至少填写一项。关键词填写示例：较强平台开发能力 详细描述填写示例：打造了以多行业高端装备产业为依托，构筑覆盖产业链的一体化工业互联网平台")]),_c('a-icon',{staticStyle:{"color":"#ddd"},attrs:{"type":"question-circle","theme":"filled"}})],1)],1)]),_vm._l((_vm.dataSource.Competitives),function(item,index){return _c('a-form-model-item',{key:item.ID},[_c('div',{staticClass:"h-40 flex items-center",attrs:{"slot":"label"},slot:"label"},[_c('a-space',{staticClass:"text-999 text-xs",attrs:{"align":"baseline"}},[_c('span',[_vm._v("竞争力"+_vm._s(index + 1))]),(
              _vm.dataSource.Competitives.length > 1 ||
              _vm.$store.getters.isManager
            )?_c('a-icon',{staticClass:"align-middle cursor-pointer",attrs:{"type":"minus-circle"},on:{"click":function($event){return _vm.deleteCompitive(index)}}}):_vm._e()],1)],1),_c('a-row',{attrs:{"gutter":10}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-model-item',{attrs:{"rules":[
              {
                max: 10,
                message: '字数超出限制',
                trigger: ['change', 'blur'],
              },
              { required: true, message: '请填写', trigger: 'blur' } ],"prop":("Competitives." + index + ".Keyword")}},[_c('gf-input',{attrs:{"placeholder":"关键词,不超过10个字"},model:{value:(item.Keyword),callback:function ($$v) {_vm.$set(item, "Keyword", $$v)},expression:"item.Keyword"}})],1)],1),_c('a-col',{attrs:{"span":16}},[_c('gf-textarea',{attrs:{"placeholder":"对该竞争力进行详细描述","max":50,"prop":("Competitives." + index + ".Desc"),"required":true,"rows":4},model:{value:(item.Desc),callback:function ($$v) {_vm.$set(item, "Desc", $$v)},expression:"item.Desc"}})],1)],1)],1)}),_c('a-form-model-item',[_c('div',{staticClass:"h-40 flex items-center",attrs:{"slot":"label"},slot:"label"},[_c('span')]),_c('div',{staticClass:"bg-white h-40 border border-eee rounded text-center leading-10",on:{"click":_vm.add}},[_vm._v(" 添加 ")])])],2),_c('div',{staticClass:"text-right mt-40"},[_c('a-space',[_c('a-button',{attrs:{"size":"large","type":"primary","ghost":""},on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('a-button',{attrs:{"size":"large","type":"primary"},on:{"click":_vm.save}},[_vm._v("保存")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }