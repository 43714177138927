<template>
  <div class="text-333 text-sm">
    <a-row class="pt-10">
      <a-col :span="24" class="mt-10">
        <a-space direction="vertical" class="w-full">
          <div class="text-999">公司主营业务：</div>
          <div>
            <div v-for="(item, index) in Business" :key="index">
              {{ item }}
            </div>
          </div>
        </a-space>
      </a-col>
      <a-col :span="24" class="mt-10">
        <a-space direction="vertical" class="w-full">
          <div class="text-999">企业价值观：</div>
          <div>
            <div v-for="(item, index) in Values" :key="index">
              {{ item }}
            </div>
          </div>
        </a-space>
      </a-col>
      <a-col :span="24" class="mt-10">
        <a-space direction="vertical" class="w-full">
          <div class="text-999">团队介绍：</div>
          <div>
            <div v-for="(item, index) in Team" :key="index">
              {{ item }}
            </div>
          </div>
        </a-space>
      </a-col>
      
    </a-row>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    Business() {
      return this.model.Business.split("\n");
    },
    Team() {
      return this.model.Team.split("\n");
    },
    Values() {
      return this.model.Values.split("\n");
    },
  },
};
</script>
