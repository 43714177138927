import Vue from 'vue'
import dayjs from 'dayjs'

Vue.filter('formatterTime', (value, type = "YYYY-MM-DD") => {
    const val = value || 0;
    return dayjs(val).format(type)
})

Vue.filter('formatterTime1', (value, type = "YYYY-MM-DD") => {
    const val = value || 0;
    return dayjs(new Date(val).getTime()).format(type)
})