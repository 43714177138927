<template>
  <Link>
    <img style="width:185px;height:45px" class="object-contain" src="@/assets/icons/logo.svg" alt="" />
  </Link>
</template>

<script>
export default {
  name: "Logo",
};
</script>