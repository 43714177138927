<template>
  <div class="partner-card border border-eee py-20">
    <div class="partner-logo mx-auto border border-eee">
      <img
        v-if="dataSource.logo"
        :src="dataSource.logo"
        alt=""
        class="w-full h-full object-contain"
      />
    </div>
    <div
      class="
        partner-name
        text-base
        font-bold
        text-center
        overflow-hidden
        mt-20
        px-20
      "
    >
      <a class="ellipsis" :href="detail">{{ dataSource.eventName }}</a>
    </div>
    <div class="partner-name text-base text-center overflow-hidden px-20">
      <div class="ellipsis">{{ dataSource.name }}</div>
    </div>
    <div class="text-center">
      <a-space>
        <div>{{ dataSource.submitTime }}</div>
        <div>提交</div>
      </a-space>
    </div>
    <div class="text-center">{{ dataSource.status }}</div>
  </div>
</template>

<script>
export default {
  name: "evaluate-partner-event-card",
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      detail: "",
    };
  },
  created() {
    this.detail = `#/evaluate/event-detail/:id`.replace(
      /:id/,
      this.dataSource.id
    );
  },
  watch: {
    "dataSource.id"(val) {
      this.detail = `#/evaluate/event-detail/:id`.replace(/:id/, val);
    },
  },
};
</script>

<style scoped>
.partner-card:hover {
  box-shadow: 2px 5px 32px -10px rgba(0, 0, 0, 0.2);
}
.partner-logo {
  width: 64px;
  height: 64px;
}
.partner-name {
  height: 24px;
}
.partner-tag {
  height: 82px;
}
.tag {
  max-width: 250px;
  padding: 5px 10px;
  border: 1px solid #2e7ced;
  color: #2e7ced;
  height: 26px;
}
</style>