<template>
  <div class="clearfix">
    <a-upload
      :disabled="disabled"
      :action="upload()"
      :before-upload="beforeUpload"
      list-type="picture-card"
      :file-list="fileList"
      :accept="accept"
      @preview="handlePreview"
      @change="handleChange"
    >
      <div v-if="fileList.length < 8">
        <a-icon type="plus" />
        <div class="ant-upload-text">Upload</div>
      </div>
    </a-upload>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
import { upload } from "@/api";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default {
  name: "PicturesUpload",
  props: {
    size: Number,
    accept: String,
    value: {
      type: Array,
      default: () => [],
    },
    disabled: Boolean,
  },
  data() {
    return {
      previewVisible: false,
      previewImage: "",
      fileList: [],
    };
  },
  watch: {
    value(val) {
      this.fileList = val.map((obj) => {
        const file = obj;
        if (file.ID) {
          return {
            ID: obj.ID || obj.id,
            uid: obj.ID || obj.id,
            Name: obj.Name || obj.name,
            name: obj.Name || obj.name,
            url: upload() + "/" + (obj.ID || obj.id),
            status: "done",
          };
        }
        if (file.response && file.status === "done") {
          const obj = file.response.data;
          return {
            ID: obj.ID || obj.id,
            uid: obj.ID || obj.id,
            Name: obj.Name || obj.name,
            name: obj.Name || obj.name,
            url: upload() + "/" + (obj.ID || obj.id),
            status: "done",
          };
        } else {
          return file;
        }
      });
    },
  },
  methods: {
    upload() {
      return upload();
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      this.fileList = fileList.map((file) => {
        if (file.response && file.status === "done") {
          const obj = file.response.data;
          return {
            ID: obj.ID || obj.id,
            uid: obj.ID || obj.id,
            Name: obj.Name || obj.name,
            name: obj.Name || obj.name,
            url: upload() + "/" + (obj.ID || obj.id),
            status: "done",
          };
        } else {
          return file;
        }
      });
      this.$emit("input", this.fileList);
    },
    beforeUpload(file) {
      this.output = "";
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("只能上传图片文件!");
      }
      const isLt2M = file.size / 1024 / 1024 < this.size;
      if (!isLt2M) {
        this.$message.error(`图片大小不能超过${this.size}MB!`);
      }
      return isJpgOrPng && isLt2M;
    },
  },
};
</script>
<style>
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>