<template>
  <div class="text-333 text-sm pt-20">
    <div class="flex">
      <div class="logo-wrapper mr-10">
        <div class="logo overflow-hidden">
          <img
            v-if="Logo"
            class="w-full h-full object-contain"
            :src="Logo"
            alt=""
          />
        </div>
        <div
          class="membership text-white text-xs"
          :class="{ not: membershipText.ID === 0 }"
        >
          {{ membershipText.Name }}
        </div>
      </div>
      <div>
        <div class="flex items-end mt-20 mb-10">
          <span class="text-111 text-base font-bold">{{ Name }}</span>
          <span class="mx-10 self-center">|</span>
          <span>{{ ShortName }}</span>
        </div>
        <div>{{ EName }}</div>
      </div>
    </div>
    <a-row class="pt-10">
      <a-col :span="12" class="mt-10">
        <a-space>
          <div class="text-999">统一社会信用码：</div>
          <div>{{ CreditCode }}</div>
        </a-space>
      </a-col>
      <a-col :span="12" class="mt-10">
        <a-space>
          <div class="text-999">法人代表：</div>
          <div>{{ Representative }}</div>
        </a-space>
      </a-col>
      <a-col :span="12" class="mt-10">
        <a-space>
          <div class="text-999">成立时间：</div>
          <div>{{ Time }}</div>
        </a-space>
      </a-col>
      <a-col :span="12" class="mt-10">
        <a-space>
          <div class="text-999">注册资本：</div>
          <div>{{ RegisterCapital }}万</div>
        </a-space>
      </a-col>
      <a-col :span="24" class="mt-10">
        <a-space>
          <div class="text-999">注册地址：</div>
          <div>{{ RegisterAddress }}</div>
        </a-space>
      </a-col>
      <a-col :span="24" class="mt-10">
        <a-space>
          <div class="text-999">办公地址：</div>
          <div>{{ BusinessAddress }}</div>
        </a-space>
      </a-col>
      <a-col :span="12" class="mt-10">
        <a-space>
          <div class="text-999">单位性质：</div>
          <div>{{ CategoryId.Name || Category.Name }}</div>
        </a-space>
      </a-col>
      <a-col :span="24" class="mt-10">
        <a-space align="start">
          <div class="text-999">营业执照：</div>
          <div class="img">
            <img
              v-if="License"
              class="w-full h-full object-contain"
              :src="License"
              alt=""
            />
          </div>
        </a-space>
      </a-col>
      <a-col :span="12" class="mt-10" v-if="isManager">
        <a-space>
          <div class="text-999">推荐人：</div>
          <div>{{ this.model.Referrer || "-" }}</div>
        </a-space>
      </a-col>
      <a-col :span="12" class="mt-10" v-if="isManager">
        <a-space>
          <div class="text-999">推荐人公司：</div>
          <div>{{ this.model.ReferrerCompany || "-" }}</div>
        </a-space>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import day from "dayjs";
import { upload } from "@/api";
export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    role() {
      return this.$store.state.role;
    },
    isManager() {
      return this.role === 6 || this.role === 7;
    },
    membership() {
      return this.options.membership;
    },
    whether() {
      return this.options.whether;
    },
    nature() {
      return this.options.nature;
    },
    CreditCode() {
      return this.model.CreditCode;
    },
    Representative() {
      return this.model.Representative;
    },
    Time() {
      return day.unix(this.model.RegisterTime).format("YYYY-MM-DD");
    },
    BusinessAddress() {
      return this.model.BusinessAddress;
    },
    RegisterAddress() {
      return this.model.RegisterAddress;
    },
    Category() {
      if (this.model.Property === 0) {
        return this.model.Property;
      }
      return "-";
    },
    CategoryId() {
      const property = this.model.Property || {};
      if (property.Name) {
        return property;
      } else {
        const option = this.nature[property.ID];
        return option ? option : "";
      }
    },
    RegisterCapital() {
      return this.model.RegisterCapital;
    },
    Name() {
      return this.model.Name;
    },
    EName() {
      return this.model.EName;
    },
    ShortName() {
      return this.model.ShortName;
    },
    License() {
      return this.model.License && this.model.License.ID
        ? upload() + "/" + this.model.License.ID
        : "";
    },
    Logo() {
      return this.model.Logo && this.model.Logo.ID
        ? upload() + "/" + this.model.Logo.ID
        : "";
    },
    membershipText() {
      const member = this.model.MembershipTypeId || {};
      if (member.Name) {
        return member;
      } else {
        const option = this.membership[member.ID];
        return option ? option : "";
      }
    },
    isMember() {
      return this.model.MembershipTypeId;
    },
  },
};
</script>

<style scoped>
.logo-wrapper {
  width: 108px;
  height: 96px;
  position: relative;
}
.img {
  width: 200px;
  height: 98px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #eee;
}
.logo {
  position: absolute;
  width: 80px;
  height: 80px;
  border: 1px solid #eee;
  border-radius: 50%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.membership {
  width: 100%;
  height: 26px;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  background-image: url("../../../assets/icons/member.svg");
}
.membership.not {
  background-image: url("../../../assets/icons/notMember.svg");
}
</style>