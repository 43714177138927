<template>
  <div class="container h-full w-full mx-auto">
    <div class="text-center flex items-center h-full justify-center">
      <div class="text-xs text-999 items-center flex flex-wrap justify-center">
        <span class="mr-10">©2021 上海市工业互联网协会保留所有权利</span>
        <a
          class="flex items-center text-999 mr-10"
          target="blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010902003303"
        >
          <img class="mr-1" src="@/assets/images/police.jpg" alt="" />
          <span>沪公网安备 31010902003303号</span>
        </a>
        <span>沪ICP备20018225号-1</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "global-footer",
};
</script>