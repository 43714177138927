<template>
  <a-modal
    v-bind="$attrs"
    v-on="$listeners"
    title="对本次服务进行评价"
    okText="确定"
    cancelText="取消"
    :afterClose="afterClose"
    :destroyOnClose="true"
    @ok="ok"
  >
    <div class="flex items-center mb-20">
      <div style="width: 120px">本次需求响应效率</div>
      <a-rate v-model="model.Response"></a-rate>
    </div>
    <div class="flex items-center mb-20">
      <div style="width: 120px">平台的服务质量</div>
      <a-rate v-model="model.Service"></a-rate>
    </div>
    <div>
      <div class="mb-10">
        对于本次需求对接，您认为我们有哪些需要改进的地方？
      </div>
      <a-form-model :model="model" ref="container">
        <gf-form-item
          prop="Comment"
          :rules="[
            {
              required: true,
              message: '请填写审核意见',
              trigger: ['change', 'blur'],
            },
            { max: 300, message: '字数超出限制', trigger: ['change', 'blur'] },
          ]"
          style="margin: 0"
        >
          <gf-re-textarea v-model="model.Comment" :max="300"></gf-re-textarea>
        </gf-form-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: "ReviewModal",
  model: {
    prop: "visible",
    event: "change",
  },
  data() {
    return {
      model: {
        Response: 5,
        Service: 5,
        Comment: "",
      },
    };
  },
  methods: {
    ok() {
      this.$refs.container.validate((valid) => {
        if (valid) {
          this.$emit("confirm", this.model);
        }
      });
    },
    afterClose() {
      this.model = { Response: 5, Service: 5, Comment: "" };
    },
  },
};
</script>