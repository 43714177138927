<template>
  <premission-link :href="`#/expert-detail/${dataSource.id}?bg=${num}`">
    <div class="rounded overflow-hidden bg-f9">
      <div
        :style="{
          backgroundImage: `url(${bg})`,
        }"
        class="info-wrapper relative"
      >
        <img v-if="dataSource.logo.url" :src="dataSource.logo.url" alt="" />
        <div class="absolute left-0 top-0 w-full h-full p-20">
          <div style="width: 150px">
            <div
              class="ellipsis-1 text-white hover:text-primary cursor-pointer"
            >
              {{ dataSource.name }}
            </div>
            <div class="ellipsis-1 mt-10 text-xs text-white leading-4">
              {{ dataSource.position }}
            </div>
            <div
              style="color: rgba(255, 255, 255, 0.5)"
              class="ellipsis-2 mt-10 text-xs"
            >
              {{ dataSource.companyName }}
            </div>
          </div>
        </div>
      </div>
      <div class="tag-wrapper flex flex-wrap items-center">
        <div
          class="pr-20"
          v-for="(tag, index) in dataSource.industries"
          :key="index"
        >
          <div class="tag1 flex-auto">{{ tag.Name }}</div>
        </div>

        <div class="tag1">汽车及零配件</div>
        <div class="tag1">钢铁</div>
      </div>
    </div>
  </premission-link>
</template>

<script>
export default {
  name: "expert-card",
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      bg: null,
      num: 1,
    };
  },
  computed: {},
  created() {
    this.num = parseInt(Math.random() * 2 + 1);
    this.bg = require(`../../assets/images/expert_card_bg_${this.num}.png`);
  },
};
</script>

<style scoped>
.info-wrapper {
  height: 132px;
  background-size: 100% 100%;
}
.info-wrapper img {
  width: 80px;
  position: absolute;
  object-fit: contain;
  right: 20px;
  bottom: 0;
}
.tag-wrapper {
  height: 66px;
  padding: 20px;
}
.tag1 {
  padding: 5px 10px;
  border: 1px solid #eee;
  border-radius: 4px;
  height: 26px;
  font-size: 12px;
  /* margin-right: 10px; */
  margin-bottom: 20px;
  line-height: 1;
  display: inline-block;
}
</style>