<template>
  <a-select :placeholder="placeholder" v-bind="$attrs" v-on="$listeners">
    <a-select-option v-for="item in list" :key="item.ID" :value="item.ID">{{
      item.Name
    }}</a-select-option>
  </a-select>
</template>

<script>
export default {
  name: "GfSelect",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    flag: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "请选择",
    },
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    if (this.flag) {
      const options = [...this.options[this.flag]];
      if (!options || !options.length) return;
      this.list = options;
    }
  },
  computed: {
    options() {
      return this.$store.state.options;
    },
  },
};
</script>