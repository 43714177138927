<template>
  <div class="text-333 text-sm">
    <a-row class="pt-10">
      <a-col :span="12" class="mt-10">
        <a-space>
          <div class="text-999">姓名：</div>
          <div>{{ Name }}</div>
        </a-space>
      </a-col>
      <a-col :span="12" class="mt-10">
        <a-space>
          <div class="text-999">性别：</div>
          <div>{{ Gender }}</div>
        </a-space>
      </a-col>
      <a-col :span="12" class="mt-10">
        <a-space>
          <div class="text-999">单位职务：</div>
          <div>{{ Position }}</div>
        </a-space>
      </a-col>
      <a-col :span="12" class="mt-10">
        <a-space>
          <div class="text-999">手机号码：</div>
          <div>{{ Phone }}</div>
        </a-space>
      </a-col>
      <a-col :span="12" class="mt-10">
        <a-space>
          <div class="text-999">微信号：</div>
          <div>{{ Wechat }}</div>
        </a-space>
      </a-col>
      <a-col :span="12" class="mt-10">
        <a-space>
          <div class="text-999">Email：</div>
          <div>{{ Email }}</div>
        </a-space>
      </a-col>
      <a-col :span="12" class="mt-10">
        <a-space>
          <div class="text-999">企业网站：</div>
          <div>{{ Website }}</div>
        </a-space>
      </a-col>
      <a-col :span="12" class="mt-10">
        <a-space>
          <div class="text-999">微信公众号：</div>
          <div>{{ WechatPub }}</div>
        </a-space>
      </a-col>
      <a-col :span="24" class="mt-10">
        <a-space direction="vertical">
          <div class="text-999">常用宣传媒介：</div>
          <div>
            <div v-for="(item, index) in Medium" :key="index">
              {{ item }}
            </div>
          </div>
        </a-space>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    Name() {
      return this.model.Name || "-";
    },
    Email() {
      return this.model.Email || "-";
    },
    Website() {
      return this.model.Website || "-";
    },
    WechatPub() {
      return this.model.WechatPub || "-";
    },
    Phone() {
      return this.model.Phone || "-";
    },
    Wechat() {
      return this.model.Wechat || "-";
    },
    Telephone() {
      return this.model.Telephone || "-";
    },
    Position() {
      return this.model.Position || "-";
    },
    Gender() {
      const gender = this.model.Gender;
      if (gender === 0) {
        return "男";
      } else if (gender === 1) {
        return "女";
      } else {
        return "-";
      }
    },
    Medium() {
      return this.model.Medium ? this.model.Medium.split("\n") : "无";
    },
  },
};
</script>

<style scoped>
.img {
  width: 200px;
  height: 98px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #eee;
}
</style>
