import axios from 'axios'
import { message } from 'ant-design-vue'
import { localGet, localSet } from '@/utils/storage.js'

const base = config.API_BASE_URL || 'https://gf.app.dadax/';

export const request = axios.create({
    baseURL: `${base}api/v1`
})

export const proRequest = axios.create({
    baseURL: `${base}api/v1/op`
})

request.interceptors.request.use((config) => {
    const token = localGet('token')
    config.headers['Authorization'] = token
    return config
})

request.interceptors.response.use((config) => {
    return config.data
}, (err) => {
    const { code, msg } = err.response.data;
    if (code === 401) {
        localSet('token', '')
    }
    return Promise.reject({ code, msg })
})

proRequest.interceptors.request.use((config) => {
    const token = localGet('token')
    config.headers['Authorization'] = token
    return config
})

proRequest.interceptors.response.use((config) => {
    return config.data
}, (err) => {
    const { code, msg } = err.response.data;
    message.error(msg)
    if (code === 401) {
        setTimeout(() => {
            localSet('token', '')
            window.location.href = '';
        }, 300)
    }
    return Promise.reject({code})
})